import React, { Component } from 'react';

const aspectRatio = (489 / 1440) * 100;
const coverImageHeight = window.innerWidth * aspectRatio;

export default class CoverImageComponent extends Component {
    render() {
        return (
            <div style={webStyles.coverImageWrapper}>
                <div style={webStyles.coverImage} />
            </div>
        );
    }
}

const webStyles = {
    coverImageWrapper: {
        position: 'relative' as 'relative',
        width: '100%', // Full width of the parent
        paddingTop: `${aspectRatio}%`, // Aspect ratio of 489px / 1440px
        backgroundColor: '#ccc', // Optional: for better visibility
      },
      coverImage: {
        position: 'absolute' as 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: 'url(https://s3-alpha-sig.figma.com/img/b7d1/fe02/10cde1837cd12dfedcf97eeb6291e362?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XItIvXp~yNovux0k54aSG3SY4LmLYgnSgi-Jasc3qz3PQLD5MQfhvCl6kynOrKTK2gdNVAw9ZRRJj~IkhERwhJZuL5IutoUTeLW~olZR8EK3oGFpq5a1XN~HDE-v4mxz6K9O21bYO1c4D7RnQUdT9F5rWu3N9yXDv6L4fmUQglx0-wrDUhNQbKpF0x6LRvCmWGXlL0Sq4G-71qzCavHFSunIR9pYbA9XBE~CNsZPpVQPBjRweuqZ45w182lgxm04Q2h2SmYBvAaBNmkmeBA8H53-c8wzQBg9sY0qfruJooNFNv50GfypcPL3HAd14A6BSw7EKzBlp1DAi53arBEvBA__)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
}