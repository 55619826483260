import React from "react";

// Customizable Area Start
import {
  Box,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle, StyledTypography, StyledButton } from "../../email-account-login/src/LoginSignUp.web";
import AccountRegistrationController, { Props } from "./AccountRegistrationController";


// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class SignUpComplete extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={
          {
            overflow: 'hidden'
          }}>
          <LoginSignUp navigation={this.props.navigation} id={""}>
            <Box width={{xs : "300px", sm : "327px",  md: "360px"}} style={{ ...webStyle.leftContainer  }}>
              <div style={webStyle.isAccountDiv}>
                <StyledTypography style={{ ...webStyle.isAccountStatement, marginBottom: 36, color: 'black', fontFamily: 'Silika-Medium', fontSize: "24px", lineHeight : "32px" }}>
                  Thanks for completing the Sign Up process
                </StyledTypography>
              </div>
              <div style={webStyle.isAccountDiv}>
                <StyledTypography style={{ ...webStyle.isAccountStatement, marginBottom : 32, fontSize: "12px" }}>
                  We will reachout to you as soon as possible
                </StyledTypography>
              </div>
                <StyledButton
                  variant="outlined"
                  data-test-id="homeBtnClick"
                  style={{ ...webStyle.logInButton, textTransform: "capitalize" }}
                  type="submit"
                  onClick={() => { this.handleHomeBtnClick() }}
                >
                  Home
                </StyledButton>
            </Box>
          </LoginSignUp>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}


