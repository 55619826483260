import React from "react";

// Customizable Area Start
import { Typography, Grid, Box } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import { cancle } from "../../postcreation/src/assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "../../customisableuserprofiles/src/ProfileInfo.css";
import LoginSignUp, {
  webStyle,
  StyledButtonFloat,
  StyledBackButton,
  StyledBackButtonFloat,
  IOSSwitch,
} from "../../email-account-login/src/LoginSignUp.web";
import AccountRegistrationController, {
  Props,
  DateObject,
} from "./AccountRegistrationController";
import { calendarIcon, plusIcon } from "./assets";
import Select from "react-select";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const StyledDatePicker = styled(DatePicker)({
  width: "95%",
  border: "none",
  ".react-datepicker__input-container": {
    width: "100%",
    display: "flex",
  },
  ".react-datepicker__input-container input": {
    width: "100%",
    padding: "10px",
    fontSize: "14px",
    boxSizing: "border-box",
    whiteSpace: "normal",
    wordBreak: "break-word",
  },
  "::placeholder": {
    color: "#999",
    whiteSpace: "normal",
    wordBreak: "break-word",
  },
});

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const visibilityOptions = [
  { label: "Anyone", value: "anyone" },
  { label: "Not my current employer", value: "Not my current employer" },
  { label: "Nobody", value: "nobody" },
];

export default class Availability extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDateickerContainer() {
    return (
      <div>
        <Grid
          container
          style={{
            textAlign: "left",
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {this.state.selectedDates.map(
            (dates: DateObject, gridIndex: number) => (
              <Box
                key={gridIndex}
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "8px",
                  marginBottom: "5px",
                }}
              >
                <Grid
                  item
                  style={{ width: "50%", border: "1px solid #DFDFDF" }}
                >
                  <div
                    className="date-picker-container"
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div style={{ width: "90%", height: "100%" }}>
                      <StyledDatePicker
                        dateFormat={"dd/MM/yyyy"}
                        data-test-id="unavailablefrom"
                        placeholderText="Unavailable from"
                        className="datepicker"
                        selected={dates.from}
                        minDate={new Date()}
                        onChange={(
                          selectedDate: Date | [Date | null, Date | null] | null
                        ) => {
                          this.handleDateSelect(
                            selectedDate,
                            "from",
                            gridIndex
                          );
                        }}
                        onClickOutside={() =>
                          this.handleDatePickerClose("from", gridIndex)
                        }
                        open={this.state.openDatePickers[gridIndex].from}
                        readOnly
                      />
                    </div>
                    <div style={{ width: "10%", height: "100%" }}>
                      <img
                        height={13}
                        width={13}
                        data-test-id="fromCalendar"
                        src={calendarIcon}
                        alt="Date Icon"
                        className="calendar-icon"
                        onClick={() =>
                          this.handleDatePickerOpen("from", gridIndex)
                        }
                      />
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  style={{ width: "50%", border: "1px solid #DFDFDF" }}
                >
                  <div
                    className="date-picker-container"
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div style={{ width: "90%", height: "100%" }}>
                      <StyledDatePicker
                        dateFormat={"dd/MM/yyyy"}
                        data-test-id="unavailableto"
                        placeholderText="Unavailable to"
                        className="datepicker"
                        selected={dates.to}
                        minDate={dates.from}
                        onChange={(
                          selectedDate: Date | [Date | null, Date | null] | null
                        ) => {
                          this.handleDateSelect(selectedDate, "to", gridIndex);
                        }}
                        onClickOutside={() =>
                          this.handleDatePickerClose("to", gridIndex)
                        }
                        open={this.state.openDatePickers[gridIndex].to}
                        readOnly
                        popperPlacement="bottom"
                      />
                    </div>
                    <div style={{ width: "10%", height: "100%" }}>
                      <img
                        height={13}
                        width={13}
                        data-test-id="toCalendar"
                        src={calendarIcon}
                        alt="Date Icon"
                        className="calendar-icon"
                        onClick={() =>
                          this.handleDatePickerOpen("to", gridIndex)
                        }
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <img
                    data-test-id="deletePicker"
                    onClick={() => {
                      this.removeDatePicker(gridIndex);
                    }}
                    src={cancle}
                    width={24}
                    height={24}
                    style={{ padding: 10 }}
                  />
                </Grid>
              </Box>
            )
          )}
        </Grid>
        <div
          style={{
            width: "98%",
            borderBottom: "1px solid #DFDFDF",
            marginBottom: 20,
          }}
        />
        <div
          style={{
            ...webStyle.isAccountDiv,
            justifyContent: "left",
            marginBottom: 20,
          }}
        >
          <img
            src={plusIcon}
            height={20}
            width={20}
            onClick={this.addNewDatePicker}
          />
        </div>
      </div>
    );
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div
          style={{
            overflow: "hidden",
          }}
          data-test-id="availabilityContainer"
        >
          <LoginSignUp navigation={this.props.navigation} id={""}>
            <Box
              width={{ xs: "300px", sm: "327px", md: "600px" }}
              style={{
                ...webStyle.leftContainer,
                display: "flex",
                flexDirection: "column",
                minHeight: "calc(100vh - 88px)",
              }}
            >
              <div
                style={{
                  ...webStyle.formTitleContainer,
                  flexDirection: "column",
                  alignItems: "left",
                  marginBottom: "30px",
                  width: "100%",
                }}
              >
                <StyledBackButton
                  data-test-id="back-button"
                  onClick={() => {
                    this.handleBackNavigation();
                  }}
                  width={{ xs: "300px", sm: "327px" }}
                  style={{
                    height: "40px",
                    width: "100%",
                    gap: "6px",
                    alignItems: "center",
                    marginBottom: "24px",
                    cursor: "pointer",
                  }}
                >
                  <ChevronLeftIcon />
                  <Typography
                    data-test-id="back-text"
                    style={{
                      ...webStyle.formTitleText,
                      textAlign: "left" as "left",
                      fontSize: "16px",
                    }}
                  >
                    Back
                  </Typography>
                </StyledBackButton>
                <Typography
                  style={{
                    ...webStyle.formTitleText,
                    textAlign: "left" as "left",
                  }}
                >
                  Availability
                </Typography>
              </div>
              <Grid
                style={{
                  marginBottom: 30,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid item style={{ width: "90%" }}>
                  <div>
                    <Typography
                      style={{
                        fontSize: "15px",
                        color: "black",
                        fontFamily: "Silika-Medium",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Open for opportunities.
                    </Typography>
                    <span
                      style={{
                        lineHeight: 1.5,
                        fontSize: "13px",
                        color: "black",
                        fontFamily: "Silika-Light",
                      }}
                    >
                      Are you interested in work either now or in future ?
                    </span>
                  </div>
                </Grid>
                <Grid item style={{ width: "10%" }}>
                  {" "}
                  <IOSSwitch
                    data-test-id="datepickerswitch"
                    checked={this.state.datePickerToggle}
                    onChange={(event) => {
                      this.handleOppertunities(event.target.checked);
                    }}
                    color={"default"}
                  />
                </Grid>
              </Grid>
              {this.state.datePickerToggle && (
                <div data-test-id="unavailabilityContainer">
                  <div
                    style={{ ...webStyle.isAccountDiv, justifyContent: "left" }}
                    data-test-id=""
                  >
                    <Typography
                      style={{
                        ...webStyle.isAccountStatement,
                        marginBottom: 30,
                        fontSize: "15px",
                        color: "black",
                        fontFamily: "Silika-Medium",
                      }}
                    >
                      Unavailability:
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "98%",
                      borderBottom: "1px solid #DFDFDF",
                      marginBottom: 20,
                    }}
                  />
                  {this.renderDateickerContainer()}
                  <div
                    style={{
                      width: "98%",
                      borderBottom: "1px solid #DFDFDF",
                      marginBottom: 20,
                    }}
                  />
                  <div
                    style={{ ...webStyle.isAccountDiv, justifyContent: "left" }}
                  >
                    <Typography
                      style={{
                        ...webStyle.isAccountStatement,
                        fontFamily: "Silika-Medium",
                        marginBottom: 10,
                        fontSize: "15px",
                        color: "black",
                      }}
                    >
                      Who can see this ?
                    </Typography>
                  </div>
                  <Select
                    placeholder="Select"
                    data-test-id="visibilitySelect"
                    styles={{
                      menu: (base) => {
                        return {
                          ...base,
                          borderTopRightRadius: "0px",
                          color: "black",
                          boxShadow:
                            "0 0 5px rgba(0, 0, 0, 0.3),-5px 0 5px rgba(0, 0, 0, 0.3)",
                          borderTop: "none",
                          borderTopLeftRadius: "0px",
                          border: "none",
                          marginTop: 8,
                          width: "98%",
                        };
                      },
                      singleValue: (base) => {
                        return { ...base, color: "black" };
                      },
                      indicatorSeparator: (styles) => ({
                        ...styles,
                        display: "none",
                      }),
                      option: (provided, state) => {
                        return {
                          ...provided,
                          fontFamily: "Silika-Medium",
                          backgroundColor: state.isSelected
                            ? "rgba(0, 0, 0, 0.04)"
                            : "inherit",
                          color: "#0D0C22",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        };
                      },
                      control: (base) => {
                        return {
                          ...base,
                          backgroundColor: "white",
                          width: "98%",
                          height: 44,
                          fontFamily: "Silika-Medium",
                        };
                      },
                      dropdownIndicator: (base) => {
                        return {
                          ...base,
                          color: "lightgrey",
                        };
                      },
                      menuList: (base) => {
                        return {
                          ...base,
                          zIndex: -1,
                          top: "-5px",
                          height: 112,
                          position: "relative",
                          fontFamily: "Silika-Medium",
                          textAlign: "left",
                          fontSize: "14px",
                          overflowY: "scroll",
                        };
                      },
                    }}
                    options={visibilityOptions}
                    isSearchable={false}
                    isClearable={false}
                    isRtl={false}
                    value={visibilityOptions.find(
                      (option) => option.value === this.state.visibility?.value
                    )}
                    onChange={(selectedOption) =>
                      this.handleVisiblity(
                        selectedOption as { label: string; value: string }
                      )
                    }
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  justifyContent: "flex-end",
                  height: "auto",
                  marginTop: "36px",
                  marginBottom: "10px",
                }}
              >
                <StyledBackButtonFloat
                  variant="outlined"
                  style={{
                    ...webStyle.logInButton,
                    backgroundColor: "none",
                    border: "1px solid #0D0C22",
                    color: "#0D0C22",
                  }}
                  type="submit"
                  data-test-id="backButton"
                  onClick={() => {
                    this.handleBackNavigation();
                  }}
                >
                  Back
                </StyledBackButtonFloat>
                <StyledButtonFloat
                  variant="outlined"
                  style={{ ...webStyle.logInButton }}
                  type="submit"
                  data-test-id="createProfile"
                  onClick={() => {
                    this.createProfile();
                  }}
                >
                  Next
                </StyledButtonFloat>
              </div>
            </Box>
          </LoginSignUp>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
