import React from "react";

// Customizable Area Start
import {
  Box,
  Dialog,
  DialogContent,
  Typography
} from "@material-ui/core";
import { webStyle } from "../../email-account-login/src/LoginSignUp.web";
import TermsController, { Props } from "./TermsController";
import { closeIcon } from "./assets";
import { termStyles } from "./TermsAndConditions.web";
import parse from 'html-react-parser';

// Customizable Area End

export default class PrivacyPolicy extends TermsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <Dialog open={this.props.open} 
      style={{...termStyles.topContainer,
        overflowX:'hidden',
        position:'absolute', 
        background: "transparent",
      boxShadow: "none",
      height:"100vh"}}>       
     <DialogContent>
     <Box>
      <img  data-test-id="closeIcon"   src={closeIcon} height={20} width={20}
      onClick={this.props.onClose} style={{cursor:'pointer',padding:'20px',float:'right'}}/>
        <div style={termStyles.heading}><Typography style={{ ...webStyle.formTitleText, 
               textAlign: "left" as "left",marginBottom:30}}>
               Privacy Policy
             </Typography>
             <div>
       <Typography 
       style={termStyles.description}>
      {parse(this.state.privacyPolicyDescription)}
       </Typography>
             </div> 
           </div> 
      </Box>
     </DialogContent>
           </Dialog> 
      // Customizable Area End
    );
  }
}


