import React from "react";
import { styled, Grid, Theme, Typography } from "@material-ui/core";
import NoFavourites from "./NoFavourites";

interface Props{
    favouritesProjects: any[];
    windowWidth: number;
}

const StyledProjectsContainer = styled(Grid)(({theme}: {theme : Theme}) => ({
    marginTop: "95px",
    [theme.breakpoints.down('sm')]: {
        marginTop: "24px",
    },
    "& .project":{
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            flexFlow: "column",
            padding: "0px !important",
            margin: "0px 20px 24px 20px !important",
            "& .projectName":{
                fontSize: "16px",
                marginTop: "8px",
                marginBottom: "4px"
            },
            "& .projectOwner":{
                fontSize: "14px",
                fontWeight: 400
            }
        },
        "& img":{
            width: "100%",
            height: "228px",
            border: "1px solid black",
            borderRadius: "4px",
            [theme.breakpoints.down('sm')]: {
                height: "250px"
            }
        }
    }
}));

class FavouriteProjectsList extends React.Component<Props>{
    constructor(props: Props){
        super(props);
    }

    render(){
        const {favouritesProjects, windowWidth} = this.props;
        const isMobile = windowWidth <= 599;

        if(favouritesProjects.length > 0){
            return (<StyledProjectsContainer container spacing={3}>
                <Grid item xs={12} sm={12} md={4} lg={3} xl={3} className="project">
                    <img src="" alt="test" />
                    {isMobile ? <>
                    <Typography className="projectName">GAC Corsa Barchetta / 2023</Typography>
                    <Typography className="projectOwner">Jhon Doe</Typography>
                    </>: <></>}
                </Grid>
            </StyledProjectsContainer>
            )
        }
        return <NoFavourites data-test-id="no-fav-projects" title="You don’t have favourites projects yet"/>
    }
}

export default FavouriteProjectsList;