import React from "react";
import { styled, Theme, Typography, Box, Avatar, Button } from "@material-ui/core";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NoFavourites from "./NoFavourites";
interface Props {
    favouritesPeople: any[],
    windowWidth: number
}

const StyledPeopleContainer = styled("div")(({ theme }: { theme: Theme }) => ({
    marginTop: "95px",
    [theme.breakpoints.down('sm')]: {
        marginTop: "24px",
        display: "flex",
        justifyContent: "center",
        flexFlow: "column",
        alignItems: "center",

    },
    "& .peopleListItem": {
        border: "1px solid #DFDFDF",
        padding: "6px",
        display: "flex",
        flexFlow: "column",
        alignItems: "center"
    },
    "& .card": {
        border: "1px solid #DFDFDF",
        borderRadius: "4px",
        marginBottom: "30px",
        display: "inline-flex",
        width: "-moz-available",
        padding: "24px 20px",
        minHeight: "180px"
    },
    "& .peopleImage": {
        width: "18%",
        display: "flex",
        alignItems: "center",
        "& img": {
            height: "156px",
            width: "156px",
            border: "1px solid black",
            borderRadius: "50%"
        },
    },
    "& .peopleAbout": {
        width: "38%",
        display: "flex",
        flexFlow: "column"
    },
    "& .name": {
        fontSize: "28px",
        fontWeight: 500,
        lineHeight: "39px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        marginRight: "43px",
        [theme.breakpoints.down('sm')]: {
            margin: "44px 0 0",
            fontSize: "20px",
            lineHeight: "24px"
        }
    },
    "& .btnGroup": {
        display: "flex",
        flexFlow: "column",
        margin: "10px auto 10px 0",
        [theme.breakpoints.down('sm')]: {
            flexFlow: "row",
            margin: "8px auto"
        },
        "& button": {
            height: "18px",
            width: "80px",
            fontSize: "12px",
            borderRadius: "2px",
            boxShadow: "none",
            "&:hover": {
                boxShadow: "none !important",
            }
        },
        "& .greenBtn": {
            height: "18px",
            background: "#25CF2C !important",
            color: "#0D0C22"
        },
        "& .blackBtn": {
            background: "#0D0C22 !important",
            color: "#fff",
        },
        "& button:nth-child(1)": {
            marginBottom: "4px",
            [theme.breakpoints.down('sm')]: {
                margin: "0 4px 0"
            }
        }
    },
    "& .profession": {
        fontSize: "14px",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center"
        }
    },
    "& .earning": {
        margin: "10px auto 10px 0",
        fontSize: "14px",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            margin: "8px auto"
        }
    },
    "& .location": {
        display: "inline-flex",
        alignItems: "center",
        fontSize: "12px",
        "& svg": {
            height: "20px",
            width: "20px"
        },
        "& span": {
            marginLeft: "4px"
        }
    },
    "& .peopleProjects": {
        width: "44%",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "end",
        "& img": {
            height: "137px",
            width: "177px",
            borderRadius: "4px"
        },
        "& img:nth-child(1), :nth-child(2)": {
            marginRight: "12px"
        }
    },

    "& .peopleCard": {

        borderRadius: "2px",
        display: "inline-flex",

    },
    "& .people": {
        position: "relative",
        "&:nth-child(1), &:nth-child(2)": {
            marginRight: "7px"
        }
    },
    "& .peopleImgCard": {
        width: "103px",
        height: "80px",
        boxShadow: "none",
        borderRadius: "1.14px",
        border: "1px solid black"
    },
    "& .peopleBox": {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "64px",
        height: "64px",
    },
    "& .peopleAvatar": {
        width: '100%',
        height: '100%',
        border: `3px solid ${theme.palette.background.paper}`,
    }
}));

class FavouritePeopleList extends React.Component<Props>{
    constructor(props: Props){
        super(props);
    }
    
    renderPeopleForDesktop = () => {
        return (
            <StyledPeopleContainer>
                <Box className="card">
                    <div className="peopleImage">
                        <img alt="test" src="" />
                    </div>
                    <div className="peopleAbout">
                        <Typography className="name">Clement Thomas de Montejjejjedjfgdfgdifgidf</Typography>
                        <div className="btnGroup">
                            <Button variant="contained" className="greenBtn">Shortlist</Button>
                            <Button variant="contained" className="blackBtn">Available</Button>
                        </div>
                        <Typography className="profession">Automotive interior designer Mazda specialist for trains</Typography>
                        <Typography className="earning">£40.00 - £45.00/hr</Typography>
                        <div className="location">
                            <LocationOnIcon /> <span>Coventry, UK</span>
                        </div>
                    </div>
                    <div className="peopleProjects">
                        <img alt="test" src="" />
                    </div>
                </Box>
            </StyledPeopleContainer>
        )
    }

    renderPeopleList = () => {
        const {favouritesPeople, windowWidth} = this.props;
        const isMobile = windowWidth <= 599;
        if(favouritesPeople.length > 0){
            if(isMobile){
                return <StyledPeopleContainer data-test-id="favouritePeople">
                <div className="peopleListItem">
                    <div className="peopleCard">
                        <div className="people">
                            <img src="" alt="test" className="peopleImgCard" />
                        </div>
                        <div className="people">
                            <img src="" alt="test" className="peopleImgCard" />
                            <Box className="peopleBox">
                                <Avatar
                                    src="path-to-your-avatar-image.jpg"
                                    alt="Avatar"
                                    className="peopleAvatar"
                                />
                            </Box>
                        </div>
                        <div className="people">
                            <img src="" alt="test" className="peopleImgCard" />
                        </div>
                    </div>
                    <Typography className="name">John Doe</Typography>
                    <div className="btnGroup">
                        <Button variant="contained" className="greenBtn">Shortlist</Button>
                        <Button variant="contained" className="blackBtn">Available</Button>
                    </div>
                    <Typography className="profession">Automotive interior designer Mazda specialist for trains</Typography>
                    <Typography className="earning">£40.00 - £45.00/hr</Typography>
                    <div className="location">
                        <LocationOnIcon /> <span>Coventry, UK</span>
                    </div>
                </div>
             </StyledPeopleContainer>
            }else{
                return this.renderPeopleForDesktop();
            }
        }else{
            return <NoFavourites data-test-id="no-fav-people" title="You don’t have favourites people yet" />;
        }
    }

    render(){
        return this.renderPeopleList();
    }
}  

export default FavouritePeopleList;
