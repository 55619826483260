import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core'
import React from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { S } from '../PostCreationWebController';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonstyles: {
            border: "1px solid  #010101",
            borderRadius: "2px",
            width: "50%",
            textTransform: "none"
        },
        textStyles: {
            fontFamily: "Silka-light",
            fontSize: "16px",
            fontWeight: 300,
            width: "60%",
            alignItems: "center"
        },
        containedButton: {
            border: "1px solid  #010101",
            borderRadius: "2px",
            backgroundColor: "#0D0C22",
            color: "white",
            width: "50%",
            textTransform: "none"

        },
        headerContent: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

        },
        buttonAlignment: {
            justifyContent: "space-around"

        }
    })
);

type Props = {
    state: S,
    handleDelete: () => void,
    deleteCollabApi: (account_id: number) => void
}


function DeleteCollab(props: Props) {
    const { state, handleDelete, deleteCollabApi } = props
    const classes = useStyles()
    return (
        <Dialog
            style={{ padding: "20px" }}
            open={state.deleteCollabPopUp}
            onClose={handleDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent className={classes.headerContent}>
                <DialogContentText id="alert-dialog-description" className={classes.textStyles}>
                    Are you sure you want to delete this collaborator?
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ margin: "10px 20px" }}>
                <Button variant="outlined" id="close-delete-collab-popup" className={classes.buttonstyles} onClick={() => handleDelete()}>Cancel</Button>
                <Button className={classes.containedButton} variant="contained" id="yes-delete-btn" onClick={()=>deleteCollabApi(state.deleteID)}>
                    Yes, Remove
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteCollab
