Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.profileListApiEndPoint = "bx_block_profile/profiles";
exports.giveFeedbackApiEndPoint = "/bx_block_like/likes";
exports.getAllApiEndPoint = "/bx_block_like/likes"
// Customizable Area End