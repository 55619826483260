import React from "react";

// Customizable Area Start
import {
  Box
} from "@material-ui/core";
import { createTheme, ThemeProvider} from "@material-ui/core/styles";
import LoginSignUp, { webStyle, StyledButton, StyledTypography } from "../../email-account-login/src/LoginSignUp.web";
import AccountLoginController, { Props } from "./AccountLoginController";





// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class PasswordProcessComplete extends AccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <LoginSignUp navigation={this.props.navigation} id={""}>
          <Box width={{xs : "300px", sm : "327px",  md: "360px"}} style={webStyle.leftContainer}>
            <div style={webStyle.isAccountDiv}>
              <StyledTypography style={{ ...webStyle.formTitleText, fontFamily: 'Silika-Medium', marginBottom: 50, color: 'black' }}>
                Thanks for completing the password reset process
              </StyledTypography>
            </div>
            <div style={webStyle.isAccountDiv}>
              <StyledTypography style={{ ...webStyle.isAccountStatement, marginBottom: 70, fontSize: "14px" }}>
                Your password is updated! Please proceed to the Homepage
              </StyledTypography>
            </div>
            <StyledButton
                data-testid="homeButton"
                variant="outlined"
                style={{ ...webStyle.logInButton, textTransform: "capitalize" }}
                type="submit"
                onClick={() => { this.props.navigation.navigate('AccountLogin') }}
              >
                Home
              </StyledButton>
          </Box>
        </LoginSignUp>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}


