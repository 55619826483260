import { FormControl, Theme, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch, Typography, withStyles, styled, IconButton, Button } from '@material-ui/core'
import React from 'react'
import AccountActivity from "./AccountActivity"
import Subscription from "./Subscription"
import AccountSubscriptions from "./AccountSubscriptions";
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions'
import { ErrorMessage, Formik } from 'formik'
import * as Yup from 'yup';
import { SettingDetailsProps1, NotificationSetting } from '../../SettingsProfileController';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { accountDark,visibilityDark, termsDark, projectDark, notificationDark } from "../../assets";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 46,
    padding: 0,
    height: 28,
  },
  switchBase: {
    padding: 1,
    top: 1,
    left: 1,
    '&$checked': {
      color: theme.palette.common.white,
      transform: 'translateX(17px)',
      '& + $track': {
        opacity: 1,
        backgroundColor: "#fff",
        border: 'none',
      },
    },
    "&.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: '#010101 !important',
      opacity: 1
    },
    '&$focusVisible $thumb': {
      border: '6px solid #fff',
      color: '#fff',
    },
    '& #swith': {
      marginTop: "1px",
    },
    "& .MuiSwitch-root-45": {
      marginRight: '16px !important'
    },
  },
  track: {
    borderRadius: 29 / 2,
    opacity: 1,
    border: `0px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#DFDFDF',
    transition: theme.transitions.create(['background-color', 'border']),
  },
  thumb: {
    height: 24,
    width: 23,
    color: '#fff'
  }
}))(Switch);

const StyledSettingDetailsWeb = styled("div")({
  "& .headingTextDisplay":{
    display: "flex",
    alignItems: "center",
    "& *":{
      fontFamily: "Silika-Medium"
    },
    "& .rightArrowBtn":{
      padding: "4px",
      marginLeft: "8px"
    }
  },
  "& .headingText": {
    color: "#010101",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "32px"
},
"& .selectedText": {
    color: "#9B9B9D",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    cursor:"pointer",
    lineHeight: "32px"
},
});

const validationSchema = Yup.object().shape({
  from: Yup.string().matches(/^\d+(\.\d+)?$/, 'Please enter numeric values')
    .required('From value is required.')
    .typeError('Please enter numeric values').nullable(),
  to: Yup.string().matches(/^\d+(\.\d+)?$/, 'Please enter numeric values')
    .required('To value is required.')
    .typeError('Please enter numeric values')
    .test('greater-than-from', 'To value must be greater than From value.', function (value) {
      return parseInt(value) > parseInt(this.parent.from);
    }).nullable()
});
  
const StyledRadio = withStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 18,
        height:18,
        border:"0.8x solid #9B9B9D",
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#fff',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#010101',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 18,
            height: 18,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#010101',
        },
    },
})(({classes,...props }:any) => (
    <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
        icon={<span className={classes.icon} />}
        {...props}
    />
));

class SettingDetails extends React.Component<SettingDetailsProps1>{
  constructor(props: SettingDetailsProps1){
    super(props);
  }

  renderProjectWorkingActionButtons = (to: string | null, from: string | null) => {
    const {isEditProjectWorking, onEditProjectWorking} = this.props;
    let button;
    if (!isEditProjectWorking) {
      if (!to && !from) {
        button = (
          <button type="button" data-test-id="addProjectWorking" onClick={onEditProjectWorking} className="addBtn">
            Add
          </button>
        );
      } else {
        button = (
          <button type="button" onClick={onEditProjectWorking} className="editBtn">
            Edit
          </button>
        );
      }
    }
    return button || <></>
  }

  renderVisibilityPrivacy = () => {
    const {visibilitySetting, onVisibilityChange} = this.props;
    return (
      <StyledVisibilityPrivacy>
        <FormControl component="fieldset" className="formControl">
          <FormLabel component="legend">Who can see my Availability?</FormLabel>
            <RadioGroup value={visibilitySetting.visibility_option} data-test-id="visibilityOption" onChange={onVisibilityChange} name="visibility_option">
              <FormControlLabel value="everyone except my current employer" control={<StyledRadio />} label="Everyone except my current employer" />
              <FormControlLabel value="anyone" control={<StyledRadio />} label="Anyone"  />
              <FormControlLabel value="nobody" control={<StyledRadio />} label="Nobody" />
            </RadioGroup>
        </FormControl>
        <FormControl component="fieldset" className="formControl">
          <FormLabel component="legend">Who can see my hourly rate?</FormLabel>
            <RadioGroup value={visibilitySetting.hourly_rate_option} data-test-id="hourlyRateOption" onChange={onVisibilityChange} name="hourly_rate_option">
              <FormControlLabel value="everyone except my current employer" control={<StyledRadio />} label="Everyone except my current employer" />
              <FormControlLabel value="anyone" control={<StyledRadio />} label="Anyone" />
              <FormControlLabel value="nobody" control={<StyledRadio />} label="Nobody" />
            </RadioGroup>
        </FormControl>
        <FormControl component="fieldset" className="formControl">
          <FormLabel component="legend">Who can see when I'm online?</FormLabel>
            <RadioGroup value={visibilitySetting.online_option} data-test-id="onlineOption" onChange={onVisibilityChange} name="online_option">
              <FormControlLabel value="everyone except my current employer" control={<StyledRadio />} label="Everyone except my current employer" />
              <FormControlLabel value="anyone" control={<StyledRadio />} label="Anyone" />
              <FormControlLabel value="nobody" control={<StyledRadio />} label="Nobody" />
            </RadioGroup>
        </FormControl>
        <Typography className="report">If you experience harassment or inappropriate behavior, please <span>report this issue here.</span></Typography>
      </StyledVisibilityPrivacy>
    )
  }

  renderProjectWorking = () => {
    const {windowWidth, projectWorking, onUpdateProjectWorking, isEditProjectWorking, onEditProjectWorking} = this.props;
    const isMobile = windowWidth <= 599;
    const renderHeader = () => isMobile ? <>Your current hourly rate is:<br/> {`${projectWorking.from}£/h - ${projectWorking.to}£/h`}</> : `Your current hourly rate is ${projectWorking.from}£/h - ${projectWorking.to}£/h`;

    return (
      <Formik
        initialValues={projectWorking}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onUpdateProjectWorking(values);
          setSubmitting(false);
        }}
        data-test-id="Formik"
      >
        {({
            handleChange,
            handleBlur,
            values,
            handleSubmit,
            resetForm,
            setTouched,
            isSubmitting,
            errors,
            touched,
            isValid
        }) => {
          return <StyledForm onSubmit={handleSubmit}>
              <Typography>{!projectWorking.from  ? "Set up your hourly rate (£)" : renderHeader()}</Typography>
              <div className="formDiv">
                  <div className="textField">
                    <label htmlFor="from">From</label>
                    <input
                        name="from"
                        value={values.from}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        data-test-id="fromHour"
                        disabled={!isEditProjectWorking}
                        className={touched.from && errors.from ? "errorInput": ""}
                    />
                    <ErrorMessage name="from" component="div" className="errorStyle" />
                  </div>
                  <div className="textField">
                    <label htmlFor="to">To</label>
                    <input
                        name="to"
                        value={values.to}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        data-test-id="toHour"
                        disabled={!isEditProjectWorking}
                        className={touched.to && errors.to ? "errorInput": ""}
                    />
                    <ErrorMessage name="to" component="div" className="errorStyle" />
                  </div>
              </div>
              <div className="actions">
              {this.renderProjectWorkingActionButtons(values.to, values.from)}
              {isEditProjectWorking ?
              <Button
                className="cancelBtn"
                type="reset"
                data-test-id="projectWorkingCancel"
                onClick={() => {
                  onEditProjectWorking();
                  resetForm();
                }}
              >
                  Cancel
              </Button> : <></>}
              {isEditProjectWorking && 
              <Button 
                className="submitBtn"
                data-test-id="projectWorkingSave" 
                type="submit" 
                classes={{disabled: "disabled-button"}}
                disabled={isSubmitting || !isValid} 
                onClick={() => {
                  setTouched({});
                }}>
                Save
              </Button>}
              </div>
          </StyledForm>
        }}
      </Formik>
    )
  }

  renderNotifications = () => {
    const {notificationSettings, onNotificationSettingChange} = this.props;
    return(
      <StyledNotificationSettings>
          {Object.entries(notificationSettings).map(([key, notification]) => {
            return <div className="notificationSetting">
                <div className="notificationLabel">
                    <div className="label">
                      {notification.title}
                    </div>
                    <div className="description">
                      {notification.description}
                    </div>
                </div>
                <IOSSwitch
                  className="notificationSwitch" 
                  data-test-id="SettingNotification" 
                  checked={notification.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onNotificationSettingChange(e, key as keyof NotificationSetting)} />
            </div>
          })}
      </StyledNotificationSettings>
    )
  }

  renderListItems = () => {
    const {onCloseDialog, onClickEditEmailPasswordShow, showEmailPassword,onForgotPasswordClick, onClickNavigateToLogin, onClickUpdateEmail, isEditEmailModalOpen, isEmailUpdated, onUpdateEmail, displaySubscription, onAgreeButtonClick, windowWidth, termsAndConditions ,selectedSetting, handlePasswordCloseModal, handlePasswordShowModal, onClickOpenSubscription, setOpenEdit,isAccountDeleted, onDeleteAccount, onClickDeleteAccount, isAgreeButtonEnabled, isConfirmDeleteModalOpen, profile} = this.props;
    if(selectedSetting === 0){
      return displaySubscription ? <AccountSubscriptions windowWidth={windowWidth}/> : <>
      <StyledItemHeaderContainer>
        <img src={accountDark} alt="accountDark" />
        <Typography>Account & Billing</Typography>
      </StyledItemHeaderContainer>
      <StyledListContainer>
        <AccountActivity onCloseDialog={onCloseDialog} showEmailPassword={showEmailPassword} onClickEditEmailPasswordShow={onClickEditEmailPasswordShow} onForgotPasswordClick= {onForgotPasswordClick} onUpdateEmail={onUpdateEmail} isEmailUpdated={isEmailUpdated} isEditEmailModalOpen={isEditEmailModalOpen} onClickUpdateEmail={onClickUpdateEmail} onClickNavigateToLogin={onClickNavigateToLogin} isAccountDeleted={isAccountDeleted} onDeleteAccount={onDeleteAccount} onClickDeleteAccount={onClickDeleteAccount} isConfirmDeleteModalOpen={isConfirmDeleteModalOpen} profile={profile} windowWidth={windowWidth} handlePasswordCloseModal={handlePasswordCloseModal} handlePasswordShowModal={handlePasswordShowModal}  onClickOpenSubscription={onClickOpenSubscription} setOpenEdit={setOpenEdit}/>
      </StyledListContainer>
      </>
    }else if(selectedSetting === 1){
      return <>
      <StyledItemHeaderContainer>
        <img src={visibilityDark} alt="visibilityDark" />
        <Typography>Visibility & Privacy</Typography>
      </StyledItemHeaderContainer>
      {this.renderVisibilityPrivacy()}
      </>
    }else if(selectedSetting === 2){
      return <>
        <StyledItemHeaderContainer>
        <img src={termsDark} alt="termsDark" />
        <Typography>Terms & Conditions</Typography>
      </StyledItemHeaderContainer>
        <TermsAndConditions onAgreeButtonClick={onAgreeButtonClick} termsAndConditions={termsAndConditions} isAgreeButtonEnabled={isAgreeButtonEnabled} />
      </>
    }else if(selectedSetting === 3){
      return <>
        <StyledItemHeaderContainer>
          <img src={projectDark} alt="projectDark" />
          <Typography>Project Working</Typography>
        </StyledItemHeaderContainer>
        {this.renderProjectWorking()}
      </>
    }else if(selectedSetting === 4){
      return <>
        <StyledItemHeaderContainer>
          <img src={notificationDark} alt="notificationDark" />
          <Typography>Notifications</Typography>
        </StyledItemHeaderContainer>
        {this.renderNotifications()}
      </>
    }
    return <></>
  }

  renderMobileView = () => {
    const {selectedSetting, onBackButtonClick} = this.props;
    return (
      selectedSetting !== null ? <StyledMobileContainer data-test-id="settingDetailsContainerMobile">
        <StyledBackContainer onClick={onBackButtonClick}>
          <IconButton><KeyboardArrowLeftIcon/></IconButton>
          <Typography>Back</Typography>
        </StyledBackContainer>
        {this.renderListItems()}
      </StyledMobileContainer>: <></>
    )
  }


  render() {
    const {onCloseDialog, onClickEditEmailPasswordShow, showEmailPassword, onForgotPasswordClick, isEmailUpdated, onUpdateEmail, isEditEmailModalOpen,onClickUpdateEmail, onDeleteAccount, onClickNavigateToLogin, onClickDeleteAccount, isAccountDeleted, isConfirmDeleteModalOpen, profile ,onAgreeButtonClick, windowWidth, selectedSetting, displaySubscription, termsAndConditions, closeSubscription, handlePasswordCloseModal, handlePasswordShowModal, onClickOpenSubscription, setOpenEdit, isAgreeButtonEnabled} = this.props;
    const isMobile = windowWidth <= 599;
    if(isMobile){
        return this.renderMobileView();
    }
    return (
        <StyledSettingDetailsWeb data-test-id="settingDetailsContainer">
            <Grid className="headingTextDisplay">
                <Typography id="main-text" onClick={closeSubscription} className={!displaySubscription ? "headingText" : "selectedText"}>{getName(selectedSetting)}</Typography>
                {(displaySubscription && selectedSetting == 0) &&<><IconButton className="rightArrowBtn"><ChevronRightIcon/></IconButton><Typography id="main-text" className={"headingText"}> &nbsp; Subscription </Typography></>}
            </Grid>
            {selectedSetting == 0 && <>
                {!displaySubscription && <AccountActivity onCloseDialog={onCloseDialog} onClickEditEmailPasswordShow={onClickEditEmailPasswordShow} showEmailPassword={showEmailPassword} onForgotPasswordClick={onForgotPasswordClick} onUpdateEmail={onUpdateEmail} isEmailUpdated={isEmailUpdated} isEditEmailModalOpen={isEditEmailModalOpen} onClickUpdateEmail={onClickUpdateEmail} onClickNavigateToLogin={onClickNavigateToLogin} isAccountDeleted={isAccountDeleted} onClickDeleteAccount={onClickDeleteAccount} onDeleteAccount={onDeleteAccount} isConfirmDeleteModalOpen={isConfirmDeleteModalOpen} profile={profile} windowWidth={windowWidth} handlePasswordCloseModal={handlePasswordCloseModal} handlePasswordShowModal={handlePasswordShowModal}  onClickOpenSubscription={onClickOpenSubscription} setOpenEdit={setOpenEdit} />}
                {displaySubscription && <AccountSubscriptions windowWidth={windowWidth}/>}
            </>
            }
            {selectedSetting == 1 ?
                 <>
                 {this.renderVisibilityPrivacy()}
                 </>: <></>
            }
            {selectedSetting == 2 && <TermsAndConditions onAgreeButtonClick={onAgreeButtonClick} termsAndConditions={termsAndConditions} isAgreeButtonEnabled={isAgreeButtonEnabled}/>}
            {selectedSetting == 3 && this.renderProjectWorking()}
            {selectedSetting == 4 && this.renderNotifications()}
        </StyledSettingDetailsWeb>
    )
  }
}

const getName = (selectedSetting: number | null) => {
  if (selectedSetting == 0) {
      return "Account & Billing"
  } else if (selectedSetting == 1) {
      return "Visibility & Privacy"
  } else if (selectedSetting == 2) {
      return "Terms & Conditions"
  } else if (selectedSetting == 3) {
      return "Project working"
  } else if (selectedSetting == 4) {
      return "Notifications"
  }
}

const StyledMobileContainer = styled("div")({
  padding: "25px 0",
  width: "100vw",
  "& *":{
    fontFamily: "Silika-Medium"
  }
});

const StyledBackContainer = styled("div")({
  display: "inline-flex",
  alignItems: "center",
  height: "40px",
  margin: "0 24px",
  "& button":{
    padding: "0px",
    "& svg":{
      height: "24px",
      width: "24px",
      fill: "#010101",
      marginRight: "11px"
    }
  },
  "& p":{
    fontSize: "16px"
  }
});

const StyledItemHeaderContainer = styled("div")({
  display: "inline-flex",
  marginTop: "24px",
  alignItems: "center",
  margin: "24px 24px 12px 24px",
  width: "calc(100% - 48px)",
  "& img":{
    height: "24px",
    width: "24px",
    marginRight: "6.5px"
  },
  "& p":{
    fontSize: "24px"
  }
});

const StyledListContainer = styled("div")({
});

const StyledListItemContainer = styled("div")({
  borderBottom: "1px solid #E2E8F0",
  display: "flex",
  flexFlow: "column",
  padding: "12px 143px 12px 40px",
  "& .label":{
    fontSize: "16px",
    width: "100%",
    marginBottom: "2px",
    lineHeight: "24px"
  },
  "& .description":{
    fontSize:"14px",
    fontFamily: "Silika-Light",
    width: "100%",
    color: "#9B9B9D",
    lineHeight: "20px"
  },
  "& .deleteIcon":{
    display: "inline-flex",
    color: "#FF5959",
    alignItems: "center",
    "& svg":{
      color: "#FF5959",
      marginRight: "8px"
    }
  }
});

const StyledVisibilityPrivacy = styled("div")(({theme}: {theme: Theme}) => ({
  "& *":{
    fontFamily: "Silika-Medium",
  },
  "& .MuiFormLabel-root":{
    fontSize: "16px",
    color: "#0D0C22",
    marginBottom: "12px"
  },
  "& .MuiFormControlLabel-root":{
    color: "#9B9B9D",
    fontSize: "14px"
  },
  "& .formControl":{
    marginTop: "16px",
    marginRight: "26px",
    paddingLeft: "0",
    width: "100%",
    [theme.breakpoints.down('sm')] :{ 
      paddingLeft: "56px !important",
      width: "auto",
      marginRight: "0px"
    }
  },
  "& .report":{
    color: "#010101",
    fontSize: "16px",
    lineHeight: "20px",
    margin: "16px 27px 0 0px",
    [theme.breakpoints.down('sm')] :{ 
      marginLeft: "56px !important"
    },
    "& span":{
      borderBottom: "1px solid #010101"
    }
  }
}));

const StyledForm = styled("form")(({theme}: {theme: Theme}) => ({
  [theme.breakpoints.down('sm')] :{
    width: "auto",
    margin: "0 24px"
  },
  width: "360px",
  "& *":{
    fontFamily: "Silika-Medium",
  },
  "& p":{
    fontSize: "16px",
    lineHeight: "32px",
    marginBottom: "24px"
  },
  "& .formDiv":{
    display: "inline-flex",
    [theme.breakpoints.down('sm')] :{
      display: "flex",
      flexFlow: "column"
    }
  },
  "& .textField":{
    display: "flex",
    flexFlow: "column",
    width: "168px",
    [theme.breakpoints.down('sm')]:{
      width: "100%",
      "&:first-child":{
        marginBottom: "24px"
      }
    },
    "&:first-child":{
      marginRight: "24px"
    }
  },
  "& label":{
    marginBottom: "4px"
  },
  "& input":{
    border: "1px solid #DFDFDF",
    borderRadius: "2px",
    padding: "12px 8px",
    fontSize: "16px",
    lineHeight: "19.3px",
    outline: "none"
  },
  "& .errorInput":{
    border: "1px solid #FF5959"
  },
  "& .errorStyle":{
    color: "#FF5959",
    fontSize: "14px"
  },
  "& button":{
    height: "48px",
    marginTop: "32px",
    borderRadius: "2px",
    width: "104px",
    fontSize: "16px",
    textTransform: "none",
    [theme.breakpoints.down('sm')]:{
      marginTop: "16px",
      width: "74px",
      fontSize: "14px",
      height: "32px"
    }
  },
  "& .submitBtn, .addBtn":{
    background: "#0D0C22",
    color: "#fff",
    border: "1px solid #0D0C22"
  },
  "& .disabled-button":{
    border: "1px solid #9B9B9D !important",
    background: "#9B9B9D !important"
  },
  "& .editBtn, .cancelBtn":{
    color: "#0D0C22",
    background: "#fff",
    border: "1px solid #0D0C22"
  },
  "& .cancelBtn":{
    marginRight: "24px"
  },
  "& .actions":{
    display: "flex",
    justifyContent: "end"
  }
}));

const StyledNotificationSettings = styled("div")(({theme}: {theme: Theme}) => ({
  marginTop: "16px",
  marginRight: "24px",
  width: "728px",
  [theme.breakpoints.down('sm')]:{
    width: "auto"
  },
  "& *":{
    fontSize: "16px",
    lineHeight: "19.3px"
  },
  "& .notificationSetting":{
    marginBottom: "24px",
    paddingLeft: "0px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]:{
      display: "inline-flex",
      paddingLeft: "56px"
    }
  },
  "& .notificationLabel":{
    width: "auto",
    [theme.breakpoints.down('sm')]:{
      width: "calc(100vw - 140px)",
    }
  },
  "& .label":{
    fontFamily: "Silika-Medium",
    marginBottom: "6px"
  },
  "& .description":{
    fontFamily: "Silika-Light",
    color: "#9B9B9D"
  },
  "& .notificationSwitch":{
    marginRight: "0px",
    marginLeft: "auto"
  }
}));

export default SettingDetails;