import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import { Box, Button, Grid, Typography } from "@material-ui/core";
// Customizable Area End

import LandingPage5Controller, {
  Props,
} from "./LandingPage5Controller";
import { CADResearchImg, classSufferingImg, CMFImg, createModelImg, designResearch, exterior, interior, productManagementImg, projectManagementImg, studioEngineeringImg, uiuxImg } from "./assets";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography:{
    fontFamily:"Silka"
  }
});
const StyledContainer = styled(Box)({
  "& .findTalentTitleContainer": {
     paddingTop:"116px",
     paddingRight:"142px",
     paddingLeft:"146px",
     justifyContent:"center",
     backgroundColor: "#F5F3F3",
     "@media(max-width:1280px)": {
      paddingRight:"70px",
      paddingLeft:"70px",
      paddingTop:"70px"
   },
   "@media(max-width:720px)": {
    paddingRight:"10px",
    paddingLeft:"10px",
    paddingTop:"30px"
 },
 "@media(max-width:620px)": {
  paddingRight:"5px",
  paddingLeft:"5px",
  paddingTop:"15px"
},
  },
  "& .findTalentTitle": {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "64px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    "@media(max-width:720px)": {
      fontSize: "36px",
   },
  },
  "& .findPara":{
    fontWeight:"300 !important",
    fontSize: "16px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    color:"#0D0C22",
    marginTop:"49px",
    fontFamily:"Silika-Light",
    lineHeight:"25px",
    "@media(max-width:1280px)": {
      marginTop:"30px",
      fontSize: "14px",
   },
   "@media(max-width:959px)": {
    marginTop:"15px",
    fontSize: "12px",
 },
  },
  "& .designResearch":{
    fontSize: "48px !important",
    justifyContent:"flex-start",
    "@media(max-width:1100px)": {
      fontSize: "36px !important",
    }
  },
  "& .paraOfDesign":{
    justifyContent:"flex-start !important",
    alignItems:"flex-start !important",
    lineHeight:"25px",
    "@media(max-width:1100px)": {
      fontSize: "14px !important",
      lineHeight:"20px",
      marginTop:"20px !important"
    }
  },
  "& .getStartedBtn": {
    width: "125px",
    height: "48px",
    borderRadius: "2px",
    color: "white",
    textTransform: "none",
    fontFamily: "Silika-Medium",
    marginTop: "32px",
  },
  "& .startBtnProject": {
    backgroundColor: "#0D0C22"
  },
  "& .designResearchContainer":{
    paddingTop:"129px",
    paddingBottom:"129px",
    display:"flex",
    justifyContent:"space-between",
    paddingRight:"133px",
    paddingLeft:"133px",
    "@media(max-width:1300px)": {
      paddingTop:"100px",
      paddingBottom:"100px",
      paddingRight:"110px",
      paddingLeft:"110px",
    },
    "@media(max-width:1100px)": {
      paddingTop:"60px",
      paddingBottom:"60px",
      paddingRight:"70px",
      paddingLeft:"70px",
    },
    "@media(max-width:959px)": {
      justifyContent:"center"
    },
    "@media(max-width:100px)": {
      paddingTop:"30px",
      paddingBottom:"30px",
      paddingRight:"40px",
      paddingLeft:"40px",
    },
  },
  "& .designResearchImg":{
    width:"441px",
    height:"313px",
    marginTop:"29px",
    "@media(max-width:1100px)": {
      width:"80%",
      marginTop:"5px",
    },
    "@media(max-width:959px)": {
      width:"100%",
    }
  },
  "& .exteriorImg": {
    width: "570px",
    height: "411.74px",
    "@media(max-width:1100px)": {
      width:"90%"
    },
    "@media(max-width:959px)": {
      width:"100%"
    }
  },
  "& .CMFImg":{
    width: "586px",
   height: "409px",
   marginTop:"47px",
   "@media(max-width:1100px)": {
    width:"80%"
  },
  "@media(max-width:959px)": {
    width:"100%"
  }
  },
  "& .uiuxImg":{
    width:"578px",
    height:"333px",
    marginTop:"85px",
    "@media(max-width:1383px)": {
      width:"80% !important"
    },
    "@media(max-width:959px)": {
      width:"100% !important",
      marginTop:"0px",
    }
  },
  "& .creativeImg":{
    width: "445px",
    height: "313px",
    marginTop:"29px",
    "@media(max-width:1100px)": {
      width:"80%"
    },
    "@media(max-width:959px)": {
      width:"100%",
      marginTop:"0px",
    }
  },
  "& .CADResearchImg":{
    width: "570px",
    height: "411px",
    "@media(max-width:1100px)": {
      width:"80%"
    },
    "@media(max-width:959px)": {
      width:"100%"
    }
  },
  "& .imgDivContaier":{
    display:"flex",
    "@media(max-width:959px)": {
      justifyContent:"center"
    }
  },
  "& .exteriorImgContainer":{
    display:"flex",
    justifyContent:"flex-end"
  },
  "& .interiorImg":{
    width:"493px",
    height:"347px",
    "@media(max-width:1100px)": {
      width:"80%"
    },
    "@media(max-width:959px)": {
      width:"100%"
    }
  },
  "& .studioEngineeringImg":{
    width:"586px",
    height:"409px",
    marginTop:"47px",
    marginBottom:"47px",
    "@media(max-width:1100px)": {
      width:"80%"
    },
    "@media(max-width:959px)": {
      width:"100%",
      marginTop:"0px",
      marginBottom:"0px",
    }
  },
  "& .projectManagementImg":{
    width:"576px",
    height:"333px",
    marginTop:"85px",
    marginBottom:"85px",
    "@media(max-width:1250px)": {
      width:"80%"
    },
    "@media(max-width:959px)": {
      width:"100%",
      marginTop:"0px",
      marginBottom:"0px",
    }
  },
  "& .productMangementImg":{
    width:"583.97px",
    height:"497.62px",
    "@media(max-width:1100px)": {
      width:"80%"
    },
    "@media(max-width:959px)": {
      width:"100%"
    }
  },
  "& .studioEngineeringContainer":{
    paddingLeft:"94px",
    paddingRight:"93px",
    paddingTop:"166px",
    paddingBottom:"165px"
  },
  "& .projectManagementContainer":{
    paddingLeft:"97px",
    paddingRight:"96px",
    paddingTop:"112px",
    paddingBottom:"112px"
  },
  "& .productManagementContainer":{
    paddingLeft:"93px",
    paddingRight:"97px",
    paddingTop:"110px",
    paddingBottom:"110px"
  },
  "& .sufferingContainer":{
    paddingLeft:"107px",
    paddingRight:"111px",
    paddingTop:"57.5px",
    paddingBottom:"57.5px"
  },
  "& .surfacingImg":{
    width:"491.29px",
    height:"422.19px",
    marginTop:"80px",
    marginBottom:"80px",
    "@media(max-width:959px)": {
      width:"100%",
      marginTop:"0px",
      marginBottom:"0px",
    }
  },
  "& .cadResearchContainer":{
    paddingLeft:"104px",
    paddingRight:"103px",
    paddingTop:"203px",
    paddingBottom:"203px"
  },
  "& .creativeModellingContainer":{
    paddingLeft:"133px",
    paddingRight:"133px",
    paddingTop:"129px",
    paddingBottom:"129px"
  },
  "& .contentDivSideDesc": {
    "@media(max-width:1400px)": {
      maxWidth: "582px",
    },
    "@media(max-width:959px)": {
      maxWidth: "100%",
      padding: "20px"
    }
  },
  "& .uiuxContainer":{
    paddingLeft:"97px",
    paddingRight:"96px",
    paddingTop:"112px",
    paddingBottom:"112px"
  },
  "& .cmfContanier":{
    paddingLeft:"95px",
    paddingRight:"93px",
    paddingTop:"166px",
    paddingBottom:"165px"
  },
  "& .interiorContainer":{
    paddingLeft:"108px",
    paddingRight:"107px",
    paddingTop:"112px",
    paddingBottom:"112px"
  },
  "& .exteriorContainer":{
    paddingLeft:"104px",
    paddingRight:"103px",
    paddingTop:"203px",
    paddingBottom:"203px"
  },
  "& .paragraphTitle":{
    textAlign: "center",
    maxWidth:"1112px"
  }

});
// Customizable Area End

export default class LandingPage5Web extends LandingPage5Controller {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
       // Customizable Area Start
       // Customizable Area End
      return (
        // Customizable Area Start
        // Required for all blocks
        <ThemeProvider theme={theme}>
          <StyledContainer>
            <LandingHeader navigation={this.props.navigation} />
            <Grid container className="findTalentTitleContainer">
              <Grid item xl={12}>
                <Typography className="findTalentTitle">
                  Find Design Talent
                </Typography>
                <Typography className="findPara paragraphTitle">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum ultricies scelerisque imperdiet. Vivamus
                  scelerisque orci tristique Vivamus scelerisque orci tristique
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              className="findTalentTitleContainer designResearchContainer"
            >
              <Grid item xl={6} lg={6} md={6} sm={12} className="imgDivContaier">
                <img src={designResearch} alt="designResearch" className="designResearchImg imgInCategory"/>
              </Grid>
              <Grid item xl={6} lg={6} md={6}  sm={12}  className="contentDivSideDesc">
                <Typography className="findTalentTitle designResearch">
                  Design Research
                </Typography>
                <Typography className="findPara paraOfDesign">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum  ultricies scelerisque imperdiet. Vivamus scelerisque orci tristique  magna uscipit.
                  Suspendisse est orci, placerat ac lectus vitae,  tempor ullamcorper ante.
                  <br/>
                  <div style={{marginTop:"10px"}}>
                  Ut vehicula sodales dui sollicitudin aliquet. Donec ultrices eros ut
                  mattis venenatis. Ut vestibulum commodo odio, ac placerat augue blandit  at.
                  Aliquam lobortis elit vel est vehicula cursus. Morbi tincidunt  pharetra neque eu pharetra.
                  Maecenas ut auctor quam. Duis suscipit dui  at nisl tincidunt volutpat ut non ipsum. Duis a tellus at sapien
                  ultrices commodo. Ut eget volutpat mauris. Morbi feugiat feugiat varius.  Fusce cursus semper odio, ac dapibus lectus dapibus vel.
                  </div>
                </Typography>
                <Button className="getStartedBtn startBtnProject">
                  Find Talent
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              className="findTalentTitleContainer designResearchContainer"
              style={{ backgroundColor: "white" }}
            >
              <Grid item xl={6}lg={6} md={6} sm={12} className="contentDivSideDesc">
                <Typography className="findTalentTitle designResearch">
                  Exterior Design
                </Typography>
                <Typography className="findPara paraOfDesign">
                  Our community features the world’s best talented professionals from
                  a range of specialist skillsets including exterior and interior design, UX and CMF.
                  Everyone is peer vetted to ensure you find the best people immediately.
                  <br/>
                  <div style={{marginTop:"20px"}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum  ultricies scelerisque imperdiet. Vivamus scelerisque orci tristique  magna uscipit.
                  Suspendisse est orci, placerat ac lectus vitae,  tempor ullamcorper ante.
                  </div>
                </Typography>
                <Button className="getStartedBtn startBtnProject">
                  Find Talent
                </Button>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12}>
                <img src={exterior} alt="designResearch" className="exteriorImg imgInCategory"/>
              </Grid>
            </Grid>
            <Grid
              container
              className="findTalentTitleContainer designResearchContainer interiorContainer"
            >
              <Grid item xl={6} lg={6}  md={6} sm={12}>
                 <img src={interior} alt="designResearch" className="interiorImg imgInCategory"/>
              </Grid>
              <Grid item xl={6} lg={6}  md={6} sm={12} className="contentDivSideDesc">
                <Typography className="findTalentTitle designResearch">
                  Interior Design
                </Typography>
                <Typography className="findPara paraOfDesign">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
                  ultricies scelerisque imperdiet. Vivamus scelerisque orci tristique
                  magna uscipit. Suspendisse est orci, placerat ac lectus vitae,
                  tempor ullamcorper ante.
                  <br/>
                  <div style={{marginTop:"10px"}}>
                  Ut vehicula sodales dui sollicitudin aliquet.
                  Donec ultrices eros ut  mattis venenatis. Ut vestibulum commodo odio,
                  ac placerat augue blandit  at. Aliquam lobortis elit vel est vehicula cursus.
                  Morbi tincidunt  pharetra neque eu pharetra. Maecenas ut auctor quam. Duis suscipit dui
                  at nisl tincidunt volutpat ut non ipsum. Duis a tellus at sapien  ultrices commodo.
                  Ut eget volutpat mauris. Morbi feugiat feugiat varius.  Fusce cursus semper odio, ac dapibus
                  lectus dapibus vel.
                  </div>
                </Typography>
                <Button className="getStartedBtn startBtnProject">
                  Find Talent
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              className="findTalentTitleContainer designResearchContainer cmfContanier"
              style={{ backgroundColor: "white" }}
            >
              <Grid item xl={6} lg={6}  md={6} sm={12} className="contentDivSideDesc">
                <Typography className="findTalentTitle designResearch">
                  CMF Design
                </Typography>
                <Typography className="findPara paraOfDesign">
                  Our community features the world’s best talented professionals from
                  a range of specialist skillsets including exterior and interior design,
                  UX and CMF. Everyone is peer vetted to ensure you find the best people immediately.
                  <br/>
                  <div style={{marginTop:"20px"}}>
                  Ut vehicula sodales dui sollicitudin aliquet. Donec ultrices eros ut
                  mattis venenatis. Ut vestibulum commodo odio, ac placerat augue blandit  at.
                  Aliquam lobortis elit vel est vehicula cursus. Morbi tincidunt  pharetra neque eu pharetra.
                  Maecenas ut auctor quam. Duis suscipit dui  at nisl tincidunt volutpat ut non ipsum. Duis a tellus at sapien
                  ultrices commodo. Ut eget volutpat mauris. Morbi feugiat feugiat varius.  Fusce cursus semper odio, ac dapibus lectus dapibus ve
                  </div>
                </Typography>
                <Button className="getStartedBtn startBtnProject">
                  Find Talent
                </Button>
              </Grid>
              <Grid item xl={6} lg={6}  md={6} sm={12}>
                <img src={CMFImg} alt="CMFImg" className="CMFImg imgInCategory"/>
              </Grid>
            </Grid>
            <Grid
              container
              className="findTalentTitleContainer designResearchContainer uiuxContainer"
            >
              <Grid item xl={6} lg={6}  md={6} sm={12}>
              <img src={uiuxImg} alt="CMFImg" className="uiuxImg imgInCategory"/>
              </Grid>
              <Grid item xl={6} lg={6}  md={6} sm={12} className="contentDivSideDesc">
                <Typography className="findTalentTitle designResearch">
                  UX/UI Design
                </Typography>
                <Typography className="findPara paraOfDesign">
                  Ut vehicula sodales dui sollicitudin aliquet. Donec ultrices eros ut  mattis venenatis.
                  Ut vestibulum commodo odio, ac placerat augue blandit  at. Aliquam lobortis elit vel est vehicula cursus. Morbi tincidunt
                  pharetra neque eu pharetra. Maecenas ut auctor quam. Duis suscipit dui  at nisl tincidunt volutpat ut non ipsum. Duis a tellus at sapien
                  ultrices commodo. Ut eget volutpat mauris. Morbi feugiat feugiat varius.  Fusce cursus semper odio, ac dapibus lectus dapibus vel.
                  <br/>
                  <div style={{marginTop:"20px"}}>
                  Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Vestibulum
                  ultricies scelerisque imperdiet. Vivamus scelerisque orci tristique
                  magna uscipit. Suspendisse est orci, placerat ac lectus vitae,  tempor ullamcorper ante.
                  </div>
                </Typography>
                <Button className="getStartedBtn startBtnProject">
                  Find Talent
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              className="findTalentTitleContainer designResearchContainer creativeModellingContainer"
              style={{ backgroundColor: "white" }}
            >
              <Grid item xl={6} lg={6} md={6} sm={12}>
              <img src={createModelImg} alt="CMFImg" className="creativeImg imgInCategory"/>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12}  className="contentDivSideDesc">
                <Typography className="findTalentTitle designResearch">
                  Creative Modelling
                </Typography>
                <Typography className="findPara paraOfDesign">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum  ultricies scelerisque imperdiet.
                  Vivamus scelerisque orci tristique  magna uscipit. Suspendisse est orci, placerat ac lectus vitae,  tempor ullamcorper ante.
                  <div style={{marginTop:"20px"}}>
                  Ut vehicula sodales dui sollicitudin aliquet. Donec ultrices eros ut
                  mattis venenatis. Ut vestibulum commodo odio, ac placerat augue blandit
                  at. Aliquam lobortis elit vel est vehicula cursus. Morbi tincidunt  pharetra neque eu pharetra.
                  Maecenas ut auctor quam. Duis suscipit dui  at nisl tincidunt volutpat ut non ipsum. Duis a tellus at sapien
                  ultrices commodo. Ut eget volutpat mauris. Morbi feugiat feugiat varius.  Fusce cursus semper odio, ac dapibus lectus dapibus vel.
                  </div>
                </Typography>
                <Button className="getStartedBtn startBtnProject">
                  Find Talent
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              className="findTalentTitleContainer designResearchContainer cadResearchContainer"
            >
              <Grid item xl={6} lg={6}  md={6} sm={12} className="contentDivSideDesc">
                <Typography className="findTalentTitle designResearch">
                  CAD Research
                </Typography>
                <Typography className="findPara paraOfDesign">
                  Our community features the world’s best talented professionals from
                  a range of specialist skillsets including exterior
                  and interior design, UX and CMF. Everyone is peer vetted to ensure you find the best people immediately.
                  <br/>
                  <div style={{marginTop:"20px"}}>
                  Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Vestibulum
                  ultricies scelerisque imperdiet. Vivamus scelerisque orci tristique
                  magna uscipit. Suspendisse est orci, placerat ac lectus vitae,  tempor ullamcorper ante.
                  </div>
                </Typography>
                <Button className="getStartedBtn startBtnProject">
                  Find Talent
                </Button>
              </Grid>
              <Grid item xl={6} lg={6}  md={6} sm={12}>
              <img src={CADResearchImg} alt="CADResearchImg" className="CADResearchImg imgInCategory"/>
              </Grid>
            </Grid>
            <Grid
              container
              className="findTalentTitleContainer designResearchContainer sufferingContainer"
              style={{ backgroundColor: "white" }}
            >
              <Grid item xl={6} lg={6}  md={6} sm={12}>
                <img src={classSufferingImg} alt="classSufferingImg" className="imgInCategory surfacingImg"/>
              </Grid>
              <Grid item xl={6} lg={6}  md={6} sm={12}  className="contentDivSideDesc">
                <Typography className="findTalentTitle designResearch">
                  Class A surfacing
                </Typography>
                <Typography className="findPara paraOfDesign">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum  ultricies scelerisque imperdiet. Vivamus scelerisque orci tristique  magna uscipit.
                  Suspendisse est orci, placerat ac lectus vitae,
                  tempor ullamcorper ante.
                  <br/>
                  <div style={{marginTop:"20px"}}>
                  Ut vehicula sodales dui sollicitudin aliquet.
                  Donec ultrices eros ut  mattis venenatis. Ut vestibulum commodo odio,
                  ac placerat augue blandit  at. Aliquam lobortis elit vel est vehicula cursus.
                  Morbi tincidunt  pharetra neque eu pharetra. Maecenas ut auctor quam. Duis suscipit dui
                  at nisl tincidunt volutpat ut non ipsum. Duis a tellus at sapien  ultrices commodo.
                  Ut eget volutpat mauris. Morbi feugiat feugiat varius.  Fusce cursus semper odio, ac
                  dapibus lectus dapibus vel.
                  </div>
                </Typography>
                <Button className="getStartedBtn startBtnProject">
                  Find Talent
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              className="findTalentTitleContainer designResearchContainer productManagementContainer"
            >
              <Grid item xl={6} lg={6}  md={6} sm={12} className="contentDivSideDesc">
                <Typography className="findTalentTitle designResearch">
                  Product Management
                </Typography>
                <Typography className="findPara paraOfDesign">
                  Our community features the world's best talented professionals from
                  a range of specialist skillsets including exterior and interior design,
                  UX and CMF. Everyone is peer vetted to ensure you find the best people immediately.

                  Ut vehicula sodales dui sollicitudin aliquet. Donec ultrices eros ut
                  mattis venenatis. Ut vestibulum commodo odio, ac placerat augue blandit  at.
                  Aliquam lobortis elit vel est vehicula cursus. Morbi tincidunt  pharetra neque eu pharetra.
                  Maecenas ut auctor quam. Duis suscipit dui  at nisl tincidunt volutpat ut non ipsum. Duis a tellus at sapien
                  ultrices commodo. Ut eget volutpat mauris. Morbi feugiat feugiat varius.  Fusce cursus semper odio, ac dapibus
                  lectus dapibus ve
                </Typography>
                <Button className="getStartedBtn startBtnProject">
                  Find Talent
                </Button>
              </Grid>
              <Grid item xl={6} lg={6}  md={6} sm={12}>
                <img src={productManagementImg} alt="productManagementImg" className="imgInCategory productMangementImg"/>
              </Grid>
            </Grid>
            <Grid
              container
              className="findTalentTitleContainer designResearchContainer projectManagementContainer"
              style={{ backgroundColor: "white" }}
            >
              <Grid item xl={6} lg={6}  md={6} sm={12}>
                <img src={projectManagementImg} alt="projectManagementImg" className="imgInCategory projectManagementImg"/>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} className="contentDivSideDesc">
                <Typography className="findTalentTitle designResearch">
                  Project Management
                </Typography>
                <Typography className="findPara paraOfDesign">
                  Ut vehicula sodales dui sollicitudin aliquet.
                  Donec ultrices eros ut  mattis venenatis. Ut vestibulum commodo odio,
                  ac placerat augue blandit  at. Aliquam lobortis elit vel est vehicula cursus.
                  Morbi tincidunt  pharetra neque eu pharetra. Maecenas ut auctor quam. Duis suscipit dui
                  at nisl tincidunt volutpat ut non ipsum. Duis a tellus at sapien  ultrices commodo.
                  Ut eget volutpat mauris. Morbi feugiat feugiat varius.  Fusce cursus semper odio, ac dapibus lectus dapibus vel.
                  <br />
                  <div style={{marginTop:"20px"}}>
                  Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit.
                  Vestibulum  ultricies scelerisque imperdiet.
                  Vivamus scelerisque orci tristique  magna uscipit.
                  Suspendisse est orci, placerat ac lectus vitae,  tempor ullamcorper ante.
                  </div>
                </Typography>
                <Button className="getStartedBtn startBtnProject">
                  Find Talent
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              className="findTalentTitleContainer designResearchContainer studioEngineeringContainer"
            >
              <Grid item xl={6} lg={6}  md={6} sm={12} className="contentDivSideDesc">
                <Typography className="findTalentTitle designResearch">
                  Studio Engineering
                </Typography>
                <Typography className="findPara paraOfDesign">
                  Our community features the world’s best talented professionals from
                  a range of specialist skillsets including exterior and interior design,
                  UX and CMF. Everyone is peer vetted to ensure you find the best people immediately.
                  <br/>
                  <div style={{marginTop:"25px"}}>
                  Ut vehicula sodales dui sollicitudin aliquet.
                  Donec ultrices eros ut  mattis venenatis. Ut vestibulum commodo odio,
                  ac placerat augue blandit  at. Aliquam lobortis elit vel est vehicula cursus.
                  Morbi tincidunt  pharetra neque eu pharetra. Maecenas ut auctor quam. Duis suscipit dui
                  at nisl tincidunt volutpat ut non ipsum. Duis a tellus at sapien  ultrices commodo. Ut eget volutpat mauris.
                  Morbi feugiat feugiat varius.  Fusce cursus semper odio, ac dapibus lectus dapibus ve
                  </div>
                </Typography>
                <Button className="getStartedBtn startBtnProject">
                  Find Talent
                </Button>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12}>
                <img src={studioEngineeringImg} alt="studioEngineeringImg" className="imgInCategory studioEngineeringImg"/>
              </Grid>
            </Grid>
          </StyledContainer>
          <LandingFooter navigation={this.props.navigation} />
        </ThemeProvider>
        // Customizable Area End
      );
    }
  }
  // Customizable Area Start
  // Customizable Area End
