import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles, createStyles, StyleRules } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import { toast } from 'react-toastify';
import * as Yup from 'yup';

type FlexibleObject = {
  [key: string]: string | number | boolean; // Define allowed types
};

type Response = 
  | { error: string }
  | { status: number; message: string };

interface ResetPassword{
  old_password: string;
  new_password: string;
  confirm_new_password: string;
}
interface ProjectWorking {
  from: string,
  to: string
}

export interface SettingHeadingProps1 {
  selectedSetting: number | null;
  onTabChange: (value: number) => void;
  windowWidth: number;
}

interface NotificationType{
  title: string,
  description: string,
  value: boolean
}
export interface NotificationSetting{
  notify_events: NotificationType;
  notify_chats: NotificationType;
  notify_timesheets: NotificationType;
  notify_project_status: NotificationType;
  notify_invitations: NotificationType;
  notify_invoicing: NotificationType;
  notify_credited: NotificationType;
}

export interface Profile {
  id: string;
  type: "profile";
  attributes: {
    id: number;
    country: string;
    county: string;
    city: string;
    postal_code: string | null;
    account_id: number;
    first_name: string;
    last_name: string;
    bio: string | null;
    skill: string[];
    phone_number: string;
    profession: string[];
    headline: string;
    company_name: string;
    company_number: string;
    registered_address: string;
    vat_number: string;
    availability: "open" | "closed";
    thumbs_up_count: number;
    preferred_type_of_work: string | null;
    visibility_option: string | null;
    hourly_rate_option: string | null;
    online_option: string | null;
    dates: string | null;
    seniority_level: string;
    work_location: string | null;
    work: string[];
    hourly_rate: number | null;
    collaborators: string[];
    tags: string[];
    description: string;
    photo: string;
    media: {
      vimeo: string;
      tikTok: string;
      behance: string;
      youtube: string;
      linkedin: string;
      instagram: string;
    };
    unavailable_dates: string;
    hourly_rate_from?: string | number | null;
    hourly_rate_to?: string | number | null;
    email: string;
    is_like: boolean;
  };
}

export interface SettingDetailsProps1 extends SubProps, AccountActivityProps{
  onBackButtonClick?: () => void;
  onAgreeButtonClick: () => void;
  selectedSetting: number | null;
  displaySubscription: boolean;
  visibilitySetting: {
    visibility_option: string,
    hourly_rate_option: string,
    online_option: string
  };
  profile: Profile | null;
  termsAndConditions: string;
  projectWorking: ProjectWorking;
  isEditProjectWorking: boolean;
  notificationSettings: NotificationSetting;
  onNotificationSettingChange:(e: React.ChangeEvent<HTMLInputElement>, key: keyof NotificationSetting)=> void;
  onVisibilityChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpdateProjectWorking: (data: ProjectWorking) => void;
  onEditProjectWorking: () => void;
  handlePasswordShowModal: () => void
  handlePasswordCloseModal: ()=> void
  closeSubscription: () => void;
  windowWidth: number;
  isAgreeButtonEnabled: boolean;
}

export interface AccountActivityProps{
  onClickOpenSubscription: () => void;
  setOpenEdit: () => void;
  handlePasswordShowModal: () => void;
  handlePasswordCloseModal: () => void;
  windowWidth: number;
  profile: Profile | null;
  isConfirmDeleteModalOpen: boolean;
  onDeleteAccount: () => void;
  onClickDeleteAccount: () => void;
  isAccountDeleted: boolean;
  onClickNavigateToLogin: () => void;
  isEditEmailModalOpen: boolean;
  isEmailUpdated: boolean;
  onClickUpdateEmail: () => void;
  onUpdateEmail: (data: {new_email: string, confirm_new_email: string, password: string}) => void;
  onForgotPasswordClick: (sendLink?: boolean) => void;
  showEmailPassword: boolean;
  onClickEditEmailPasswordShow: () => void;
  onCloseDialog: () => void;
}
// Customizable Area End

export const configJSON = require("./config");

export const styles = (): StyleRules<string, {}> => createStyles({
  leftPadding: {
    paddingLeft: "2% !important",
    paddingTop: "25px !important",
  }
})

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  selectedSetting: number | null;
  // Customizable Area Start
  openEdit: boolean;
  openEditConfirmation: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  showNewPassword: boolean;
  openEditMail: boolean;
  displaySubscription: boolean;
  first: boolean;
  second: boolean;
  third: boolean;
  fourth: boolean;
  changePasssword:boolean;
  dialouge: {

    open: boolean,
    title: string,
    description: string

  },
  cardSaved: {

    open: boolean,
    title: string,
    description: string

  },
  visibilitySetting: {
    visibility_option: string,
    hourly_rate_option: string,
    online_option: string
  }
  profile: Profile | null
  termsAndConditions: string;
  projectWorking: ProjectWorking;
  notificationSetting: NotificationSetting;
  isEditProjectWorking: boolean;
  resetPasswordSucceed: boolean;
  isSendLinkModalOpen: boolean;
  windowWidth: number;
  isAgreeButtonEnabled: boolean;
  isConfirmDeleteModalOpen: boolean;
  isAccountDeleted:boolean;
  isEditEmailModalOpen: boolean;
  isEmailUpdated: boolean;
  showEmailPassword: boolean;
  verificationLinkDisabled: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface SettingHeadingProps {
  values: {
    changePasssword:boolean;
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    selectedSetting: number | null;
    showPassword: boolean
  };
  setSettigValue: any
}

export interface SettingDetailsProps {
  handlePasswordClose:()=> void;
  openSubscription: () => void;
  closeSubscription: () => void;
  values: {
    changePasssword:boolean;
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    selectedSetting: number | null;
    openEdit: boolean;
    openEditConfirmation: boolean;
    showPassword: boolean;
    openEditMail: boolean;
    displaySubscription: boolean;
    dialouge: {

      open: boolean,
      title: string,
      description: string

    };
    cardSaved: {

      open: boolean,
      title: string,
      description: string

    }
  };
  setOpenEdit: () => void;
  setOpenConfirmation: () => void;
  setCloseEdit: () => void;
  setCloseConfirmation: () => void;
  handleClickShowPassword: () => void;
  setOpenEmail: () => void;
  setCloseEmail: () => void;
  handleFirst: () => void;
  handleSecond: () => void;
  handleThird: () => void;
  handleFourth: () => void;
  openDialouge: () => void;
  closeDialouge: () => void;
  openCardSaved: () => void;
  closeCardSaved: () => void;
}

export interface SubProps {
  openDialouge: () => void;
  closeDialouge: () => void;
  closeCardSaved: () => void;
  dialouge: {
    open: boolean,
    title: string,
    description: string
  },
  cardSaved: {
    open: boolean,
    title: string,
    description: string
  }
}
export interface EditFieldProps {
  valuesProp: {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    selectedSetting: number | null;
    openEdit: boolean;
    openEditConfirmation: boolean;
    showPassword: boolean;
    changePasssword:boolean
  },
  open: boolean,
  handleClose: () => void;
  setOpenConfirmation: () => void;
  handleClickShowPassword: () => void;


}

export interface CardSavedProps {

  dialouge: {
    open: boolean,
    title: string,
    description: string
  },
  handleClose: () => void;
}

export interface SubscriptionProps {
  dialouge: {
    open: boolean,
    title: string,
    description: string
  };
  setDialouge: (dialouge: { open: boolean; title: string; description: string }) => void;
  closeDialouge: () => void;
}

export default class SettingsProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  visibilitySettingApiCallId: string | null = "";
  profileApiCallId: string | null = "";
  termsAndConditionsApiCallId: string | null = "";
  projectWorkingApiCallId: string | null = "";
  notificationSettingApiCallId: string | null = "";
  fetchNotifcationSettingApiCallId: string | null = "";
  sendResetPasswordLinkApiCallId: string | null = "";
  resetPasswordApiCallId: string | null = "";
  agreeTermsAndConditionsApiCallId: string | null = "";
  deleteAccountApiCallId: string | null = "";
  editEmailApiCallId: string | null = "";
  validationSchema = Yup.object().shape({
    old_password: Yup.string().required('Old password is required'),
    new_password: Yup.string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z]).*$/,
          'Password must contain at least one uppercase letter and one lowercase letter.'
        )
        .required('New password is required'),
    confirm_new_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    });

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleResize = this.handleResize.bind(this);
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      selectedSetting: null,
      openEdit: false,
      openEditConfirmation: false,
      showPassword: false,
      openEditMail: false,
      displaySubscription: false,
      first: false,
      second: false,
      third: false,
      fourth: false,
      changePasssword:false,
      dialouge: {

        open: false,
        title: "",
        description: ""

      },
      cardSaved:{
        open: false,
        title: "",
        description: ""

      }
      // Customizable Area Start
      ,showConfirmPassword: false,
      showNewPassword: false,
      visibilitySetting: configJSON.visibilitySetting,
      profile: null,
      termsAndConditions: "",
      projectWorking: {
        from: "",
        to: ""
      },
      notificationSetting: configJSON.notificationSetting,
      isEditProjectWorking: false,
      resetPasswordSucceed: false,
      isSendLinkModalOpen: false,
      windowWidth: window.innerWidth,
      isAgreeButtonEnabled: false,
      isConfirmDeleteModalOpen: false,
      isAccountDeleted: false,
      isEditEmailModalOpen: false,
      isEmailUpdated: false,
      showEmailPassword: false,
      verificationLinkDisabled: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }





  // web events
  setSettigValue = (value: number) => {
    this.setState({ selectedSetting: value });
  };

  setOpenEdit = () => {
    this.setState({ openEdit: true })
  }
  setCloseEdit = () => {
    this.setState({ openEdit: false })
  }

  setOpenEmail = () => {
    this.setState({ openEditMail: true })
  }
  setCloseEmail = () => {
    this.setState({ openEditMail: false })
  }

  setOpenConfirmation = () => {
    this.setState({ openEditConfirmation: true })
  }
  setCloseConfirmation = () => {
    this.setState({ openEditConfirmation: false })
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  };
  openSubscription = () => {
    this.setState({ displaySubscription: true })
  }
  closeSubscription = () => {
    this.setState({ displaySubscription: false })
  }
  handleFirst = () => {
    this.setState({ first: !this.state.first })
  }
  handleSecond = () => {
    this.setState({ second: !this.state.second })

  }
  handleThird = () => {
    this.setState({ third: !this.state.third })

  }
  handleFourth = () => {
    this.setState({ fourth: !this.state.fourth })

  }

  openDialouge = () => {
    this.setState({
      dialouge: {
        open: true,
        title: "Downgrade Plan",
        description: "Are you sure you wish to downgrade your plan?"
      }
    })

  }
  closeDialouge = () => {
    this.setState({
      dialouge: {
        open: false,
        title: "",
        description: ""
      }
    })

  }
  openCardSaved = () => {
    this.setState({
      dialouge: {
        open: true,
        title: "Downgrade Plan",
        description: "Thank you. The requested changes will be implemented for your next billing period."
      }
    })
  }
  closeCardSaved = () => {
    this.setState({
      dialouge: {
        open: false,
        title: "",
        description: ""
      }
    })
  }
  handlePasswordClose = () => {
    this.setState({changePasssword:!this.state.changePasssword})
  }
  
  async componentDidMount(){
    this.fetchProfile();
    this.fetchTermsAndConditions();
    this.fetchNotificationSetting();
    window.addEventListener("resize", this.handleResize);
    if(window.innerWidth > 599){
      this.setState({selectedSetting: 0});
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
      const innerWidth = window.innerWidth;
      this.setState({ windowWidth:  innerWidth});
      if(innerWidth > 599 && this.state.selectedSetting === null){
        this.setState({selectedSetting: 0});
      }
  }

  async receive(from: string, message: Message)  {
   
    const responseType = getName(MessageEnum.RestAPIResponceMessage);

    if(getName(MessageEnum.NavigationPayLoadMessage) === message.id){
      const data = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
      if(data && data?.tab) this.setSettigValue(data.tab as number);
    }else if(responseType === message.id){
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if(!Boolean(errorJson)){
      if(responseJson.errors){
        this.handleTokenError(responseJson.errors);
      }else{
        switch(apiRequestCallId){
          case this.profileApiCallId:{
            const updatedVisibilitySetting = {...this.state.visibilitySetting};
            const attributes = responseJson.data.attributes;
            updatedVisibilitySetting.hourly_rate_option = attributes.hourly_rate_option;
            updatedVisibilitySetting.visibility_option = attributes.visibility_option;
            updatedVisibilitySetting.online_option = attributes.online_option;
            this.handleProjectWorkingResponse(attributes.hourly_rate_to, attributes.hourly_rate_from)
            this.setState({profile: responseJson.data, visibilitySetting: updatedVisibilitySetting });
            break;
          }

          case this.termsAndConditionsApiCallId:{
            this.setState({termsAndConditions: responseJson.data[0].description});
            break;
          }

          case this.fetchNotifcationSettingApiCallId:{
            const attributes = responseJson.data.attributes;
            const updatedNotificationSetting = {...this.state.notificationSetting}
            updatedNotificationSetting["notify_events"].value = Boolean(attributes.notify_events);
            updatedNotificationSetting["notify_chats"].value= Boolean(attributes.notify_chats);
            updatedNotificationSetting["notify_timesheets"].value = Boolean(attributes.notify_timesheets);
            updatedNotificationSetting["notify_project_status"].value = Boolean(attributes.notify_project_status);
            updatedNotificationSetting["notify_invitations"].value = Boolean(attributes.notify_invitations);
            updatedNotificationSetting["notify_credited"].value = Boolean(attributes.notify_credited);
            updatedNotificationSetting["notify_invoicing"].value= Boolean(attributes.notify_invoicing);
            this.setState({notificationSetting: updatedNotificationSetting, isAgreeButtonEnabled: attributes.is_agree});
            break;
          }

          case this.projectWorkingApiCallId: {
            this.setState({isEditProjectWorking: false});
            break;
          }
          case this.resetPasswordApiCallId: {
              this.handleResetPasswordResponse(responseJson);
              break;
            }
          case this.sendResetPasswordLinkApiCallId:{
              this.handleSendResetPasswordLink(responseJson);
              break;
          }
          case this.agreeTermsAndConditionsApiCallId:{
              this.fetchNotificationSetting();
              break;
          }
          case this.deleteAccountApiCallId:{
              this.setState({isAccountDeleted: true});
              break;
          }

          case this.editEmailApiCallId:{
            this.setState({isEmailUpdated: true, isEditEmailModalOpen: false });
            break;
          }
          default: {
            break;
          }
        }
        }
      }
    }
  }
  // Customizable Area Start
  handleEditEmailPasswordShow = () => {
    this.setState({showEmailPassword: !this.state.showEmailPassword})
  };

  handlePasswordCloseModal = () => {
    this.setState({changePasssword:false})
  }
  handlePasswordShowModal = () => {
    this.setState({changePasssword:true})
  }
  handleClickShowNewPassword = () => {
    this.setState({ showNewPassword: !this.state.showNewPassword })
  };
  handleClickShowNewConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword })
  };
  handleProjectWorkingResponse = (hourly_rate_to: string | null, hourly_rate_from: string | null) => {
    this.setState({
      projectWorking: {
        to: hourly_rate_to !== null ? hourly_rate_to: "",
        from: hourly_rate_from !== null ? hourly_rate_from: "",
      }
    })
  };

  handleTokenError = (errors : Array<Record<string, any>>) => {
    if(errors.length > 0){
      if(errors[0].token && errors[0].token === "Invalid token"){
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.loginRoute);
        runEngine.sendMessage(message.id, message);
      }else if(errors[0].password){
        toast.error(errors[0].password);
      }else if(errors[0].email){
        toast.error(errors[0].email);
      }
    }
  }

  fetchTermsAndConditions = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsAndConditionsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.termsAndConditionsApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  fetchProfile = () => {
    const profileId = sessionStorage.getItem("profileId");
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.profileApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getProfileApiEndPoint}${profileId}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchNotificationSetting = () => {
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchNotifcationSettingApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getNotificationApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleVisibilityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedVisibilitySetting = {
      ...this.state.visibilitySetting,
      [e.target.name]: e.target.value
    }

    let formdata = new FormData();
    formdata.append("hourly_rate_option" ,updatedVisibilitySetting.hourly_rate_option);
    formdata.append("online_option" ,updatedVisibilitySetting.online_option);
    formdata.append("visibility_option" ,updatedVisibilitySetting.visibility_option);
    this.setState({visibilitySetting: updatedVisibilitySetting});
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.visibilitySettingApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.visbilitySettingApiEndPoint}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNotificationSettingChange = (e:React.ChangeEvent<HTMLInputElement>, key: keyof NotificationSetting) => {
    let updatedNotificationSetting = {
      ...this.state.notificationSetting,
    }
    updatedNotificationSetting = {
      ...updatedNotificationSetting,
        [key]: {
          ...updatedNotificationSetting[key],
          value: e.target.checked
        }
    }
    this.setState({notificationSetting: updatedNotificationSetting });
    let formdata = new FormData();
    formdata.append(key , updatedNotificationSetting[key].value.toString());
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.notificationSettingApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.notificationSettingApiEndPoint}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putApiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  saveProjectWorking = (data: ProjectWorking) => {
    let formdata = new FormData();
    formdata.append("from" ,data.from);
    formdata.append("to" ,data.to);
    this.setState({projectWorking: data});
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectWorkingApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.projectWorkingApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditProjectWorking = () => {
    this.setState({isEditProjectWorking: !this.state.isEditProjectWorking });
  };
  handleCloseDialog = () => {
    this.setState({resetPasswordSucceed: false, isEditEmailModalOpen: false, isSendLinkModalOpen: !this.state.isSendLinkModalOpen, changePasssword: false});
  };

  handleResetPassword = (data: ResetPassword) => {
    let formdata = new FormData();
    formdata.append("old_password" ,data.old_password);
    formdata.append("new_password" ,data.new_password);
    formdata.append("confirm_new_password" ,data.confirm_new_password);
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.resetPasswordApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putApiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResetPasswordResponse = (response : Response) => {
    if ('error' in response) {
      toast.error(response.error);
    } else {
      this.setState({resetPasswordSucceed: true});
      this.handlePasswordCloseModal();
    }
  };

  handleSendResetPasswordLink = (response: {status: number, message:string, meta : {token: string}, errors?: FlexibleObject[]}) => {
    if(response.errors){
      toast.error(response.errors);
    }
  };

  handleForgotPasswordModal = (sendLink?: boolean) => {
    this.setState({verificationLinkDisabled: true, isSendLinkModalOpen: true, isEditEmailModalOpen: false});
    if(sendLink){
      this.handlePasswordSendLink();
    }
  };

  handlePasswordSendLink = () => {
    const email = this.state.profile?.attributes?.email || "";
;   const formData = {
      data:{
        email
      }
    }
    const headers = {
      token: window.localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendResetPasswordLinkApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "forgot_password/otps/forgot_password_mail");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(formData));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  backButtonHandler = () => {
    this.setState({selectedSetting: this.state.displaySubscription ? 0 :null });
    if(this.state.displaySubscription){
      this.setState({displaySubscription: false});
    }
  };

  saveAgreeButtonHandler = () => {
    let formdata = new FormData();
    formdata.append("is_agree" ,"true");
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.agreeTermsAndConditionsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.agreeTermsAndConditionsApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigateToLogin= () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.loginRoute);
    runEngine.sendMessage(message.id, message);
  };

  handleDeleteAccountModal = () => {
    this.setState(prevState => ({...prevState, isConfirmDeleteModalOpen: !prevState.isConfirmDeleteModalOpen}));
  };

  handleDeleteAccount = () => {
    this.handleDeleteAccountModal();
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.deleteAccountApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteApiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditEmailModal = () => {
    this.setState(prevState => ({...prevState, isEditEmailModalOpen: !prevState.isEditEmailModalOpen}));
  };

  handleEditEmail = (data: {new_email: string, confirm_new_email: string, password: string}) => {
    const headers = {
      token: window.localStorage.getItem("token")
    };
    let formdata = new FormData();
    formdata.append("new_email" ,data.new_email);
    formdata.append("confirm_new_email" ,data.confirm_new_email);
    formdata.append("password" ,data.password);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editEmailApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.editEmailApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchApiMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}

