import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider, Theme } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import {
    amarenatiLogo,arrowRight, astromartinLogo, bentlyLogo, bmwLogo, bugatiLogo, ferariLogo, groupPng, jaguarLogo,
    mercedesLogo, profileList, projectInfo, teslaLogo, tvrLogo,carInner
} from "./assets";
// Customizable Area End

import LandingPage2Controller, {
    Props,
} from "./LandingPage2Controller";

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
    typography:{
        fontFamily:"Silka"
    }
});
const StyledContainer = styled(Box)(({theme}: {theme: Theme}) => ({
    "& .findTalentContainer":{
        paddingLeft:"34px",
        paddingRight:"34px",
        [theme.breakpoints.down('sm')]:{
            paddingBottom: "32.5px"
        },
        "&:nth-child(2)":{
            [theme.breakpoints.down('sm')]:{
                "& .findSubTitle":{
                    marginTop:"16.5px !important"
                 }
            }
        },
    },
    "& .findTitle": {
        fontWeight: 500,
        fontSize: "30px",
        lineHeight: "36px",
        color:"#9B9B9D",
        fontFamily: "Silika-Medium",
        [theme.breakpoints.down('sm')]:{
            fontSize: "16px",
            lineHeight: "19.3px"
        }
    },
    "& .findSubTitle": {
        fontWeight: 500,
        fontSize: "64px",
        lineHeight: "70.4px",
        color:"#0D0C22",
        marginTop:"30px",
        fontFamily: "Silika-Medium",
        [theme.breakpoints.down('sm')]:{
            fontSize: "36px",
            lineHeight: "39.6px",
            marginTop:"39.5px",
        }
    },
    "& .subDesc":{
        marginTop:"30px",
        [theme.breakpoints.down('sm')]:{
            marginTop: "24px",
            display: "flex",
            flexFlow: "column",
            alignItems: "center"
        }
    },
    "& .findSubDesc":{
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "25px",
        color:"#0D0C22",
        fontFamily: "Silika-Light",
        [theme.breakpoints.down('sm')]:{
            margin: "0 21px"
        }
    },
    "& .findSubDesc2":{
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "25px",
        color:"#0D0C22",
        fontFamily: "Silika-Light",
    },
    "& .getStartedBtn":{
        width:"125px",
        height: "48px",
        borderRadius: "2px",
        background: "#0D0C22",
        color: "white",
        textTransform: "none",
        fontFamily: "Silika-Medium",
        marginTop: "30px",
        [theme.breakpoints.down('sm')]:{
            marginTop: "24px"
        }
    },
    "& .groupPng":{
        height:"921px",
        width:"100%",
        [theme.breakpoints.down('sm')]:{
            marginTop: "24px",
            height: "460.5px"
        }
    },
    "& .groupPng2":{
        height:"921px",
        width:"100%",
        [theme.breakpoints.down('sm')]:{
            marginTop: "0px",
            height: "460.5px"
        }
    },
    "& .findTelentLeft": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    "& .profileTitle": {
        fontSize: "48px"
    },
    "& .projectInfoImgDiv": {
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    "& .projectInfoImg": {
        height: "442px",
        width: "398px",
        [theme.breakpoints.down('sm')]:{
            height: "293px",
            width: "calc(100vw - 65px)"
        }
    },
    "& .profileListPng": {
        width: "249px",
        height: "650px"
    },
    "& .grayPaidContent": {
        backgroundColor: "#D9D9D9",
        height: "552px",
        width: "100%",
        marginRight: "90px",
        [theme.breakpoints.down('sm')]:{
            margin: "24px 0",
            height: "294px"
        }
    },
    '& .projectTitle': {
        fontSize: "48px",
        lineHeight: "52.8px",
        alignContent: "center",
        fontWeight: 500,
        fontFamily: "Silika-Medium",
        [theme.breakpoints.down('sm')]:{
            marginTop: "40px",
            marginBottom: "24px",
            fontSize: "36px",
            lineHeight: "39.6px"
        }   
    },
    '& .projectDiv': {
        display: "flex",
        marginBottom: "80px",
        paddingLeft: "69px",
        paddingRight: "69px",
        background: "white",
        [theme.breakpoints.down('sm')]:{
            paddingLeft: "25px",
            paddingRight: "24px",
            marginBottom: "31.62px"
        }
    },
    '& .projectDescription': {
        fontWeight: 300,
        fontSize: "16px",
        marginTop: "80px",
        lineHeight: "25px",
        color: "#0D0C22",
        fontFamily: "Silika-Light",
        [theme.breakpoints.down('sm')]:{
            marginTop: "24px",
            marginRight: "42px"
        }
    },
    '& .projectTitleDiv': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .brandsLogoContainer": {
        "& *":{
            fontFamily: "Silika-Light"
        },
        backgroundColor: "#F5F3F3",
        paddingTop: "70px",
        paddingLeft: "64px",
        paddingRight: "64px",
        paddingBottom: "70px",
        [theme.breakpoints.down('sm')]:{
            paddingTop: "40px",
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingBottom: "31.63px"
        },
        "& span":{
            maxWidth: "600px",
            textAlign: "center"
        }
    },
    "& .logoDesc": {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        width: "100%"
    },
    "& .logoImg": {
        marginLeft: "auto",
        marginRight: "auto",
        flexWrap: "wrap"
    },
    "& .startBtnProject": {
        backgroundColor: "#9B9B9D"
    },
    "& .logos": {
        width: "20%",
        height: "90px",
        [theme.breakpoints.down('sm')]:{
            marginTop: "32px",
            width: "50%",
        },
        "@media(max-width:290px)": {
            width: "100%",
        }
    },
    '& .arrowRight': {
        marginRight: "14px"
    },

}))
// Customizable Area End

export default class LandingPage2Web extends LandingPage2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderDesktopPic = (image: string, gridClass: string, imgClass: string) => {
        const {windowWidth} = this.state;
        const isMobile = windowWidth <= 599;
        return !isMobile && <Grid item xl={6} lg={6} md={6} sm={12} className={gridClass}>
                    <img src={image} className={imgClass} />
                </Grid>
    }

    renderMobilePic = (image: string, imgClass: string) => {
        const {windowWidth} = this.state;
        const isMobile = windowWidth <= 599;
        return isMobile && <img src={image} className={imgClass} />
    }
    // Customizable Area End
    render() {
         // Customizable Area Start
         const {windowWidth} = this.state;
         const isMobile = windowWidth <= 599;
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <StyledContainer>
                    <LandingHeader navigation={this.props.navigation} />
                    <Grid container className="findTalentContainer">
                        <Grid item xl={6} lg={6} md={6} sm={12} className="findTelentLeft">
                            <Typography className="findTitle"><img src={arrowRight} className="arrowRight" alt="arrow" />FIND TALENT</Typography>
                            <Typography className="findSubTitle">Perfect results with the world's best designers</Typography>
                            {this.renderMobilePic(groupPng, "groupPng")}
                            <div className="subDesc">
                                <Typography className="findSubDesc">
                                Our hand-picked community of automotive and industrial designers<br />
                                are enabled to work better through our simple, seamless management<br />
                                tools.
                                </Typography>
                                <Button className="getStartedBtn" onClick={() => this.handleNavigation("LandingPage3Web")} data-test-id="findTalentBtn">Get Started</Button>
                            </div>
                        </Grid>
                        {this.renderDesktopPic(groupPng, "", "groupPng")}
                    </Grid>
                    <Grid container className="findTalentContainer" style={{ backgroundColor: "#F5F3F3" }}>
                        {!isMobile && <Grid item xl={6} lg={6} md={6} sm={12} className="projectInfoImgDiv">
                            <img src={profileList} className="groupPng profileListPng" />
                        </Grid>}
                        <Grid item xl={6} lg={6} md={6} sm={12} className="findTelentLeft" style={{ paddingLeft: isMobile ? "": "20px" }}>
                            <Typography className="findSubTitle profileTitle">Save time finding highly <br />skilled people</Typography>
                            {this.renderMobilePic(profileList, "groupPng profileListPng")}
                            <Typography className="findSubDesc2">Our community features the world's best talented professionals from
                                a range of specialist skillsets including exterior and interior design, UX and CMF. Everyone is peer vetted to ensure you find the best people immediately.
                                <br /><br />The right people for your team are recommended based on skillset, seniority and data-rich metrics to increase the likelihood of team fit
                                and performance.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className="findTalentContainer" style={{ paddingTop: isMobile ? "0": "150px", paddingBottom: isMobile ? "31.95px": "150px" }}>
                        <Grid item xl={6} lg={6} md={6} sm={12} className="findTelentLeft">
                            <Typography className="findSubTitle profileTitle">Control project spend more accurately</Typography>
                            {this.renderMobilePic(projectInfo, "groupPng2 projectInfoImg")}
                            <div className="subDesc">
                            <Typography className="findSubDesc2">Gain clear visibility of your project budget and approve each payment individually, by <br />timesheet or deliverable. Our tools ensure total control <br />
                                of your cashflow.<br /><br />Structure your projects using the most highly skilled suppliers and freelancers,<br /> instead of having to maintain overhead costs for large internal teams.
                            </Typography>
                            <Button className="getStartedBtn" onClick={() => this.handleNavigation("LandingPage3Web")} data-test-id="controlProjectBtn">Get Started</Button>
                            </div>
                        </Grid>
                        {this.renderDesktopPic(projectInfo, "projectInfoImgDiv", "groupPng projectInfoImg")}
                    </Grid>
                    <Grid container className="findTalentContainer" style={{
                        backgroundColor: "#F5F3F3",
                        paddingTop: isMobile ? "0.5px": "90px", paddingBottom: isMobile ? "31.91px": "90px"
                    }}>
                        {!isMobile && <Grid item xl={6} lg={6} md={6} sm={12} className="projectInfoImgDiv">
                            <Box className="grayPaidContent">  </Box>
                        </Grid>}
                        <Grid item xl={6} lg={6} md={6} sm={12} className="findTelentLeft">
                            <Typography className="findSubTitle profileTitle">Eliminate meetings and <br />save time</Typography>
                            {isMobile && <Box className="grayPaidContent">  </Box>}
                            <Typography className="findSubDesc2">Gain clear visibility of your project budget and approve each payment individually, by <br />
                                timesheet or deliverable. Our tools ensure total control <br />
                                of your cashflow. <br /> <br />Structure your projects using the most highly skilled suppliers and freelancers,  <br />
                                instead of having to maintain overhead costs for large internal teams.
                            </Typography>
                            <Button className="getStartedBtn" onClick={() => this.handleNavigation("")} data-test-id="saveTimeBtn">Get Started</Button>
                        </Grid>
                    </Grid>
                    <Grid container className="projectDiv">
                        <Grid container>
                            <Grid item xl={6} lg={6} md={6} sm={12} className="projectTitleDiv">
                                <Typography className="projectTitle">Ensure projects<br />finish on time </Typography>
                            </Grid>
                            {isMobile && <img src={carInner} height={isMobile? "189px": "756px"} width="100%" alt="carInner" />}
                            <Grid item xl={6} lg={6} md={6} sm={12}>
                                <Typography className="projectDescription">By working for clients through the Forge platform you gain access to
                                    the most innovative, exciting and purposeful projects in the world.
                                    <br />
                                    <br />
                                    From autonomous electric projects for new cities, to new startup companies and
                                    <br />
                                    established OEMs - we bring all the most interesting
                                    <br />
                                    and creative challenges under one roof.
                                </Typography>
                            </Grid>
                        </Grid>
                        {!isMobile && <Grid container style={{ marginTop: "50px", marginBottom: "50px" }}>
                            <img src={carInner} height="756px" width="100%" alt="carInner" />
                        </Grid>}
                    </Grid>
                    <Box className="brandsLogoContainer">
                        <Typography className="logoDesc" style={{ flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <span>Understandably, a lot of our work and clients have to remain confidential.
                           Our designers have already been trusted by the world's leading brands,
                            and if necessary can operate within your existing processes and infrastructure.</span>
                        </Typography>
                        <Box className="logoDesc logoImg" style={{ marginTop: isMobile ? "0px" : "71px" }}>
                            <Grid item className="projectTitleDiv logos"><img src={bentlyLogo} width="111px" alt="bently" /></Grid>
                            <Grid item className="projectTitleDiv logos"><img src={jaguarLogo} width="107px" alt="bently" /></Grid>
                            <Grid item className="projectTitleDiv logos"><img src={bmwLogo} width="83px" alt="bently" /></Grid>
                            <Grid item className="projectTitleDiv logos"><img src={bugatiLogo} width="101px" alt="bently" /></Grid>
                            <Grid item className="projectTitleDiv logos"><img src={astromartinLogo} width="127px" alt="bently" /></Grid>
                            <Grid item className="projectTitleDiv logos"><img src={amarenatiLogo} width="131px" alt="bently" /></Grid>
                            <Grid item className="projectTitleDiv logos"><img src={ferariLogo} width="111px" alt="bently" /></Grid>
                            <Grid item className="projectTitleDiv logos"><img src={mercedesLogo} width="108px" alt="bently" /></Grid>
                            <Grid item className="projectTitleDiv logos"><img src={teslaLogo}width="110px" alt="bently" /></Grid>
                            <Grid item className="projectTitleDiv logos"><img src={tvrLogo} width="110px" alt="bently" /></Grid>
                        </Box>
                    </Box>
                    <Grid container className="findTalentContainer" style={{paddingBottom: "72px"}}>
                        <Grid item xl={6} lg={6} md={6} sm={12} className="findTelentLeft">
                            <Typography className="projectTitle" >Start a Project</Typography>
                            {this.renderMobilePic(groupPng, "groupPng2")}
                            <Typography className="projectDescription">As a decentralised design consultancy we can provide designers,<br />
                                modellers and project managers, for services in-house or complex<br />
                                projects on a turnkey basis.
                            </Typography>
                            <Button className="getStartedBtn startBtnProject" onClick={() => this.handleNavigation("LandingPage3Web")} data-test-id="startProjectBtn" >Get Started</Button>
                        </Grid>
                        {this.renderDesktopPic(groupPng, "", "groupPng")}
                    </Grid>
                </StyledContainer>
                <LandingFooter navigation={this.props.navigation} />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
// Customizable Area End