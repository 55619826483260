import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
interface ImageAttributes {
  id: number;
  name: string;
  image: string;
}
interface MediaImage {
  id: string;
  type: string;
  attributes: ImageAttributes;
}
interface IListGallery {
  data: MediaImage[];
  errors?: object;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  images: MediaImage[];
  isRemainingImagesLoading: boolean;
  paginatedGallery: MediaImage[];
  isLoadingMore: boolean;
  activePage: number;
  imagesPerPage: number;
  hasCalledBelow400: boolean;
  hasCalledAbove400: boolean;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class LandingPage6Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMediaGalleryApiID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      images: [],
      isRemainingImagesLoading: false,
      paginatedGallery: [],
      isLoadingMore: false,
      activePage: 0,
      imagesPerPage: window.innerWidth < 400 ? 5 : 12,
      hasCalledBelow400: window.innerWidth < 400,
      hasCalledAbove400: window.innerWidth >= 400,
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getMediaGalleryApiID) {
          this.handleListGalleryResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Star
  async componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getMediaGallery();
  }
  componentWillUnmount(): Promise<void> {
    window.removeEventListener("resize", this.handleResize);
    return Promise.resolve();
  }
  handleResize = () => {
    const newWidth = window.innerWidth;
    this.setState((prevState) => {
      let updatedState = this.state;
      if (newWidth < 400 && !prevState.hasCalledBelow400) {
        updatedState = {
          ...updatedState,
          hasCalledBelow400: true,
          hasCalledAbove400: false,
        };
        this.getMediaGallery();
      } else if (newWidth >= 400 && !prevState.hasCalledAbove400) {
        updatedState = {
          ...updatedState,
          hasCalledBelow400: false,
          hasCalledAbove400: true,
        };
        this.getMediaGallery();
      }
      const imagesPerPage = newWidth < 400 ? 5 : 12;
      if (imagesPerPage !== prevState.imagesPerPage) {
        updatedState = {
          ...updatedState,
          imagesPerPage,
        };
      }

      if (Object.keys(updatedState).length > 0) {
        return updatedState;
      }
      return null;
    });
  };
  handleLoadMore = () => {
    this.setState({ isLoadingMore: true });
    const { images, activePage, imagesPerPage } = this.state;
    const nextPage = activePage + 1;
    const newPaginatedGallery = images.slice(0, nextPage * imagesPerPage);

    this.setState({
      paginatedGallery: newPaginatedGallery,
      activePage: nextPage,
      isLoadingMore: false,
      isRemainingImagesLoading: newPaginatedGallery.length < images.length,
    });
  };
  getMediaGallery = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMediaGalleryApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getMediaGalleryApiID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleListGalleryResponse = async (responseJson: IListGallery) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        images: responseJson.data,
        paginatedGallery: responseJson.data.slice(0, this.state.imagesPerPage),
        activePage: 1,
        isLoadingMore: false,
        isRemainingImagesLoading:
          responseJson.data.length > this.state.imagesPerPage,
      });
    }
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
