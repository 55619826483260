import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ValueType } from 'react-select';

// Customizable Area Start



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface OptionType {
  label: string;
  value: string;
}

interface S {
    // Customizable Area Start
    token : string,
     tags:string[];
     advancedsearchList: any,
     seniorityDropdownOpen:boolean;
     workTypeDropDown:boolean;
     countryDropdownOpen:boolean;
     professionDropDown:boolean; 
     skillsDropDown:boolean;
     seniority:OptionType[];
     selectedCountry:string;
     searchSkillValue:string;
     profession:string[];
     searchProfessionValue:string;
     skills:string[];
     workType:string[];
     minRate:string;
     maxRate:string;
     advanceSearchText: string,
     SearchRes : any;
     isShowMobileFilter: boolean,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AccountRegistrationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiEmailSignInCallId: string = "";
    sendEmailApiCallId: string = "";
    advancedsearchApiCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {  
          token: "",   
          advancedsearchList: [],
            tags:[],
            seniorityDropdownOpen:false,
            workTypeDropDown:false,
            countryDropdownOpen:false, 
            professionDropDown:false, 
            skillsDropDown:false,
            seniority:[],
            selectedCountry:'',
            profession:[],
            searchSkillValue:'',
            searchProfessionValue:'',
            skills:[],
            workType:[],
            minRate:'',
            maxRate:'',
            advanceSearchText: '',
            SearchRes : [],
            isShowMobileFilter: false
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start



    async receive(from: string, message: Message) {
      // Customizable Area Start
      if (getName(MessageEnum.SessionResponseMessage) === message.id) {
        let token = message.getData(getName(MessageEnum.SessionResponseToken));
        runEngine.debugLog("TOKEN", token);
        this.setState({ token: token });
        this.getAdvancedSearchList(token);
      } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        runEngine.debugLog("API Message Recived", message);
      }
      // Customizable Area End
    }

    getAdvancedSearchList = (searchText: string) => {
      const header = {
        "Content-Type": configJSON.advancedsearchApiContentType,
        token: localStorage.getItem("token")
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.advancedsearchApiCallId = requestMessage.messageId;
     
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAdvancedSearchApiEndPoint + "?first_name="+searchText 
  
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
     runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleAdvanceSearchText =(searchText: string) => {
      this.setState({ advanceSearchText: searchText });
      this.getAdvancedSearchList(searchText)
    };
  
   handleCountryDropDown(){
    this.setState({ countryDropdownOpen: !this.state.countryDropdownOpen });
   }
   handleWorkTypeDropDown(){
    this.setState({ workTypeDropDown: !this.state.workTypeDropDown });
   }
   handleSeniorityDropDown(){
    this.setState({seniorityDropdownOpen:!this.state.seniorityDropdownOpen});
   }
   handleProfessionDropDown(){
    this.setState({professionDropDown:!this.state.professionDropDown}); 
   }
   handleSkillsDropDown(){
    this.setState({skillsDropDown:!this.state.skillsDropDown}); 
   }
   handleChangeSeniority = (selected: ValueType<OptionType, true>) => {
    const selectedOptions = Array.isArray(selected) ? selected : [];
    const existingTags = [...this.state.tags]; 
    if (Array.isArray(selectedOptions)) {
      selectedOptions.forEach((option: OptionType) => {
        if (!existingTags.includes(option.label)) {
          existingTags.push(option.label); 
        }
      });
    } 
  
    this.setState({ seniority: selectedOptions, tags: existingTags });
  };

  handleChangeWorkTypes = (selected:ValueType<OptionType, true>) => {
    const selectedWorkOptions = Array.isArray(selected) ? selected : [];
    const existingTags = [...this.state.tags]; 
    if (Array.isArray(selectedWorkOptions)) {
      selectedWorkOptions.forEach((option: OptionType) => {
        if (!existingTags.includes(option.label)) {
          existingTags.push(option.label);  }
      }); } 
    this.setState({ workType:selectedWorkOptions, tags:existingTags });
  };

  addSkill = (selected:ValueType<OptionType, true>) => {
    const selectedOptions = Array.isArray(selected) ? selected : [];
    const existingTags = [...this.state.tags]; 
    if (Array.isArray(selectedOptions)) {
      selectedOptions.forEach((option: OptionType) => {
        if (!existingTags.includes(option.label)) {
          existingTags.push(option.label); 
        }
      });
    } 
    this.setState({ skills:selectedOptions, tags:existingTags });
  };
  handleCountryChange = (selectedOption: ValueType<OptionType, false>) => {
    if (selectedOption) {
      this.setState(prevState => {
        const updatedTags = prevState.tags.filter(tag => tag !== prevState.selectedCountry);
        updatedTags.unshift(selectedOption.label);
        return { selectedCountry: selectedOption.label, tags: updatedTags };
      });
    }
  };
  handleMinRateChange = (event: { target: { value: string; }; }) => {
    const minRate = event.target.value;
    this.setState({ minRate }, () => {
        this.updateRangeTag();
    });
};

handleMaxRateChange = (event: { target: { value: string; }; }) => {
    const maxRate = event.target.value;
    this.setState({ maxRate }, () => {
        this.updateRangeTag();
    });
};
clearAllFilters(){
  this.setState({tags:[], workType:[],
    profession:[], skills:[],
    selectedCountry:'',seniority:[],
    minRate:'',maxRate:''})
}
removeTag = (tagToRemove:string) => {
  this.setState(prevState => ({
      tags: prevState.tags.filter(tag => tag !== tagToRemove)
  }));
};
updateRangeTag = () => {
    const { minRate, maxRate } = this.state;
    if (minRate !== '' && maxRate !== '') {
        const rangeTag = `£ ${minRate}-${maxRate}`;
        this.setState(prevState => ({
            tags: [...prevState.tags.filter(tag => !tag.startsWith('£')), rangeTag]
        }));
    }
};
  addProfession = (selected:ValueType<OptionType, true>) => {
    const selectedOptions = Array.isArray(selected) ? selected : [];
    const existingTags = [...this.state.tags]; 
    if (Array.isArray(selectedOptions)) {
      selectedOptions.forEach((option: OptionType) => {
        if (!existingTags.includes(option.label)) {
          existingTags.push(option.label);  }
      }); } 
    this.setState({ profession:selectedOptions, tags:existingTags });
  };
  addTag = (tag: string) => {
    const { tags } = this.state;
    const updatedTags = [...tags, tag];
    this.setState({ tags: updatedTags });
  };
  showMobileFilter = () => {
    this.setState(() => ({
      isShowMobileFilter: true
    }));
  }
  hideMobileFilter = () => {
    this.setState(() => ({
      isShowMobileFilter: false
    }));
  }
    // Customizable Area End
}
