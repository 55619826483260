import { Box, Button, Grid, Theme, Typography, createStyles, makeStyles, Dialog, Avatar, styled } from '@material-ui/core'
import React from 'react'
import { ProjectData, S } from '../PostCreationWebController';
import { cancle } from "../assets";
import {returnTruthyString } from "../../../../components/src/HelperUtils";

const ProfileAvatar = styled(Avatar)({
  backgroundColor: "#DFDFDF",
  color: "#9B9B9D",
  fontFamily: "Silika-Medium !important",
  "& .MuiAvatar-img": {
    objectFit: "fill",
  }
});


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalBackdrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        GridMain: {
            border: "1px solid #D9D9D9",
            backgroundColor : "white",
            paddingLeft: "30px",
            paddingRight: "30px",
            [theme.breakpoints.down('xs')]: {
              paddingLeft: "2px",
              paddingRight: "2px",
              border: "none"
            },
        },
        imageStyle: {
            width: "100%",
            height: "60vh",
            padding: "20px",
            objectFit: "cover",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
        },
        buttonOutline: {
            borderRadius: "2px",
            borderColor: "black",
            marginTop: "20px",
            float: "right",
            textDecoration: "none",
            textTransform: "none"
        },
        buttonFilled: {
            marginLeft: "20px",
            borderRadius: "2px",
            backgroundColor: "black",
            color: "white",
            marginTop: "20px",
            float: "right",
            textDecoration: "none", textTransform: "none"
        },
        buttonClass: {
            width: "100%",
            margin: '20px'
        },
        projectTitle: {
            fontFamily: "Silika-Medium",
            fontSize: "20px",
            fontWeight: 500,
            padding: "20px"
        },
        projectDescription: {
            fontFamily: "Silika",
            fontSize: "16px",
            fontWeight: 300,
            padding: "20px",
            color: "#000000",
            [theme.breakpoints.down('xs')]: {
              width: "218px",
              fontSize: "14px",
              wordWrap: "break-word",
            },
        },
        descriptionText: {
            display: "flex !important",
            flexDirection: "column",
        },
        profileDiv: {
            position: "relative" as "relative",
            display: "flex",
            gap: "18px",
            marginTop: "80px",
            marginLeft: "22px",
            width: "100%",
            [theme.breakpoints.down('xs')]: {
              marginTop: "25px",
              gap: "10px",
            },
          },
          profileImgNew: {
            width: "60px",
            Height: "60px",
            borderRadius: "50px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          },
          userNameDiv: {
            display: "flex",
            justifyContent: "space-between",
          },
          describtionInterior: {
            fontSize: "14px",
            fontFamily: "Silika-Medium",
            fontWeight: 500,
            lineHeight: "140.625%",
            fontStyle: "normal",
            color: "#121212",
            [theme.breakpoints.down('xs')]: {
              fontSize: "12px",
              width: "120px",
              wordWrap: "break-word",
            },
          },
          userNameInterior: {
            fontSize: "14px",
            fontFamily: "Silika-Medium",
            fontWeight: 500,
            lineHeight: "normal",
            fontStyle: "normal",
            color: "#64748B",
            [theme.breakpoints.down('xs')]: {
              fontSize: "12px",
              width: "120px",
              wordWrap: "break-word",
            },
          }, 
          rightAlignOption: {
            position: "absolute" as "absolute",
            right: "16px"
          },
          headerImage: {
            height: "32px",
            width: "32px",
            flexShrink: 0,
            [theme.breakpoints.down('xs')]: {
              height: "25px",
              width: "25px",
            },
          },
          profileImgStyle : {
            width: "68px",
            height: "68px",
            [theme.breakpoints.down('xs')]: {
              width: "40px",
              height: "40px",
            },
          }
    })
);
type Props = {
    state: S
    updatePost: (value: ProjectData, isEdited:boolean) => void
    handlePreviewClose : ()=>void
    isEdited:boolean
    profileImage:any
}

function Preview(this: any, props: Props) {
    const { state, updatePost, handlePreviewClose, isEdited, profileImage } = props
    const classes = useStyles()
    const post = state.postData.attributes;
    const avatarName = returnTruthyString(post?.profile?.data.attributes?.first_name[0]) + returnTruthyString(post.profile.data.attributes.last_name[0]);
    let firstImage = state.uploadMultipleImagesAPI.length > 0 && state.uploadMultipleImagesAPI[0].type || ""
    return (
        <Dialog
      open={state.displayPreview}
      onClose={handlePreviewClose}
      PaperProps={{
        style: { backgroundColor: 'transparent', boxShadow: 'none', maxWidth: "1200px" },
      }}
      BackdropProps={{
        classes: {
          root: classes.modalBackdrop,
        },
      }}
    >
        <Grid container sm={12} item className={classes.GridMain}>
        <div className={classes.profileDiv}>
            <div>
            <ProfileAvatar src={profileImage} className={classes.profileImgStyle}>
                {avatarName}
              </ProfileAvatar>
            </div>
            <div className={classes.userNameDiv}>
              <div style={{paddingTop: "8px"}}>
                <Typography className={classes.describtionInterior}>{state.savedValues.description}</Typography>
                <Typography className={classes.userNameInterior}>
                  {sessionStorage.getItem("firstName")+" "+sessionStorage.getItem("lastName")}
                </Typography>
              </div>
            </div>
            <Box className={classes.rightAlignOption}>
            <img
              src={cancle}
              alt="option"
              className={classes.headerImage}
              onClick={handlePreviewClose}
            />
          </Box>
          </div>
            {state.uploadMultipleImagesAPI.length > 0 ? firstImage.includes("image") && <img className={classes.imageStyle} src={URL.createObjectURL(state.uploadMultipleImagesAPI[0])}></img> : <></>}
            {state.uploadMultipleImagesAPI.length > 0 ? firstImage.includes("video") && <video className={classes.imageStyle} controls >
                <source src={URL.createObjectURL(state.uploadMultipleImagesAPI[0])} type="video/mp4" />
            </video> : <></>}

            <Box className={classes.descriptionText}>
                <Typography className={classes.projectDescription}>{state.savedValues.description}</Typography>
            </Box>
            {state.uploadMultipleImagesAPI.map((srcImage, index) => {
                return <>
                    {index !== 0 && state.uploadMultipleImagesAPI[index].type.includes("image") && (
                        <>
                            <img className={classes.imageStyle} src={URL.createObjectURL(srcImage)}></img>
                        </>
                    )}
                    {index !== 0 && state.uploadMultipleImagesAPI[index].type.includes("video") && (
                        <>
                            <video className={classes.imageStyle} controls >
                                <source src={URL.createObjectURL(srcImage)} type="video/mp4" />
                            </video>
                        </>
                    )}
                </>
            })}
            <Box className={classes.buttonClass}>

                <Button variant="contained" id='publishPost' className={classes.buttonFilled}  type="submit" onClick={() => updatePost(state.savedValues, isEdited)}>Publish</Button>
                <Button variant="outlined" className={classes.buttonOutline} onClick={handlePreviewClose} >Cancel</Button>
            </Box>
        </Grid>
        </Dialog>
    )
}

export default Preview
