import React from "react";

// Customizable Area Start
import {
  Typography,
  Box
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle, StyledButton } from "../../email-account-login/src/LoginSignUp.web";
import AccountRegistrationController, { Props } from "./AccountRegistrationController";


// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class SignUpThanks extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}><div
        style={{ overflow: 'hidden' }}>
        <LoginSignUp navigation={this.props.navigation} id={""}>

          <Box width={{xs : "300px", sm : "327px",  md: "360px"}} style={webStyle.leftContainer }>
          <Box height={{xs : "auto", md:"370px"}} style = {{display : "flex", flexDirection : "column"}}>
            <div style={{ ...webStyle.formTitleContainer, justifyContent: 'left' }}>
              <Typography style={{ ...webStyle.formTitleText, textAlign: "left" as "left" }}>
                Thanks For Your Interest!
              </Typography>
            </div>
            <div style={webStyle.isAccountDiv}>
              <Typography style={{ fontFamily: "Silika-Medium", fontStyle: "normal", fontWeight: 400, lineHeight: "22px", marginBottom: 20, fontSize: "16px", color: 'black' }}>
                We are working on an invitation based system.Your signup request will be processed and we will come back to you as soon as possible.
              </Typography>
            </div>
            <div style={webStyle.isAccountDiv}>
              <Typography style={{ ...webStyle.isAccountStatement, marginBottom: 30, fontSize: "16px" }}>
                Please proceed to signup if you are okay. If you have an invitation link, please signup using it.
              </Typography>
            </div>
            <StyledButton
                onClick={() => { this.handleProceedSignUpClick() }}
                variant="outlined"
                style={{ ...webStyle.logInButton, textTransform: "capitalize", marginTop : 'auto' }}
                type="submit"
                data-test-id="buttonClick"
              >
                Proceed to Sign Up
              </StyledButton>
              </Box>
          </Box>
        </LoginSignUp>
      </div> </ThemeProvider>
      // Customizable Area End
    );
  }
}


