import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { StyledBackButtonFloat, StyledButtonFloat, webStyle } from "../../email-account-login/src/LoginSignUp.web";
import ClientController, {Props} from "./ClientController";
import { ErrorMessage, Formik } from "formik";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Silika-Medium"
    }
});
const StyledTextField = styled(TextField)({
    "& .formHelperClass":{
        color: "#DC2626",
        marginLeft: "0px",
        fontFamily: "Silika-Medium"
    },
    "& input, input::placeholder":{
        fontFamily: "Silika-Medium !important"
    }
});
// Customizable Area End

export default class PaymentSetup extends ClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={{
          overflow: 'hidden'
        }}>
          <LoginSignUp navigation={this.props.navigation} id={""}>
            <Box width={{xs : "300px", sm : "327px",  md: "727px"}} style={{...webStyle.leftContainer}}>
                <Typography style={{...webStyle.membershipPlanTitle, fontSize : "28px", marginBottom : "22px"}}>Payment | £ 80</Typography>
                <Typography style={webStyle.membershipPlanTitle}>Set up payment method</Typography>
                <Typography style={{...webStyle.selectedPlanFeature, marginTop : "35px", marginBottom : "56px"}}>Set up your payment method, Please fill your details below
                </Typography>
                <Formik
                                initialValues={{
                                    nameOnCard : "",
                                    cardNumber : "",
                                    expirationDate : "",
                                    securityCode : ""
                                }}
                                data-test-id="formikForm"
                                onSubmit={(values) => 
                                    console.log("values", values)
                                }
                                enableReinitialize
                                // validationSchema={validationSchema}
                            >
                                {({
                                    touched, handleSubmit,values, setFieldValue, handleBlur, handleChange, errors, setFieldError, setFieldTouched, validateField
                                }) => {
                                    return (
                                        <form style={{ paddingRight: 10 }} autoComplete="off" onSubmit={handleSubmit}>
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} lg={6} md={6}>
                                                    <label htmlFor="nameOnCard" style={webStyle.labelStyle}>Name on the Card</label>
                                                    <StyledTextField
                                                        fullWidth
                                                        name={"nameOnCard"}
                                                        id="nameOnCard"
                                                        value={values.nameOnCard}
                                                        inputProps={{ maxLength: 30, style: { height: 10 } , className:"customTextField",  shrink: true, }}
                                                        placeholder="Jhon Doe"
                                                        variant="outlined"
                                                        data-test-id='nameOnCard'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={<ErrorMessage name="nameOnCard" />}
                                                        FormHelperTextProps={{
                                                            className: "formHelperClass"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6} md={6}>
                                                    <label htmlFor="cardNumber" style={webStyle.labelStyle}>Card Number</label>
                                                    <StyledTextField
                                                        fullWidth
                                                        name={"cardNumber"}
                                                        data-test-id='cardNumber'
                                                        value={values.cardNumber.replace(/(\d{4})(?=\d)/g, '$1 ')}
                                                        onChange={(e) => {
                                                            const rawValue = e.target.value.replace(/\s+/g, ''); 
                                                            handleChange({ target: { name: 'cardNumber', value: rawValue } });
                                                        }}
                                                        placeholder="0000 0000 0000 0000"
                                                        variant="outlined"
                                                        onBlur={handleBlur}
                                                        helperText={<ErrorMessage name="cardNumber" />}
                                                        inputProps={{ maxLength: 19, style: { height: 10 }, className:"customTextField",  inputMode: 'numeric',
                                                        pattern: '[0-9]*', onKeyDown : (e)=>this.handleKeyDown(e) }}
                                                        FormHelperTextProps={{
                                                            className: "formHelperClass"
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} >
                                                <Grid item xs={12} lg={6} md={6}>
                                                    <label htmlFor="expirationDate" style={webStyle.labelStyle}>Expiration Date</label>
                                                    <StyledTextField
                                                        fullWidth
                                                        name={"expirationDate"}
                                                        id="expirationDate"
                                                        value={values.expirationDate}
                                                        inputProps={{ maxLength: 30, style: { height: 10 } , className:"customTextField",  shrink: true, }}
                                                        placeholder="00/00"
                                                        variant="outlined"
                                                        data-test-id='expirationDate'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        helperText={<ErrorMessage name="expirationDate" />}
                                                        FormHelperTextProps={{
                                                            className: "formHelperClass"
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} lg={6} md={6}>
                                                    <label htmlFor="securityCode" style={webStyle.labelStyle}>Security Code</label>
                                                    <StyledTextField
                                                    fullWidth
                                                    style={
                                                    webStyle.inputfieldHeight
                                                    }
                                                    variant="outlined"
                                                    type={this.state.showSecurityCode ? "text" : "password"}
                                                    value={values.securityCode}
                                                    placeholder="***"
                                                    data-test-id={"securityCode"}
                                                    name="securityCode"
                                                    id="securityCode"
                                                    onChange={handleChange}
                                                    FormHelperTextProps={{
                                                    style: {
                                                        marginLeft:0,
                                                    }
                                                    }}
                                                    inputProps={{
                                                        maxLength: 3, style: { height: 10 } , className:"customTextField",  shrink: true,
                                                    }}
                                                    InputProps={{
                                                    endAdornment: !this.state.showSecurityCode ? (
                                                        <VisibilityOffIcon
                                                        style={webStyle.passwordVisibilityOffIcon}
                                                        data-test-id="visibilityOffIcon"
                                                        onClick={this.handleToggleSecurityNumber}
                                                        />
                                                    ) : (
                                                        <VisibilityIcon
                                                        style={webStyle.passwordVisibilityOnIcon}
                                                        data-test-id="visibilityOnIcon"
                                                        onClick={this.handleToggleSecurityNumber}
                                                        />
                                                    )
                                                    }}
                                                />  
                                                </Grid>
                                            </Grid>
                                            <div
                                            style={{
                                            display: "flex",
                                            gap: "16px",
                                            justifyContent: "flex-end",
                                            height: "auto",
                                            marginBottom: "10px",
                                            }}
                                        >
                                            <StyledBackButtonFloat
                                            variant="outlined"
                                            style={{
                                                ...webStyle.logInButton,
                                                backgroundColor: "none",
                                                border: "1px solid #0D0C22",
                                                color: "#0D0C22",
                                            }}
                                            type="submit"
                                            data-test-id="backButton"
                                            >
                                            Back
                                            </StyledBackButtonFloat>
                                            <StyledButtonFloat
                                            variant="outlined"
                                            style={{ ...webStyle.logInButton }}
                                            type="submit"
                                            data-test-id="createProfile"
                                            >
                                            Next
                                            </StyledButtonFloat>
                                        </div>
                                        </form>
                                    )
                                }}
                                
                            </Formik>
                
            </Box>
          </LoginSignUp></div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}


