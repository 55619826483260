import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'; 
const saveImage = require("./image__icon share.svg");
const shareImage = require("./image__icon save.svg");
const locationIcon = require("./location.svg");

interface Props {
    title: string;
    category: string;
    updatedTime: string;
    level: string;
    location: string;
    type: string;
    profession: string;
    dates: string;
    rate: string;
    budget: string;
    description: string;
    imageUrl: string;
    stage?: string;  
    btnText?: string;
}

interface S { }

export default class JobCard extends Component<Props, S> {
    render() {
        const {
            title,
            category,
            updatedTime,
            level,
            location,
            type,
            profession,
            dates,
            rate,
            budget,
            description,
            imageUrl,
            stage = 'open', 
            btnText = 'Apply',
        } = this.props;

    return (
        <div style={webStyles.opportunityBlock}>
            <div style={webStyles.opportunityDetails}>
                <img src={imageUrl} style={webStyles.administrativeImage} alt="Job" />
                <div style={webStyles.administrativeDetailBlock}>
                    <div style={webStyles.headerDiv}>
                        <div style={webStyles.headerLeft}>
                            <Typography style={webStyles.opportunityTitle}>
                                {title} 
                            </Typography>
                            <div style={webStyles.subTexts}>
                                <Typography style={webStyles.catagory}>{category}</Typography>
                                <Typography style={webStyles.updatedTime}>{updatedTime}</Typography>
                            </div>
                        </div>
                        <div style={webStyles.headerRight}>
                            <img src={shareImage} style={webStyles.iconStyle} alt="Share" />
                            <img src={saveImage} style={webStyles.iconStyle} alt="Save" />
                            <Button style={webStyles.applyButton}>{btnText}</Button>
                        </div>
                    </div>
                    <div style={webStyles.detailsDiv}>
                        <div style={webStyles.column1}>
                            <Typography style={webStyles.attributeTitle}>Level:</Typography>
                            <Typography style={webStyles.attributeDesc}>{level}</Typography>
                        </div>
                        <div style={webStyles.column2}>
                            <Typography style={webStyles.attributeTitle}>Location:</Typography>
                            <img src={locationIcon} style={webStyles.iconStyle} alt="Location" />
                            <Typography style={webStyles.attributeDesc}>{location}</Typography>
                        </div>
                    </div>
                    <div style={webStyles.detailsDiv}>
                        <div style={webStyles.column1}>
                            <Typography style={webStyles.attributeTitle}>Type:</Typography>
                            <Typography style={webStyles.attributeDesc}>{type}</Typography>
                        </div>
                        <div style={webStyles.column2}>
                            <Typography style={webStyles.attributeTitle}>Profession:</Typography>
                            <Typography style={webStyles.attributeDesc}>{profession}</Typography>
                        </div>
                    </div>
                    <div style={webStyles.detailsDiv}>
                        <div style={webStyles.column1}>
                            <Typography style={webStyles.attributeTitle}>Dates:</Typography>
                            <Typography style={webStyles.attributeDesc}>{dates}</Typography>
                        </div>
                        <div style={webStyles.column2}>
                            <div style={{ display: 'flex', gap: '15px', width: '50%' }}>
                                <Typography style={webStyles.attributeTitle}>Rate:</Typography>
                                <Typography style={webStyles.attributeDesc}>{rate}</Typography>
                            </div>
                            <div style={{ display: 'flex', gap: '15px', width: '50%' }}>
                                <Typography style={webStyles.attributeTitle}>Budget:</Typography>
                                <Typography style={webStyles.attributeDesc}>{budget}</Typography>
                            </div>
                        </div>
                    </div>
                    <Typography style={webStyles.opprtunityDescription}>
                        {description}
                    </Typography>
                </div>
            </div>
        </div>
    );
}
}

const webStyles = {
    opportunityBlock: {
        display: "flex",
        flex: 1,
        padding: "38px 42px",
        border: "1px solid #DFDFDF",
    },
    opportunityDetails: {
        display: "flex",
        flex: 1,
        gap: "32px"
    },
    administrativeImage: {
        height: "200px",
        width: "190px",
        objectFit: "cover" as "cover",
        border: "1px solid #DFDFDF"
    },
    administrativeDetailBlock: {
        display: "flex",
        flex: 1,
        flexDirection: "column" as "column",
    },
    headerDiv: {
        display: "flex",
        flex: 1,
        height: "80px",
        justifyContent: "space-between",
        marginBottom: "34px"
    },
    headerLeft: {

},
headerRight: {
    display: "flex",
    gap: "16px",
    alignItems: "center"
},
opportunityTitle: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "26px",
    LineHeight: "31.37px",
    letterSpacing: "-2.2%"
},
subTexts: {
    display: "flex",
    gap: "115px",
    alignItems: "center"
},
catagory: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "16px",
    LineHeight: "19.36px",
    letterSpacing: "-0.15%",
    color: "#9B9B9B"
},
updatedTime: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "12px",
    LineHeight: "19.36px",
    letterSpacing: "-0.15%",
    color: "#9B9B9B"
},
applyButton: {
    width: "110px",
    height: "48px",
    backgroundColor: "#0D0C22",
    color: "white",
    textTransform: "none" as "none"
},
detailsDiv: {
    height: "36px",
    display: "flex",
    borderBottom: "1px solid #DFDFDF",
    borderTop: "1px solid #DFDFDF",
    alignItems: "center",
},
column1: {
    width: "40%",
    display: "flex",
    gap: "15px"
},
column2: {
    width: "60%",
    display: "flex",
    gap: "15px",
    alignItems: "center"
},
opprtunityDescription: {
    fontFamily: "Silika-Light",
    fontWeight: 200,
    fontSize: "16px",
    lineHeight: "18.98px",
    marginTop: "34px"
},
iconStyle: {
    height: "24px",
    width: "24px"
},
attributeTitle: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16.88px",
    color: "#9B9B9D"
},
attributeDesc: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16.88px",
},
}