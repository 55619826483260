import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  windowWidth: number;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPage2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      windowWidth: window.innerWidth
      // Customizable Area End
    };
    // Customizable Area Start
    this.handleResize = this.handleResize.bind(this);
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
      const innerWidth = window.innerWidth;
      this.setState({ windowWidth:  innerWidth});
  }

  handleNavigation = (screenName: string) => {
    const messageIn = new Message(getName(MessageEnum.NavigationMessage));
    messageIn.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    messageIn.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(messageIn.id, messageIn);
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
