import React from "react";

// Customizable Area Start
import {
  Typography,
  Box
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp,   { webStyle, StyledButton } from "../../email-account-login/src/LoginSignUp.web";
import AccountRegistrationController, { Props } from "./AccountRegistrationController";
import "../../customisableuserprofiles/src/ProfileInfo.css"

// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class AccountAlreadyExists extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
           <div style={{
            overflow:'hidden'}}>
        <LoginSignUp navigation={this.props.navigation} id={""}>
          <Box width={{xs : "300px", sm : "327px",  md: "360px"}} style={webStyle.leftContainer}>
            <div style={{ ...webStyle.formTitleContainer, justifyContent: 'left',marginBottom:40}}>
              <Typography style={{ ...webStyle.formTitleText, textAlign: "left" as "left" }}>
                You're already a member
              </Typography>
            </div>
            <div style={webStyle.isAccountDiv}>
              <Typography style={{
                ...webStyle.isAccountStatement,fontFamily:"Silika-Medium",
                marginBottom: 70, fontSize: "15px", color: 'black'
              }}>
                We have this email in our system, would you like to login?
              </Typography>
            </div>
            <StyledButton
                variant="outlined"
                data-test-id='loginBtn'
                onClick={() => { this.props.navigation.navigate('AccountLogin') }}
                style={{ ...webStyle.logInButton, textTransform: "none" }}
              >
                Yes, proceed with log in
              </StyledButton>
              <StyledButton
                variant="outlined"
                data-test-id='cancelBtn'
                onClick={() => { this.props.navigation.navigate('AccountRegistration') }}
                style={{ height:'56px',backgroundColor:'white',fontSize: '16px',border:'1px solid black',color:"black",fontFamily:'Silika-Medium', textTransform: "capitalize",marginTop:20,borderRadius:0 }}
              >
                Cancel
              </StyledButton>
      
          </Box>
        </LoginSignUp></div>   
      </ThemeProvider>
        
      // Customizable Area End
    );
  }
}


