import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  profileData: any[];
  allLikes : any[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: any;
  getProfileDataApiCallId: string | null = "";
  giveFeedbackAPI : string | null = "";
  getAllLikesAPICallID  :string | null = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    // Customizable Area Start
    this.state = {
      arrayHolder: [],
      token: "",
      profileData: [],
      allLikes : []

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start
    super.componentDidMount();
    this.getToken();
    this.ProfileListData();
    this.getAllLikes();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const messageId = getName(MessageEnum.RestAPIResponceMessage);
    const messageData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token });
    }

    if (messageId === message.id) {
      if (this.getProductApiCallId && this.getProductApiCallId === messageData) {
        if (!responseJson.errors && responseJson.data) {
          this.setState({ arrayHolder: responseJson.data });
          runEngine.debugLog("arrayHolder", this.state.arrayHolder);
        } else {
          const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
          this.parseApiCatchErrorResponse(errorResponse);
        }
      }

      if (this.getProfileDataApiCallId && this.getProfileDataApiCallId === messageData) {
        if (!responseJson.errors && responseJson.data) {
          this.setState({ profileData: responseJson.data });
        }
      }

      if (this.getAllLikesAPICallID && this.getAllLikesAPICallID === messageData) {
        if (!responseJson.errors && responseJson.data) {
          this.setState({ allLikes: responseJson.data });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  ProfileListData = (str?: string) => {
    const headers = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  giveFeedback = (id : string) => {
    const headers = {
      token: localStorage.getItem("token"),
      "Content-Type": "application/json" 
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
        data:{
            attributes: {
                likeable_id: parseInt(id),
                likeable_type: "BxBlockProfile::Profile"
            }
        }
    };

    this.giveFeedbackAPI = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.giveFeedbackApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getAllLikes = () => {
    const headers = {
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllLikesAPICallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  

  handleThumbsUp = (id : string, isLike : boolean) => {
    if (this.giveFeedback(id)) {
      const updatedProfileData = this.state.profileData.map(profile => {
        if (profile.attributes.id === id) {
          return {
            
            ...profile,
            attributes: {
              ...profile.attributes,
              is_like: isLike
            }
          };
        }
        return profile;
      });
  
      this.setState({ profileData: updatedProfileData });
    }
  }
  

  // Customizable Area End
}
