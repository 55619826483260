import React from "react";
import { Box, Button, Typography, styled} from "@material-ui/core";
import {rightLogo} from "../assets"

type Props = {
    planTitle : string,
    planDescription : string,
    cost:number,
    features : string[],
    buttonText : string
}

const StyledContainer = styled(Box)({
    height: "409px", 
        flex: "1 1 calc(25% - 12px)", 
        border: "0.78px solid #DFDFDF", 
        borderRadius: "4px", 
        padding : "18px 9px 18px 9px", 
        boxSizing : "border-box" as "border-box",
        "&:hover":{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06),0px 8px 12px rgba(0, 0, 0, 0.03), 0px 12px 16px rgba(0, 0, 0, 0.09)",
            "& .actionBtn":{
                background: "#0D0C22 !important",
                color: "#fff",
                border: "1px solid #0D0C22",
                transition: "background-color 0.6s ease, color 0.7s ease",
            }
        }

})

const StyledButton = styled(Button)({
        backgroundColor:"transparent",
        height  :"43.68px",
        border : "0.39px solid #DFDFDF",
        textTransform  :"none" as "none",
        fontFamily  :"Silika-Medium",
        fontWeight : 500,
        fontSize : "12px",
        lineHeight  :"18.72px",
        width:"100%",
        color:"#010101",
        cursor : "pointer",
})

function MembershipCard(props:Props) {
    const {planTitle, planDescription, cost, features, buttonText} = props
  return (
    <StyledContainer >
        <div style={webStyle.card}>
        <div style={webStyle.titleCard}>
            <Typography style={webStyle.titleStyle}>
                {planTitle}
            </Typography>
            <Typography style={webStyle.descriptionStyle}>
                {planDescription}
            </Typography>
        </div>
        <div>
        <Typography style={webStyle.costDiv}>
            £ {cost}
        </Typography>
        <Typography style={{...webStyle.descriptionStyle, color : "#9B9B9B"}}>
            per month
        </Typography>
        </div>
        <div>
            {features.map((feature, index)=>(
                <div key={index} style={webStyle.feature}>
                    <img src={rightLogo} alt="right" />
                    <Typography style={webStyle.descriptionStyle}>
                        {feature}
                    </Typography>
                </div>
            ))}
        </div>
        <StyledButton className="actionBtn">
            {buttonText}
        </StyledButton>
        <Typography style={webStyle.cancelTxtStyle}>
            Cancel anytime. No strings attached.
        </Typography>
        </div>
        
    </StyledContainer>
  )
}

export default MembershipCard

const webStyle = {
    card  :{
        display  :"flex",
        flexDirection : "column" as "column",
        gap  :"20px"
    },
    titleCard : {
        height : "77px",
    },
    titleStyle : {
        fontFamily  :"Silika-Medium",
        fontWeight : 400,
        fontSize : "18.72px",
        lineHeight  :"24.96px",
        letterSpacing  :"-0.09px",
    },
    descriptionStyle : {
        fontFamily  :"Silika-Light",
        fontWeight : 400,
        fontSize : "12px",
        lineHeight  :"24.96px",
    },
    costDiv : {
        fontFamily  :"Silika-Medium",
        fontWeight : 500,
        fontSize : "37.44px",
        lineHeight  :"43.68px",
        letterSpacing : "-0.56px",
    },
    feature : {
        display : "flex",
        gap  :"12px",
        alignItems : "center",
    },
    buttonStyle : {
        height  :"43.68px",
        border : "0.39px solid #DFDFDF",
        textTransform  :"none" as "none",
        fontFamily  :"Silika-Medium",
        fontWeight : 500,
        fontSize : "12px",
        lineHeight  :"18.72px",
    },
    cancelTxtStyle : {
        fontFamily  :"Silika-Light",
        fontWeight : 400,
        fontSize : "10px",
        lineHeight  :"18.72px",
        color : "#9B9B9B",
        textAlign  :"center" as  "center",
        padding : "0 18.72px"
    }
}