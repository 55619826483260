import React from "react";
// Customizable Area Start
import {
  Button,
  Container,
  Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import HeaderNavbar from "../../../components/src/Navbar";
import {likeImage, shareImage, saveImage, ChatIcon, location} from "./assets"
import JobCard from "../../../components/src/JobCard.web";
import CoverImageComponent from "../../../components/src/CoverImageComponent.web"
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
import ClientProfileController, {
    Props
} from "./ClientProfileController";
const administrators = [
  {
    id: 1,
    name: "Clement Thomas",
    headline: "Senior interior designer",
    roles: [
      {
        id: 1,
        title: "Project Manager"
      },
      {
        id: 2,
        title: "Purchase Authority"
      },
      {
        id: 3,
        title: "Approval Authority"
      }
    ]
  },
  {
    id: 2,
    name: "Alexandro Rodrice",
    headline: "Senior interior designer",
    roles: [
      {
        id: 1,
        title: "Project Manager"
      },
      {
        id: 2,
        title: "Purchase Authority"
      }
    ]
  },
  {
    id: 3,
    name: "John F. Kennedy",
    headline: "Senior interior designer",
    roles: [
      {
        id: 3,
        title: "Approval Authority"
      }
    ]
  },
  {
    id: 4,
    name: "Jeniffer Thompson",
    headline: "Senior interior designer",
    roles: [
      {
        id: 1,
        title: "Project Manager"
      },
      {
        id: 2,
        title: "Purchase Authority"
      }
    ]
  },
  {
    id: 5,
    name: "Clement Thomas",
    headline: "Senior interior designer",
    roles: [
      {
        id: 1,
        title: "Project Manager"
      },
      {
        id: 2,
        title: "Purchase Authority"
      },
      {
        id: 3,
        title: "Approval Authority"
      }
    ]
  },
  {
    id: 6,
    name: "Alexandro Rodrice",
    headline: "Senior interior designer",
    roles: [
      {
        id: 1,
        title: "Project Manager"
      },
      {
        id: 2,
        title: "Purchase Authority"
      }
    ]
  }
];

// Customizable Area End

export default class ClientProfile extends ClientProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  headingBar = (title : string, count : number) =>{
    return(
      <div style={webStyle.headingBar}>
          <Typography style={webStyle.blockHeadding}>
            {title+` (${count})`}
          </Typography>
      </div>
    )
  }

  administrator = (name:string, headline : string, roles : any[]) =>{
    return(
      <div style={webStyle.adminstratorBlock}>
          <img src="https://s3-alpha-sig.figma.com/img/ad3b/7c38/1218aaed9d3df69e24a3c0df1ecbbac1?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ECicqsHrTzCKvmFB~4DAFHNMApd7sIgZmo~QIbrS7hWB8fyb1JiHOn-X8QjZb7punBsTTHaEtUo5V9EUHlo3vDyg~YZzKAghoPIoTUTOEDLHA9vIqwYKA-Fr7w3M7aHSOutsmRIAmLOLzXmrcxfqFwq2kFkwqlYUakheC4LGiQlKN-HVBU~qLqMK3hQYO6gWtau9wdUuUyRuOme-mc5UweSENVewiqBOYWIQ0XI~gJa5OYG0GrQk8RQrxU2mvW0ZzT~~vM2~r6-ZLfFkovnceI1szOR1hvXvgaNL-7cMW9tWqKDe5qRjk3v93iqBHIJgmBHfS2bc2qQ3MwAnuDz9DQ__" style={webStyle.administratorprofile}>
          </img>
          <div>
            <Typography style={webStyle.administratorName}>
              {name}
            </Typography>
            <Typography style={webStyle.administratorHeadline}>
              {headline} 
            </Typography>
            <div style={webStyle.borderBottom}></div>
            <div style={webStyle.professionBox}>
              {roles.map((role, index)=>(
                <div key={index} style={webStyle.professionCard}>
                <Typography style={webStyle.professionTitle}>
                  {role.title}
                </Typography>
              </div>
              ))}
            </div>
          </div>
      </div>
    )
    
  }

  profileCard = ()=>{
    return(
      <div style={webStyle.profileContainer}>
          <img style={webStyle.clientImgStyle} src="https://s3-alpha-sig.figma.com/img/0913/6e54/c89f22b93d5b2d16d51fd34a2b9ad98f?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TzcjTW2YeOSmXo8KIZlk1y6V~ANHbm12A1useiNvgtyuGmK6Pf6n48RCVMd7BhFNhsipnQ8ceaIz8IQzUqB3eDg98cxh23-BrkfHs829GgnJJ4TaENFIYJ-otyHRtTLy~yd1UG-lpXPfEYCMpeMFX0zb5sovmwChwMfTXS~FCH3dUu6p9TYOkHS9~GOmPWe~-MgbdTjoDGhILg~YMrFXweYUYZ6Aeq~VlW4wMu~yjq~LTUJuDvJMt9b2zsZbE8qLJW6SQKfTz07OVzBgfZOmFLdx5JM~cZgzt5fA0XBcolPcdLG6QCgTjIi2022ezLQ-ASqeVt5QXk4tpGP4G5XlGw__">
          </img>
        <div style={webStyle.clientDetailsDiv}>
            <Typography style={webStyle.clientName}>
              Supercars
            </Typography>
            <Typography style={webStyle.description}>
            Experienced automotive interior designer Living in London.
            </Typography>
            <div style = {webStyle.attributesDivParent}>
            <div style={webStyle.attributesDiv}>
            <Typography style={webStyle.attributeTitle}>
              Location:
            </Typography>
            <img src={location} style={webStyle.iconStyle}>
            </img>
            <Typography style={webStyle.attributeDesc}>
            73 Holborn Street, CV2 R34 Coventry, United Kingdom
            </Typography>
            </div>
            <div style={webStyle.borderBottom}></div>
            <div style={webStyle.attributesDiv}>
            <Typography style={webStyle.attributeTitle}>
              Web:
            </Typography>
            <Typography style={webStyle.attributeDesc}>
            www.supercars.com
            </Typography>
            </div>
            </div>
            <div style={webStyle.clientActionDiv}>
                <img src={likeImage} style={webStyle.iconStyle}>
                </img>
                <div style={webStyle.clientActionright}>
                <img src={ChatIcon} style={webStyle.iconStyle}>
                </img>
                <img src={shareImage} style={webStyle.iconStyle}>
                </img>
                <img src={saveImage} style={webStyle.iconStyle}>
                </img>
                </div>
            </div>
        </div>
      </div>
    )
  }

  descriptionContainer = () =>{
    return(
      <div style={webStyle.descriptionContainer}>
        <Typography style={webStyle.descriptionTitle}>
              Description
        </Typography>
        <Typography style={webStyle.descriptionDes}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis. Sed a odio condimentum, volutpat ex eu, volutpat tortor. Duis imperdiet urna eget lacus sollicitudin, non accumsan lorem posuere. In hac habitasse platea dictumst. Ut tempus et justo ac facilisis. Aliquam vitae faucibus nisi. Vivamus fermentum tortor in massa eleifend imperdiet. Vestibulum magna leo, hendrerit vitae odio eu, egestas tincidunt ligula. Integer sodales ac purus sit amet imperdiet.
        Vivamus fringilla suscipit erat sit amet euismod. Cras tristique ipsum quis lacinia varius. Nam ante ante, auctor ut consectetur eget, tincidunt et metus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed ipsum felis, ullamcorper quis lorem nec, ultricies egestas neque. Mauris erat neque, pulvinar non consectetur sit amet, molestie in ex.
        </Typography>
      </div>
    )
  }

  administratorContainer = ()=>{
    return(
      <div style={webStyle.administratorBlock}>
          {this.headingBar("Administrators", 6)}
          <div style={webStyle.administratorBody}>
            <div style={webStyle.cardParent}>
            {administrators.map((administrator, index) => (
              this.administrator(administrator.name, administrator.headline, administrator.roles )
            ))}
          </div>
          <Button style={webStyle.showMoreButton}>
            Show More +
          </Button>
        </div>
      </div>
    )
  }

  opportunityContainer = ()=>{
    return(
      <div style={webStyle.administratorBlock}>
        {this.headingBar("Opprortunities", 5)}
        <div style={webStyle.opportunityBody}>
          <JobCard
              title="Automotive Designer"
              category="Supercars"
              updatedTime="5 days ago"
              level="Senior"
              location="Coventry, UK"
              type="Full Time | Remote"
              profession="Interior Design | UI/UX Design | Visualization"
              dates="13 Mar 24 - 25 Apr 24"
              rate="£45.00/hr"
              budget="£2,000"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis. Sed a odio condimentum, volutpat ex eu, volutpat tortor. Duis imperdiet urna eget lacus sollicitudin, non accumsan lorem posuere. In hac habitasse platea dictumst. Ut tempus et justo ac facilisis. Aliquam vitae faucibus nisi. Vivamus fermentum tortor in massa eleifend imperdiet. Vestibulum magna leo, hendrerit vitae odio eu, egestas tincidunt ligula. Integer sodales ac purus sit amet imperdiet."
              imageUrl="https://s3-alpha-sig.figma.com/img/0913/6e54/c89f22b93d5b2d16d51fd34a2b9ad98f?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TzcjTW2YeOSmXo8KIZlk1y6V~ANHbm12A1useiNvgtyuGmK6Pf6n48RCVMd7BhFNhsipnQ8ceaIz8IQzUqB3eDg98cxh23-BrkfHs829GgnJJ4TaENFIYJ-otyHRtTLy~yd1UG-lpXPfEYCMpeMFX0zb5sovmwChwMfTXS~FCH3dUu6p9TYOkHS9~GOmPWe~-MgbdTjoDGhILg~YMrFXweYUYZ6Aeq~VlW4wMu~yjq~LTUJuDvJMt9b2zsZbE8qLJW6SQKfTz07OVzBgfZOmFLdx5JM~cZgzt5fA0XBcolPcdLG6QCgTjIi2022ezLQ-ASqeVt5QXk4tpGP4G5XlGw__"
          />
          <Button style={webStyle.showMoreButton}>
            Show More +
          </Button>
        </div>
      </div>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <HeaderNavbar navigation={this.props.navigation}/>
        <Container maxWidth='xl'  style ={webStyle.parentDiv}>
          <CoverImageComponent />
          {this.profileCard()}
          {this.descriptionContainer()}
          {this.administratorContainer()}
          {this.opportunityContainer()}
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const webStyle = {
  parentDiv : {
    width : "100%",
    padding: "0 0 95px 0",
    display : 'flex', 
    flexDirection : 'column' as 'column', 
    alignItems : 'center'
  },
  profileContainer : {
    marginTop : `-110px`,
    width: '85%',
    border: '1px solid #DFDFDF',
    zIndex: 2,
    backgroundColor: "white",
    borderRadius: '4px',
    display: "flex",
    padding: "48px",
    gap: "48px",
    boxSizing: 'border-box' as 'border-box',
  },
  descriptionContainer : {
    width : '85%',
    border : '1px solid #DFDFDF',
    marginTop : '70px',
    borderRadius : '4px',
    padding : "48px",
    boxSizing: 'border-box' as 'border-box',
    display : "flex",
    flexDirection : "column" as "column",
    gap : "24px"
  },
  administratorBlock : {
    width : '85%',
    border : '1px solid #DFDFDF',
    marginTop : '70px',
    overflow : "hidden",
    borderRadius : '4px',
  },
  headingBar : {
    height : "60px",
    width : "100%",
    backgroundColor : "#0D0C22",
    padding : "0 0 0 48px",
    display : "flex",
    alignItems : "center"
  },
  blockHeadding : {
    color : "white",
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "16px",
    lineHeight : "19.3px"
  },
  clientImgStyle :{
    height : "170px",
    width : "170px",
    border : "1px solid #D9D9D9",
    objectFit : "cover" as "cover",
  },
  clientDetailsDiv : {
    height : "100%",
    display : "flex",
    flex:1,
    flexDirection : "column" as "column",
    gap : "24px"
  },
  clientName : {
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "26px",
    lineHeight : "31.37px"
  },
  description : {
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "14px",
    lineHeight : "19.69px"
  },
  borderBottom : {
    borderBottom : "1px solid #DFDFDF"
  },
  attributesDiv : {
    display : "flex",
    gap  :"8px",
    alignItems : "center"
  },
  attributeTitle : {
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "12px",
    lineHeight : "16.88px",
    color : "#9B9B9D"
  },
  descriptionTitle : {
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "20px",
    lineHeight : "24.13px",
  },
  descriptionDes : {
    fontFamily : "Silika-Light",
    fontWeight : 200,
    fontSize : "16px",
    lineHeight : "18.98px",
  },
  attributeDesc : {
    fontFamily : "Silika-Medium",
    fontWeight : 500,
    fontSize : "12px",
    lineHeight : "16.88px",
  },
  attributesDivParent : {
    height : "70px",
    display : "flex",
    flexDirection : "column" as "column",
    borderBottom : "1px solid #DFDFDF",
    borderTop : "1px solid #DFDFDF",
    justifyContent : "space-evenly"
  },
  clientActionDiv : {
    height : "30px",
    width : "100%",
    display : "flex",
    justifyContent : "space-between",
    alignItems : "center"
  },
  clientActionright : {
    display  :"flex",
    gap : "20px"
  },
  iconStyle : {
    height : "24px",
    width : "24px"
  },
  administratorBody : {
    display  :"flex",
    flexDirection : "column" as "column",
    alignItems : "center",
    padding : "48px"
  },
  opportunityBody : {
    display  :"flex",
    flexDirection : "column" as "column",
    alignItems : "center",
    padding : "48px"
  },
  cardParent : {
    display : "flex",
    gap : "24px",
    flexWrap  :"wrap" as "wrap",
    justifyContent : "center",
    marginTop : "24px",
  },
  adminstratorBlock : {
    maxHeight : "332px",
    maxWidth : "375px",
    paddingTop : "30px",
    paddingBottom  :"30px",
    display : "flex",
    flexDirection : "column" as "column",
    gap : "24px",
    alignItems : "center",
    borderRadius : "4px",
    border : "1px solid #DFDFDF"
  },
  administratorprofile : {
    height : "100px",
    width : "100px",
    borderRadius : "50%",
    objectFit : "cover" as "cover"
  },
  administratorName : {
    fontFamily : "Silika-Medium",
    fontSize : "20px",
    fontWeight : 500,
    lineHeight : "24.13px",
    textAlign : "center" as "center"
  },
  administratorHeadline : {
    fontFamily : "Silika-Light",
    fontSize : "14px",
    fontWeight : 300,
    lineHeight : "22px",
    marginBottom : "10px",
    marginTop  :"4px",
    textAlign : "center" as "center"
  },
  professionBox : {
    width : "315px",
    display : "flex",
    gap : "8px",
    flexWrap:"wrap" as "wrap",
    marginTop : "16px",
    justifyContent : "center"
  },
  professionCard : {
    height : "33px",
    width : "134px",
    border  :"1px solid #DFDFDF",
    borderRadius : "2px",
    display : "flex",
    alignItems : "center",
    justifyContent : "center"
  },
  professionTitle : {
    fontFamily : "Silika-Medium",
    fontSize : "12px",
    fontWeight : 500,
    lineHeight : "14.4px",
  },
  showMoreButton : {
    border : "1px solid #DFDFDF",
    marginTop : "32px",
    textTransform  :"none" as "none",
    fontSize: '16px', 
  }
}

// Customizable Area End
