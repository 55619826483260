import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import _ from "lodash";
export const configJSON = require("./config");
import { measureInternetSpeed } from "../../../components/src/measureInternet.web";
export interface FavouriteTabsEventsProps {
  favourites:Favourite[];
  profiles: OptionType[];
  shareData:OptionType[];
  chatMessage: string;
  onShareDataChange: (event: React.ChangeEvent<{}>, value: OptionType[]) => void;
  onChangeChatMessage: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onClickShareButton: (event: React.MouseEvent<HTMLButtonElement>, eventId?: string) => void;
  onClickChatButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickBackButton: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickSendChat: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isShareModalOpen: boolean;
  shareLink: string;
  isChatSend: boolean;
  isChatModalOpen: boolean;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickDeleteFavourite: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void;
  onNavigateToDetailsPage: (eventId: string) => void;
  isLoading: boolean;
  count: number;
  onLoadMoreClick: () => void;
  needLoadMoreBtn: boolean;
}

export interface FavouriteTabsProps extends FavouriteTabsEventsProps{
  navigation?: {};
  activeTab: string;
  onTabChange: (tab: string) => void;
  favouritesProjects: any[];
  favouritesPeople: any[];
  favouritesJobs: any[];
  searchKey: string;
  onChangeSearchKey: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export interface OptionType {
  firstName: string;
  lastName: string;
  photo: string;
}

type Media = {
  vimeo?: string;
  tikTok?: string;
  behance?: string;
  youtube?: string;
  linkedin?: string;
  instagram?: string;
};

type ProfileAttributes = {
  id: number;
  country: string;
  county: string;
  city: string;
  postal_code: string | null;
  account_id: number;
  first_name: string;
  last_name: string;
  bio: string | null;
  skill: string[];
  phone_number: string;
  profession: string[];
  headline: string;
  company_name: string;
  company_number: string;
  registered_address: string;
  vat_number: string;
  availability: string;
  thumbs_up_count?: number;
  preferred_type_of_work: string | null;
  visibility_option: string | null;
  hourly_rate_option: string | null;
  online_option: string | null;
  dates: string | null;
  seniority_level: string;
  work_location: string | null;
  work: string[];
  hourly_rate: string | null;
  collaborators: string[];
  tags: string[];
  description: string;
  photo: string;
  media: Media;
  unavailable_dates: string;
  hourly_rate_from: number | null;
  hourly_rate_to: number | null;
  email: string;
  is_like?: boolean;
};

export type Profile = {
  id: string;
  type: string;
  attributes: ProfileAttributes;
};

type Account = {
  data: Profile;
};

type EventAttributes = {
event_name: string;
description: string;
event_website: string;
account: Account;
start_date: string;
end_date: string;
start_time: string;
end_time: string;
is_in_person: string;
is_favourite: boolean;
address: string;
link: string;
country: string;
county: string;
town: string;
postcode: string;
created_at: string;
updated_at: string;
banner_image: string;
attendees: {
  data: Profile[];
};
};

type Favouriteable = {
data: {
  id: string;
  type: string;
  attributes: EventAttributes;
};
};

type Attributes = {
favouriteable_id: number;
favouriteable_type: string;
user_id: number;
created_at: string;
updated_at: string;
favouriteable: Favouriteable;
};

export type Favourite = {
id: string;
type: string;
attributes: Attributes;
};
interface IListFavourites {
  data: Favourite[];
  errors?: Array<Record<string, any>>;
} 
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  activeTab: string;
  favourites: Favourite[];
  favouritesProjects: any[];
  favouritesPeople: any[];
  favouritesJobs: any[];
  profiles: OptionType[];
  shareData:OptionType[];
  chatMessage: string;
  isShareModalOpen: boolean;
  shareLink: string;
  isChatSend: boolean;
  isChatModalOpen: boolean;
  searchKey: string;
  isLoading: boolean;
  count: number;
  needLoadMoreBtn: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class FavouritesControllerWeb extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  listFavouritesApi: string | null = "";
  listProfilesApiCallId: string | null = "";
  deleteFavouriteEventApiCallId: string | null = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      activeTab: "events",
      favourites: [],
      favouritesProjects: [],
      favouritesJobs: [],
      favouritesPeople: [],
      profiles: [],
      shareData: [],
      chatMessage:"",
      isShareModalOpen: false,
      isChatModalOpen: false,
      isChatSend: false,
      searchKey: "",
      shareLink: "",
      isLoading: false,
      count: 6,
      needLoadMoreBtn: false
    }
    this.handleScroll = this.handleScroll.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.listFavouriteEvents();
    this.fetchProfiles();
    window.addEventListener("scroll", this.handleScroll);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseType = getName(MessageEnum.RestAPIResponceMessage);
    if(responseType === message.id){
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if(!errorResponse){
        switch(apiRequestCallId){
          case this.listFavouritesApi: {
            this.handleListFavouritesResponse(responseJson, errorResponse);
            break;
          }

          case this.listProfilesApiCallId:{
            this.setState({profiles: responseJson.data.map((profile: Profile) => ({firstName: profile.attributes.first_name, lastName: profile.attributes.last_name, photo: profile.attributes.photo }))});
            break;
          }

          case this.deleteFavouriteEventApiCallId:{
            this.listFavouriteEvents();
            break;
          }

          default: {
            return;
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleScroll = async () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;
    const touchedBottom = windowHeight + scrollTop >= scrollHeight;
    if (touchedBottom) {
      const speed = await measureInternetSpeed();
      const speedInSeconds =  speed / 1000;
      const fileSizeInBytes = 1024 * 1024;
      const estimatedSpeedMbps = fileSizeInBytes / speedInSeconds / 125000;
      const slowInternet = estimatedSpeedMbps && estimatedSpeedMbps <= 30;
      if(slowInternet){
        this.setState({needLoadMoreBtn:true});
      }
      else{
        this.handleLoadMoreClick();
      }
    }
  };
  handleChangeTab = (tab: string) => {
    this.setState({activeTab: tab, favourites: []});
    if(tab === "events"){
      this.listFavouriteEvents();
    }
  }

  listFavouriteEvents = () => {
    const { searchKey } = this.state;
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.listFavouritesApi = requestMessage.messageId;

    let endpoint = `${configJSON.getFavouritesApiEndPoint}${configJSON.eventsType}`;

    if(searchKey){
      endpoint = `${endpoint}&query=${searchKey}`
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleListFavouritesResponse = async (responseJson: IListFavourites, errorResponse? : object) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ favourites: responseJson.data });
    }else if(responseJson.errors){
      if(responseJson.errors.length > 0 && responseJson.errors[0].token && responseJson.errors[0].token.toLowerCase().includes("token")){
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationTargetMessage), configJSON.loginRoute);
        runEngine.sendMessage(message.id, message);
      }
    }
    else{
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }

  fetchProfiles = () => {
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.listProfilesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchProfiles
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShareData = (event: React.ChangeEvent<{}>, value: OptionType[]) => {
    this.setState({ shareData: value });
  }

  handleChangeChatMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({chatMessage: event.target.value});
  }
  handleBackButton = () => {
    this.setState(prevState => ({...prevState, isShareModalOpen: !prevState.isShareModalOpen, isChatModalOpen: !prevState.isChatModalOpen}));
  }

  handleClickShareButton = (event: React.MouseEvent<HTMLButtonElement>, eventId?: string) => {
    event.stopPropagation();
    this.setState(prevState => {
      let updatedState = {
        ...prevState, 
        isShareModalOpen: !prevState.isShareModalOpen
      }
      if(eventId){
        updatedState.shareLink = window.location.href.split("/",3).join("/")+"/EventView?id="+`${eventId}`
      }
      return updatedState;
    });
  }

  handleClickChatButton = () => {
    this.setState(prevState => ({...prevState, isChatModalOpen: !prevState.isChatModalOpen, isShareModalOpen: !prevState.isShareModalOpen}));
  }

  handleSendChatButton = () => {
     this.setState({isChatModalOpen: false, isShareModalOpen: true, isChatSend: true});
  }
  handleCloseButton = () => {
    this.setState({isChatModalOpen: false, isShareModalOpen: false, shareData: [], shareLink:""});
  }

  handleDeleteFavourite = (event: React.MouseEvent<HTMLButtonElement>, id:string) => {
    event.stopPropagation();
    const headers = {
      token: window.localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFavouriteEventApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteFavouriteEvent}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchKeyDebounce = _.debounce(() => {
    this.listFavouriteEvents();
  },1000);

  handleChangeSearchKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchKey: event.target.value}, this.handleSearchKeyDebounce);
  };

  navigateToPage = (idEvent: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), "EventView");
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), idEvent);
    userNavMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(userNavMsg);
  };

  handleLoadMoreClick = () => {
    this.setState({isLoading: true})
    setTimeout(() => {
      this.setState(prevState => ({
        ...prevState,
        count: prevState.count + 6 ,
        isLoading: false,
        needLoadMoreBtn: false
      }));
    }, 1000);
  };

  // Customizable Area End
}