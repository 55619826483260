import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End


export interface S {
  // Customizable Area Start
  showSecurityCode : boolean;
  nameOnCard : string;
  cardNumber : string;
  expirationDate : string;
  securityCode : string;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
interface SS {
  id: any;
}


export default class ClientController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      showSecurityCode : false,
      nameOnCard : "",
      cardNumber : "",
      expirationDate : "",
      securityCode : ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    let authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
  }
  


 
  async receive(from: string, message: Message) {
    // Customizable Area Start
  }
  handleBtnClick(role : string){ 
    sessionStorage.setItem('selectedRole', role);
    this.props.navigation.navigate("AccountRegistration")
  }
  handleToggleSecurityNumber  = () =>{
    this.setState({showSecurityCode : !this.state.showSecurityCode});
  }
  handleKeyDown = (e:any) =>{
    if (
      (e.key < '0' || e.key > '9') &&  // If the key is not a number
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'Tab' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight'
  ) {
      e.preventDefault();  // Prevent other keys
  }
  }
  // Customizable Area End
}
