import React from "react";

// Customizable Area Start
import {
  Typography, TextField,
  Box
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle, StyledButton } from "../../email-account-login/src/LoginSignUp.web";
import AccountLoginController, { Props } from "./AccountLoginController";
import "../../customisableuserprofiles/src/ProfileInfo.css"




// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ForgotPasswordEmail extends AccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={{
          overflow: 'hidden'
        }}>
          <LoginSignUp navigation={this.props.navigation} id={""}>

            <Box width={{ xs: "300px", sm: "327px", md: "360px" }} style={{ ...webStyle.leftContainer }}>


              <div style={{ ...webStyle.formTitleContainer, justifyContent: 'left', marginBottom: 20 }}>
                <Typography style={{ ...webStyle.formTitleText, textAlign: "left" as "left" }}>
                  Forgot Password
                </Typography>
              </div>
              <div style={webStyle.isAccountDiv}>
                <Typography style={{
                  ...webStyle.isAccountStatement, marginBottom: 40, fontFamily: "Silika-Medium", fontSize: "15px", color: 'black'
                }}>
                  Please enter the email address you'd like your password reset information sent to
                </Typography></div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography style={{
                  ...webStyle.isAccountStatement, fontFamily: "Silika-Medium", fontSize: "15px", marginBottom: 10, color: 'black'
                }}>
                  Enter email address
                </Typography>
                <TextField
                  onBlur={() => { this.validateEmail() }}
                  fullWidth
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      this.handleBtnClick();
                    }
                  }}
                  error={!!this.state.pswrdEmailError}
                  helperText={this.state.pswrdEmailError}
                  style={webStyle.inputfieldHeight}
                  variant="outlined"

                  placeholder="email@email.com"
                  data-test-id={"pwdEmail"}
                  name="password"
                  onChange={(event) => {
                    this.handleEmailChange(event)
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: 0,
                    },
                  }}
                />
              </div>
                <StyledButton
                  variant="outlined"
                  data-test-id={"submitBtn"}
                  onClick={() => { this.handleBtnClick() }}
                  style={{ ...webStyle.logInButton, textTransform: "capitalize" }}
                >
                  Request reset link
                </StyledButton>

            </Box>

          </LoginSignUp></div>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}


