// Customizable Area Start
import React from "react";
import { Grid, 
  Box, 
  Typography, 
  Button,
  styled, 
  ButtonGroup } from "@material-ui/core";
import JobListingController from "./JobListingController"
import HeaderNavbar from "../../../components/src/Navbar"
import JobCard from "../../../components/src/JobCard.web"
import { AdvanceSearch }from "./components/advanceSearch"
import JobPostPopUp from "../../../components/src/JobPostPopUp";
import PostPositionPopUp from '../../../components/src/PostPositionPopUp';

const StyledTabWrapper = styled(Box)({
  marginTop:"51px",
  display:"flex",
  flexDirection:"column",
  padding: "80px",
  "& .tabTitle":{
    fontFamily:"Silika-Medium !important"
  },
  "& .tabBtn":{
    borderRadius:"0px"
  },
  "& .cardContainer":{
   display:"flex",
   columnGap:"2%",
    "@media (max-width:960px)": {
      marginBottom:"20px",
    },
  },
  
})
class JobListing extends JobListingController {
  
  buttonGroupOfTab = () =>{
    return(
      <ButtonGroup style={webStyle.opportunityDiv}>
      <Button
        data-test-id="InfoDiv"
        type="button"
        className="tabBtn"
        onClick={() => {
          this.handleTabChange(1);
        }}
        style={this.state.activeTab ===1 ? webStyle.infoDiv : webStyle.workDiv}
      >
        <Typography
          style={this.state.activeTab ===1 ? webStyle.info : webStyle.info1}
          className="tabTitle"
        >
         Search
        </Typography>
      </Button>
      <Button
        data-test-id="workDiv"
        type="button"
        className="tabBtn"
        onClick={() => {
          this.handleTabChange(2);
        }}
        style={this.state.activeTab === 2 ? webStyle.infoDiv : webStyle.workDiv}
      >
        <Typography
          style={this.state.activeTab === 2 ? webStyle.info : webStyle.info1}
          className="tabTitle"
        >
          Invitations{" "}
        </Typography>
      </Button>
      <Button
        data-test-id="workDiv"
        type="button"
        className="tabBtn"
        onClick={() => {
          this.handleTabChange(3);
        }}
        style={this.state.activeTab === 3 ? webStyle.infoDiv : webStyle.workDiv}
      >
        <Typography
          style={this.state.activeTab === 3 ? webStyle.info : webStyle.info1}
          className="tabTitle"
        >
          Applications{" "}
        </Typography>
      </Button>
    </ButtonGroup>
    )
  }
  render() {
    return (
      <Grid>
        <HeaderNavbar navigation={this.props.navigation}/>
        <PostPositionPopUp  isOpen={false} onClose={()=>{}}/>
        <JobPostPopUp isOpen= {this.state.isPostJobOpen} onClose= {this.handleNewOpportunity}/>
       <StyledTabWrapper>
        <Box style={webStyle.opportunityBox}>
          <Typography style={webStyle.OpportunitiesText}>Opportunities</Typography>
          <Button style={webStyle.opportunityBtn} onClick={this.handleNewOpportunity}>New Opportunity</Button>
        </Box>
         {this.buttonGroupOfTab()}
        {this.state.activeTab ===1 && 
       <Grid container style={webStyle.jobCardContainer}>
          <AdvanceSearch />
        <JobCard
              title="Automotive Designer"
              category="Supercars"
              updatedTime="5 days ago"
              level="Senior"
              location="Coventry, UK"
              type="Full Time | Remote"
              profession="Interior Design | UI/UX Design | Visualization"
              dates="13 Mar 24 - 25 Apr 24"
              rate="£45.00/hr"
              budget="£2,000"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis. Sed a odio condimentum, volutpat ex eu, volutpat tortor. Duis imperdiet urna eget lacus sollicitudin, non accumsan lorem posuere. In hac habitasse platea dictumst. Ut tempus et justo ac facilisis. Aliquam vitae faucibus nisi. Vivamus fermentum tortor in massa eleifend imperdiet. Vestibulum magna leo, hendrerit vitae odio eu, egestas tincidunt ligula. Integer sodales ac purus sit amet imperdiet."
              imageUrl="https://s3-alpha-sig.figma.com/img/0913/6e54/c89f22b93d5b2d16d51fd34a2b9ad98f?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TzcjTW2YeOSmXo8KIZlk1y6V~ANHbm12A1useiNvgtyuGmK6Pf6n48RCVMd7BhFNhsipnQ8ceaIz8IQzUqB3eDg98cxh23-BrkfHs829GgnJJ4TaENFIYJ-otyHRtTLy~yd1UG-lpXPfEYCMpeMFX0zb5sovmwChwMfTXS~FCH3dUu6p9TYOkHS9~GOmPWe~-MgbdTjoDGhILg~YMrFXweYUYZ6Aeq~VlW4wMu~yjq~LTUJuDvJMt9b2zsZbE8qLJW6SQKfTz07OVzBgfZOmFLdx5JM~cZgzt5fA0XBcolPcdLG6QCgTjIi2022ezLQ-ASqeVt5QXk4tpGP4G5XlGw__"
              stage="open"
              btnText="Apply"
         />
        </Grid>}
        {this.state.activeTab ===2 && 
        <Grid container style={webStyle.jobCardContainer}>
           {Array.from(Array(2)).map((_, index) => (
         <Box style={{marginBottom: "40px"}}>
           <JobCard
               title="Automotive Designer"
               category="Supercars"
               updatedTime="5 days ago"
               level="Senior"
               location="Coventry, UK"
               type="Full Time | Remote"
               profession="Interior Design | UI/UX Design | Visualization"
               dates="13 Mar 24 - 25 Apr 24"
               rate="£45.00/hr"
               budget="£2,000"
               description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis. Sed a odio condimentum, volutpat ex eu, volutpat tortor. Duis imperdiet urna eget lacus sollicitudin, non accumsan lorem posuere. In hac habitasse platea dictumst. Ut tempus et justo ac facilisis. Aliquam vitae faucibus nisi. Vivamus fermentum tortor in massa eleifend imperdiet. Vestibulum magna leo, hendrerit vitae odio eu, egestas tincidunt ligula. Integer sodales ac purus sit amet imperdiet."
               imageUrl="https://s3-alpha-sig.figma.com/img/0913/6e54/c89f22b93d5b2d16d51fd34a2b9ad98f?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TzcjTW2YeOSmXo8KIZlk1y6V~ANHbm12A1useiNvgtyuGmK6Pf6n48RCVMd7BhFNhsipnQ8ceaIz8IQzUqB3eDg98cxh23-BrkfHs829GgnJJ4TaENFIYJ-otyHRtTLy~yd1UG-lpXPfEYCMpeMFX0zb5sovmwChwMfTXS~FCH3dUu6p9TYOkHS9~GOmPWe~-MgbdTjoDGhILg~YMrFXweYUYZ6Aeq~VlW4wMu~yjq~LTUJuDvJMt9b2zsZbE8qLJW6SQKfTz07OVzBgfZOmFLdx5JM~cZgzt5fA0XBcolPcdLG6QCgTjIi2022ezLQ-ASqeVt5QXk4tpGP4G5XlGw__"
               stage="invitation"
               btnText="Accept"
          />
        </Box>
        ))}
         </Grid>
        }
        {this.state.activeTab === 3 && 
        <Grid container style={webStyle.jobCardContainer}>
         
           {Array.from(Array(4)).map((_, index) => (
         <Box style={{marginBottom: "40px", minWidth: "100%"}}>
        <JobCard
              title="Automotive Designer"
              category="Supercars"
              updatedTime="5 days ago"
              level="Senior"
              location="Coventry, UK"
              type="Full Time | Remote"
              profession="Interior Design | UI/UX Design | Visualization"
              dates="13 Mar 24 - 25 Apr 24"
              rate="£45.00/hr"
              budget="£2,000"
              description=""
              imageUrl="https://s3-alpha-sig.figma.com/img/0913/6e54/c89f22b93d5b2d16d51fd34a2b9ad98f?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=TzcjTW2YeOSmXo8KIZlk1y6V~ANHbm12A1useiNvgtyuGmK6Pf6n48RCVMd7BhFNhsipnQ8ceaIz8IQzUqB3eDg98cxh23-BrkfHs829GgnJJ4TaENFIYJ-otyHRtTLy~yd1UG-lpXPfEYCMpeMFX0zb5sovmwChwMfTXS~FCH3dUu6p9TYOkHS9~GOmPWe~-MgbdTjoDGhILg~YMrFXweYUYZ6Aeq~VlW4wMu~yjq~LTUJuDvJMt9b2zsZbE8qLJW6SQKfTz07OVzBgfZOmFLdx5JM~cZgzt5fA0XBcolPcdLG6QCgTjIi2022ezLQ-ASqeVt5QXk4tpGP4G5XlGw__"
              stage="application"
              btnText="Withdraw"
         />
         </Box>
        ))}
        </Grid>
        }
        </StyledTabWrapper>
      </Grid>
    );
  }
}

export default JobListing;

const webStyle = {
  outerMostContainerSize: {
    height: "600px",
    width: "100%",
    marginTop: "100px",
    marginBottom:'100px',
    paddingTop:50,
    position: 'relative' as 'relative'
  },
  opportunityDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infoDiv: {
    width: "50%",
    alignItems: "center",
    border: "none",
    borderBottom: "2px solid #000",
  },
  workDiv: {
    width: "50%",
    alignSelf: "center",
    border: "none",
    borderBottom: "2px solid #DFDFDF",
  },
  info: {
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    textAlign: "center" as "center",
    textTransform: "none" as "none",
  },
  info1: {
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    textAlign: "center" as "center",
    color: "#9B9B9D",
    textTransform: "none" as "none",
  },
  jobCardContainer: {
    padding: "48px 0 48px 0",
  },
  OpportunitiesText: {
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    color: "#000000",
  },
  opportunityBtn: {
    width: "136px",
    height: "48px",
    backgroundColor: "#0D0C22",
    color: "#FFFFFF",
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "11px",
    lineHeight: "19.2px",
    letterSpacing: "-0.15px",
},
opportunityBox: {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "50px"
}
};
// Customizable Area End
