Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.visibilitySetting = {
  visibility_option: "everyone except my current employer",
  hourly_rate_option: "everyone except my current employer",
  online_option: "everyone except my current employer"
}
exports.notificationSetting = {
  notify_events: {
    title:"Events",
    description: "Notices related to events you are attending or interested in.",
    value: false
  },
  notify_chats:{
    title: "Chats Updates",
    description: "Notifications when you receive new messages.",
    value: false
  },
  notify_timesheets: {
    title: "Timesheet Updates",
    description: "When your timesheets are edited or approved.",
    value: false
  },
  notify_invoicing: {
    title: "Invoicing Related Emails",
    description: "When your invoices are modified or approved.",
    value: false
  },
  notify_project_status: {
    title: "Project Status Updates",
    description: "Alerts when projects you are interested in, or working on, are updated.",
    value: false
  },
  notify_invitations: {
    title: "Invitations",
    description: "Notifications of being invited to projects, or being accepted to work on projects.",
    value: false
  },
  notify_credited: {
    title: "Being Credited",
    description: "Receive notice when you are credited as a project collaborator by others.",
    value: false
  }
}
exports.getProfileApiEndPoint = "bx_block_profile/profiles/"
exports.visbilitySettingApiEndPoint="bx_block_profile/visibility_setting";
exports.termsAndConditionsApiEndPoint="terms_and_conditions/terms_and_conditions";
exports.projectWorkingApiEndPoint="bx_block_profile/hourly_rate_setting"
exports.notificationSettingApiEndPoint="account_block/accounts/notification_settings";
exports.putApiMethod="PUT";
exports.getNotificationApiEndPoint="account_block/accounts/show_profile";
exports.loginRoute="AccountLogin"
exports.resetPasswordApiEndPoint="forgot_password/passwords/reset_password";
exports.agreeTermsAndConditionsApiEndPoint="terms_and_conditions/accept_terms";
exports.deleteAccountApiEndPoint="account_block/accounts/delete_account";
exports.deleteApiMethod="DELETE";
exports.editEmailApiEndPoint="account/accounts/email_update";
exports.patchApiMethod="PATCH"
// Customizable Area End