import React from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle } from "../../email-account-login/src/LoginSignUp.web";
import ClientController, {Props} from "./ClientController";
import MembershipCard from "./components/MembershipCard";
import { rightLogo } from "./assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Silika-Medium"
    }
});

const plans = [
    {
        id : 1,
        title : "Free",
        headline  :"Perfect plan for Starters",
        cost: 0,
        features : ["Portfolio page", "Opportunities feed", "View and search events"],
        buttonText : "Get Free"
    },
    {
        id : 2,
        title : "Freelance",
        headline  :"Work on projects professionally.",
        cost: 12,
        features : ["Can initiate messages", "Apply to opportunities", "Create events"],
        buttonText : "Get Freelance"
    },
    {
        id : 3,
        title : "Studio",
        headline  :"Build teams and work on projects.",
        cost: 30,
        features : ["Boost your profile", "Shortlist designers", "Payment management"],
        buttonText : "Get Studio"
    },
    {
        id : 4,
        title : "Enterprise",
        headline  :"Find talent and manage professional teams.",
        cost: 80,
        features : ["6 Roles per month", "Project management", "Approval permissions"],
        buttonText : "Get Enterprise"
    }
];
const StyledTableContainer = styled(TableContainer)({
    width: '100%',
    "@media(max-width:1200px)":{
        maxWidth:"800px",
    },
    overflowX: 'scroll',
    fontFamily:"Silika-Medium",
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor:"white",
    "& .getItNow":{
       backgroundColor:"#0D0C22 !important",
       fontFamily:"Silika-Medium",
       textTransform:"none",
       fontSize : "12px",
       width:"98px",
       marginTop : "8px"
    },
    "& .plantTitle":{
       fontFamily:"Silika-Medium",
       fontWeight:500,
       fontSize:"18.72px"
    },
    "& .featureTitle":{
        fontFamily:"Silika-Light",
       fontWeight:300,
       fontSize:"14px",
       lneHeight  :"18.72"
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '& table': {
        width: '100%',
        display: 'block',
        overflowX: 'auto',
        border: `1px solid ${theme.palette.grey[300]}`,
      },
    },
  });
  
  const StyledTableCell = styled(TableCell)({
    textAlign: 'center',
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    fontSize:"22.66px",
    backgroundColor:"white",
    '&.green': {
        color: '#00FFA7',
      },
      '&.black': {
        color: 'black',
        fontSize : "12px",
        fontFamily : "Silika-Medium",
        fontWeight : 4000
      },
  });
  
  const SectionHeaderCell = styled(TableCell) ({
    backgroundColor: `${theme.palette.grey[300]}`,
    fontWeight: 500,
    textAlign: 'left',
    border: `1px solid ${theme.palette.grey[300]}`,
    fontFamily:"Silika-Medium",
    fontSize:"16px",
    lineHeight  :"22px",
  });
  const GetButton = styled(Button)({
    backgroundColor:"black",
    color: "white",
    cursor: 'pointer',
    textAlign: 'center',
  });
  const data = [
    {
      category: 'Profile management',
      features: [
        'Subscribe and manage subscription',
        'Portfolio page management',
        'Portfolio page',
        'Tagging/crediting other collaborators',
        'Request collaborator credit from others',
        'Approving collaborator credit from others',
        'Like images in other portfolios',
        'Save favourite profiles',
        'See other people social media links',
      ],
    },
    {
      category: 'Search',
      features: [
        'Filter by names', 
        'Filter by other fields', 
        'See full surnames'
      ],
    },
    {
      category: 'Messaging & Interaction',
      features: [
        'Links to social accounts', 
        'Calendar management to mark availability', 
        'Initiate messaging','Block messaging from selected users',
        'Boost your profile'
      ],
    },
      {
          category: 'Events',
          features: [
              'View and search events',
              'Create events',
          ],
      },
      {
          category: 'Content',
          features: [
              'Media library for online content'
          ],
      },
      {
        category: 'Opportunities',
        features: [
            'Opportunities feed',
            'Review portfolios',
            'Submit automated invoice',
            'Submit timesheet',
            'Be invited to an opportunity',
            'See if you’ve been accepted',
            'Apply to an opportunity',
            'Access to project management dashboard',
            'Define deliverables',
            'Define dates',
            'See designer availability',
            'Shortlist designers',
            'See who’s applied to your opportunity',
            'Request video interviews / schedule appoitment ',
            'Timesheet review and approve',
            'Payment management',
            'Included roles per month',
            'Auto-onboard document suite'
        ],
    },
    {
        category: 'Account Permissions',
        features: [
            'Different approval permissions under a single account',
            'Multiple seats on each account (max 5)'
        ],
    },
  ];
  const planFeatures = [
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'],
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'],   
    [' ', ' ', '✔', '✔'],
    ['✔', '✔', '✔', '✔'], 
    [' ', '✔', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'], 
    [' ', '✔', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],   
    [' ', ' ', ' ', '✔'],    
    ['✔', '✔', '✔', '✔'],
    [' ', '✔', '✔', '✔'],
    [' ', '✔', '✔', '✔'],  
    ['✔', '✔', '✔', '✔'],
    ['✔', '✔', '✔', '✔'],
    ['✔', '✔', '✔', '✔'],
    ['✔', '✔', '✔', ' '],
    ['✔', '✔', '✔', ' '],
    ['✔', '✔', '✔', ' '],
    [' ', '✔', '✔', ' '],
    [' ', ' ', '✔', '✔'],
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'], 
    [' ', ' ', '1', '6'],   
    [' ', ' ', ' ', '✔'],
    [' ', ' ', ' ', '✔'],
    [' ', ' ', ' ', '✔'],  
  ];
// Customizable Area End

export default class Membership extends ClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  
  PricingTable = () => {
    return (
        <StyledTableContainer>
        <Table aria-label="pricing table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {plans.map((plan, index) => (
                <StyledTableCell key={index}>
                  <Typography className="plantTitle" >{plan.title}</Typography>
                  <GetButton className="getItNow" >Get it Now</GetButton>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
                    {data.map((section, sectionIndex) => (
                        <React.Fragment key={sectionIndex}>
                            <TableRow>
                                <SectionHeaderCell colSpan={plans.length + 1}>
                                    {section.category}
                                </SectionHeaderCell>
                            </TableRow>
                            {section.features.map((feature, featureIndex) => {
                                const featureIndexOffset = sectionIndex === 0
                                    ? featureIndex
                                    : featureIndex + data.slice(0, sectionIndex).reduce((acc, curr) => acc + curr.features.length, 0);

                                return (
                                    <TableRow key={featureIndex}>
                                        <TableCell className="featureTitle">{feature}</TableCell>
                                        {plans.map((_, planIndex) => {
                                            const cellValue = planFeatures[featureIndexOffset][planIndex];
                                            const cellClass = cellValue === '✔' ? 'green' : 'black';

                                            return (
                                                <StyledTableCell className={cellClass} key={planIndex}>
                                                    {cellValue==='✔'?  <img style={{height : "12.75px", width:"16.78px"}} src={rightLogo} alt="right" /> : cellValue}
                                                </StyledTableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </React.Fragment>
                    ))}
        </TableBody>
        </Table>
      </StyledTableContainer>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={{
          overflow: 'hidden'
        }}>
          <LoginSignUp navigation={this.props.navigation} id={""}>

            <Box  style={{ ...webStyle.leftContainer, width : "95%", marginLeft : "24px"}}>
                <Typography style={webStyle.membershipTitle}>Select other plan</Typography>
                <div style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "12px", marginBottom: "38px" }}>
                    {plans.map((plan, index)=>(
                        <MembershipCard
                           key={index}
                            planTitle={plan.title}
                            planDescription={plan.headline} 
                            cost={plan.cost} 
                            features={plan.features} 
                            buttonText={plan.buttonText}
                        />
                    ))}
                </div>
                <div style={{marginBottom : "50px"}}>
                    {this.PricingTable()}
                </div>
            </Box>
          </LoginSignUp></div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}


