import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import LoginSignUp, { webStyle , StyledButton, StyledTypography} from "./LoginSignUp.web";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CommonDialog from "../../../components/src/CommonDialog.web";

const validationSchema = Yup.object().shape({
  email: Yup.string().email('This email is not valid').required('Email is required'),
  password: Yup.string()
    .required("Password is required"),
});

// Customizable Area End

import AccountLoginController, { Props } from "./AccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class AccountLogin extends AccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div 
        style={{overflow:'hidden'}}>
<LoginSignUp navigation={this.props.navigation} id={""}>
          <Formik
            initialValues={{
              email: this.state.signInFormData.email,
              password: this.state.signInFormData.password,
            }}
            data-test-id="formikForm"
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            validateOnChange={false}
            
          >
            {({ values, errors, setFieldValue, validateField, handleSubmit }) => (
              <form autoComplete="off" onSubmit={handleSubmit} >
                <div style={webStyle.formTitleContainer}>
                  <StyledTypography style={webStyle.formTitleText}>Log In</StyledTypography>
                </div>

                <Box width={{xs : "300px", sm : "327px",  md: "360px"}} style = {{ display : "flex", alignItems : "center", flexDirection : "column"}}>

                <Box width={{xs : "300px", sm : "327px",  md: "360px"}} >
                  <TextField
                    fullWidth
                    style={
                       webStyle.inputfieldHeight
                    }
                    data-test-id={"txtEmail"}
                    name={"email"}
                    error={!!errors.email||!!this.state.pswrdEmailError}
                    helperText={errors.email||this.state.pswrdEmailError}
                    placeholder="Email"
                    variant="outlined"
                    inputProps={{ maxLength: 100 }}
                    value={values.email}
                    onChange={(event) => {
                      setFieldValue("email", event.target.value);
                     
                    }}
                    onBlur={()=>{validateField('email')}}
                    FormHelperTextProps={{
                      style: {
                        marginLeft:0,
                      }
                    }}
                  />
                </Box>
                <Box width={{xs : "300px", sm : "327px",  md: "360px"}} style={webStyle.passwordInputPosition}>
                  <TextField
                    fullWidth
                    style={
                       webStyle.inputfieldHeight
                    }
                    variant="outlined"
                    type={this.state.showPassword ? "text" : "password"}
                    value={values.password}
                    placeholder="Password"
                    data-test-id={"txtPassword"}
                    name="password"
                    error={!!errors.password||!!this.state.errorMsg}
                    helperText={errors.password||this.state.errorMsg}
                    autoComplete='new-password'
                    onChange={(event) => {
                      setFieldValue("password", event.target.value);
                    }}
                    onBlur={()=>{validateField('password')}}
                    FormHelperTextProps={{
                      style: {
                        marginLeft:0,
                      }
                    }}
                    InputProps={{
                      endAdornment: !this.state.showPassword ? (
                        <VisibilityOffIcon
                          style={webStyle.passwordVisibilityOffIcon}
                          onClick={this.handlePassShow}
                        />
                      ) : (
                        <VisibilityIcon
                          style={webStyle.passwordVisibilityOnIcon}
                          onClick={this.handlePassShow}
                        />
                      ),
                    }}
                  />
                </Box>
                <Box  style={webStyle.isForgotPasswordOuterContainer}>
                  <Box width={{xs : "300px", sm : "327px",  md: "360px"}} >
                  <Typography
                    align="right"
                    onClick={() => {
                     this.handleNavigate()
                    }}
                    style={webStyle.isForgotPassword}
                  >
                    Forgot Password?
                  </Typography>
                  </Box>
                  
                </Box>
                  <StyledButton
                    variant="outlined"
                    style={{ ...webStyle.logInButton, textTransform: "capitalize" }}
                    type="submit"
                  >
                    Log In
                  </StyledButton>

                <div style={webStyle.borderContainer}>
                  <div>
                    <Typography style={webStyle.borderOr} />
                  </div>
                  <Typography style={webStyle.or}>or</Typography>
                  <div>
                    <Typography style={webStyle.borderOr} />
                  </div>
                </div>

                <div style={webStyle.isAccountDiv}>
                  <Typography style={webStyle.isAccountStatement}>
                    Don't have an account?
                  </Typography>
                  <span
                    onClick={() => {
                     this.handleSignUpClick();
                    }}
                    style={{...webStyle.signUplink,fontFamily:'Silika-Medium'}}
                  >
                    Sign Up
                  </span>
                </div>
                
                </Box>

              </form>
            )}
          </Formik>
        </LoginSignUp>
    </div>
      <CommonDialog
        title="Account Reactivated"
        description="Thank you, your account has now been reactivated."
        onClose={this.handleOk}
        onHandleOk={this.handleOk}
        okText="Ok"
        open={this.state.isAccountReactivated}
        isOkdisabled={false}
        hasCloseButton={false}
        data-test-id="reactivated"
      />
      <CommonDialog
        title="Reopen Account"
        description="If you continue we will re-active your subscription."
        onClose={this.handleClose}
        onCancelClick={this.handleClose}
        onSuccessClick={this.handleReactivateAccount}
        open={this.state.accountRecoveryModalOpen}
        cancelText="Cancel"
        successText="Ok"
        hasCloseButton={false}
        data-test-id="reopen"
      />
      <CommonDialog 
        open={this.state.isEmailUpdated}
        title="Change email"
        description={<span>Thank you, your email has been updated.</span>}
        onClose={this.handleEmailUpdated}
        onHandleOk={this.handleEmailUpdated}
        okText="Ok"
        hasCloseButton={false}
        data-test-id="emailUpdatedDialog"
      />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
