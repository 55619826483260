import React from "react";

// Customizable Area Start
import {
  Typography,
  Box
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle, StyledTypography } from "../../email-account-login/src/LoginSignUp.web";
import JoinForgeButtons from "./components/JoinForgeButtons";
import ClientController, {Props} from "./ClientController";
// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class JoinForge extends ClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={{
          overflow: 'hidden'
        }}>
          <LoginSignUp navigation={this.props.navigation} id={""}>

            <Box width={{ xs: "300px", sm: "327px", md: "360px" }} style={{ ...webStyle.leftContainer }}>
                <div style={webStyle.formTitleContainer}>
                  <StyledTypography style={webStyle.formTitleText}>Joining Forge</StyledTypography>
                </div>
                <Typography style={webStyle.forgePurposeStatement}>
                    Your purpose for using Forge Design:
                  </Typography>
                  <JoinForgeButtons id = 'joinForgeBtn1' title={"Find a project to work on"}  handleClick={(e)=>this.handleBtnClick("creative")}  />
                  <JoinForgeButtons id = 'joinForgeBtn2' title={"Find talent for my project"}  handleClick={(e)=>this.handleBtnClick("client")} />
                  <JoinForgeButtons id = 'joinForgeBtn3' title={"Both"} handleClick={(e)=>this.handleBtnClick("both")}  />
            </Box>

          </LoginSignUp></div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}


