import React from "react";
import SubscriptionCard from "./SubscriptionCards";
import CommonDialog from "../../../../../components/src/CommonDialog.web";

interface AccountSubscriptionsProps{
    windowWidth: number;
}

export default class AccountSubscriptions extends React.Component<AccountSubscriptionsProps>{
    
    constructor(props: AccountSubscriptionsProps){
        super(props);
    }

    render(){
        return (
            <>
            <SubscriptionCard data-test-id="accountSubscriptions" windowWidth={this.props.windowWidth} />
            <CommonDialog
                open={false}
                title="Downgrade Plan"
                description="Are you sure you wish to downgrade your plan?"
                successText="Confirm"
                cancelText="Cancel"
                hasCloseButton={false}
            />
            <CommonDialog
                open={false}
                title="Downgrade Plan"
                description="Thank you, The requested changes will be implemented for your next billing period."
                okText="Ok"
                hasCloseButton={false}
                isOkdisabled={false}
            />
            <CommonDialog
                open={false}
                title="Upgrade Plan"
                description="Please confirm you wish to upgrade to Studio plan"
                successText="Confirm"
                cancelText="Cancel"
                hasCloseButton={false}
            />
             <CommonDialog
                open={false}
                title="Upgrade Plan"
                description="Thank you, The requested changes will be implemented for your next billing period."
                okText="Ok"
                hasCloseButton={false}
                isOkdisabled={false}
            />
            </>
        )
    }
}