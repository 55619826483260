import React from "react";
// Customizable Area Start
import {
    Button,
     Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { S } from "../PostCreationWebController";
import CustomTag from "../../../../components/src/CustomTags";
import {  close } from "../assets"
import * as Yup from "yup"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "2px",
            "& .MuiOutlinedInput-input": {
                "padding": "14px",
                borderRadius: "2px",
                border: "1px solid #9C9C9C",
                background: "#FFF",
                color: "#DFDFDF",
                "fontFamily": "Silika-Medium",
                "fontSize": "14px",
                "fontStyle": "normal",
                "fontWeight": "lighter",
                "&:hover": {
                    border: "1px solid #DFDFDF",
                },
                "&:focus": {
                    border: "1px solid red",
                },
                "&::placeholder": {
                    "color": "#64748B",
                    "fontFamily": "Silika-Medium",
                    "fontSize": "14px",
                    "fontStyle": "normal",
                    "fontWeight": "lighter",
                    "lineHeight": "22px"
                },
            },
            "& .MuiOutlinedInput-root": {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#9C9C9C",
                },
            },
        },
        experienceInnerDiv1: {
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "1195px",
            background: "#000",
            borderRadius: "4px, 4px, 0px, 0px",
            fontSize: "14px !important",
            fontWeight: 500,
            fontFamily: "Silika-Light",
            lineHeight: "19.3px",
            color: "white",
        },
        dialougeHeading: {
            fontFamily: "Silika-Medium",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "19px",

        },
        InputFormik: {
            // display: "flex",
            // alignItems: "center"
        },
        socialText: {
            fontFamily: "Silika-Medium",
            fontSize: "16px !important",
            fontWeight: 500,
            marginRight: "35px"
        },
        socialInput: {
            border: "1px solid ##DFDFDF",
            borderRadius: "4px", marginTop: "1%", width: 700
        },
        buttonFilled: {
            borderRadius: "2px",
            backgroundColor: "black",
            color: "white",
            marginTop: "20px",
            textTransform: "none"

        },
        buttonCnatined: {
            borderRadius: "2px",
            marginTop: "20px",
            textTransform: "none"
        },
        buttonContainer: {
            paddingRight: "18px"
        }
    })
);

interface Props {
    state: S
    closeDialougCollab: () => void
    handleAddCollab:(values:{})=>void
}

const validationSchema = Yup.object().shape({
    // addCollabInnitialValue: Yup.object().shape({
    selectedCollabarator: Yup.array().min(1, 'At least one collaborator must be selected'),
    emailCollab: Yup.array() // You can define validation for emailCollab if needed
    // })
});
function AddCollabarator(props: Props) {
    const { state, closeDialougCollab,handleAddCollab } = props

    const classes = useStyles();
    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={state.addCollabator}
            onClose={closeDialougCollab}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className={classes.experienceInnerDiv1}>
                <DialogTitle className={classes.dialougeHeading}>{"Add collaborator"}</DialogTitle>
                <img onClick={closeDialougCollab} style={{ width: "20px", paddingRight: "20px", cursor: "pointer" }} src={close}></img>
            </div>
            <DialogContent >
                <DialogContentText id="alert-dialog-slide-description">
                    <Formik
                        initialValues={state.addCollabInnitialValue}
                        onSubmit={(values) => {
                            handleAddCollab(values)
                        }}
                        validationSchema={validationSchema}
                    >
                        {({
                            handleChange,
                            values,
                            handleSubmit,
                            errors,
                            touched,
                            setFieldValue,
                            isValid,
                            dirty,
                        }) => {
                            return (
                                <Form >
                                    <div className={classes.InputFormik} >

                                        <div style={{ width: "100%" }}>

                                            <Field
                                                name="selectedCollabarator"
                                                component={CustomTag}
                                                type="string"
                                                variant="outlined"
                                                title=""
                                                placeHolder="🔍 Search by name"
                                                options={state.getTagsList}
                                            />
                                        </div>
                                        <div style={{ width: "100%" }}>

                                            <Field
                                                name="emailCollab"
                                                component={CustomTag}
                                                type="string"
                                                variant="outlined"
                                                title=""
                                                placeHolder="✉️ Invite by mail"
                                                options={state.getTagsList}
                                                extractEmailDomain={true}
                                                isCreatable={true}
                                            />
                                        </div>
                                    </div>

                                    <DialogActions className={classes.buttonContainer}>
                                        <Button variant="outlined" className={classes.buttonCnatined} onClick={closeDialougCollab} id="cancle-id">Cancel</Button>
                                        <Button variant="contained" className={classes.buttonFilled} disabled={values.selectedCollabarator.length===0} type="submit" id="submitting">Done</Button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContentText>
            </DialogContent>

        </Dialog>
    )

}

export default AddCollabarator
