import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { orangeBanner, rightLogo } from "./assets";
import ExpandMoreIcon from "@mui/icons-material/KeyboardArrowDown";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// Customizable Area End

import LandingPage3Controller, {
    Props,
} from "./LandingPage3Controller";

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: "Silka"
    }
});

const StyledContainer = styled(Box)({
    display:"flex",
    "& .topTitle": {
        fontSize: "64px",
        fontWeight: "500",
        lineHeight: "70.4px",
        color: "#0D0C22",
        fontFamily:"Silika-Medium",
        "@media(max-width:1288px)": {
            fontSize: "48px",
            lineHeight: "39.4px",
        },
        "@media(max-width:892px)": {
            fontSize: "36px",
            lineHeight: "39.4px",
        }
    },
    "& .tagLine": {
        fontSize: "16px",
        fontWeight: "300",
        lineHeight: "25px",
        marginTop: "20px",
        fontFamily:"Silika-Light"
    },
    "& .startProjectTagLine":{
        maxWidth:"582px",
        textAlign:"center"
    },
    "& .paymentContainer": {
        backgroundColor: "#F5F3F3",
        justifyContent:"center",
        width:"100%",
    },
    "& .paymentTitle": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop:"116px",
        "@media(max-width:790px)": {
            paddingLeft: "36px !important",
            paddingRight: "36px !important",
            paddingTop:"71px",
        },
        "@media(max-width:892px)": {
            paddingLeft: "25px !important",
            paddingRight: "25px !important",
        }
    },
    "& .paidCard": {
        paddingLeft: "64px",
        paddingRight: "64px",
        marginTop:"86px",
        "@media(max-width:1250px)": {
            marginTop:"36px",
        },
        "@media(max-width:960px)": {
            paddingLeft: "10px",
            paddingRight: "10px",
        }
    },
    "& .cardTitle": {
        fontWeight: 500,
        fontSize: "24px",
        lineHeight: "33.69px",
        fontFamily:"Silika-Medium",
        "@media(max-width:390px)": {
           display:"flex",
           justifyContent:"center",
           fontSize: "32px",
        }
    },
    "& .subTitle":{
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "25.27px",
        marginTop:"10px",
        fontFamily:"Silika-Light",
        height:"50px",
        "@media(max-width:390px)": {
            display:"flex",
            justifyContent:"center"
         }
    },
    "& .amount":{
        fontWeight: 500,
        fontSize: "48px",
        lineHeight: "58.27px",
        color:"#010101",
        marginTop:"51.3px",
        fontFamily:"Silika-Medium",
        "@media(max-width:1378px)": {
            marginTop:"24.3px !important",
        },
        "@media(max-width:390px)": {
            display:"flex",
            justifyContent:"center",
            lineHeight: "43.27px",
         }
    },
    "& .perMonth":{
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "25.27px",
        color:"gray",
        marginTop:"10px",
        fontFamily:"Silika-Light",
        "@media(max-width:390px)": {
            display:"flex",
            justifyContent:"center",
            marginTop:"0px",
         }
    },
    "& .cardsPayment":{
        paddingLeft:"34px",
        paddingTop:"45px",
        paddingRight:"34px",
        paddingBottom:"45px",
        "&:hover":{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06),0px 8px 12px rgba(0, 0, 0, 0.03), 0px 12px 16px rgba(0, 0, 0, 0.09)"
        },
        "@media(max-width:1378px)": {
            paddingLeft:"24px",
            paddingTop:"24px",
            paddingRight:"24px",
            paddingBottom:"24px",
        },
    },
    "& .cardMarks":{
        display:"flex",
        justifyContent:"flex-start",
        alignItems:"center",
        marginTop:"10px",
        "@media(max-width:390px)": {
            marginTop:"8px",
         }
    },
    "& .checkMarkTitle":{
        fontWeight: 300,
        fontSize: "16px",
        lineHeight: "25.27px",
        color:"#010101",
        marginLeft:"20px",
        fontFamily:"Silika-Light",
        "@media(max-width:1378px)": {
            fontSize: "14px",
        },
    },
    "& .markDiv":{
        marginTop:"50px",
        "@media(max-width:1378px)": {
            marginTop:"24.3px !important",
        }
    },
    "& .choosePlanBtn":{
        backgroundColor:"transparent",
        border:"1px solid #DFDFDF",
        textTransform:"none",
        width:"100%",
        height:"58.96px",
        marginTop:"50px",
        color:"#010101",
        fontSize:"16px",
        fontWeight:"500",
        fontFamily:"Silika-Medium",
        "&:hover":{
            backgroundColor:"black",
            color:"white",
        },
        "@media(max-width:1378px)": {
            marginTop:"24.3px !important",
            height:"44px",
        }
    },
    "& .cardFooterLine":{
        fontSize:"12px",
        fontWeight:"300",
        color:"#9B9B9B",
        marginTop:"25px",
        width:"100%",
        display:"flex",
        justifyContent:"center",
        fontFamily:"Silika-Light",
        "@media(max-width:1378px)": {
            marginTop:"16px",
        }
    },
    "& .startProject":{
        backgroundImage:`url(${orangeBanner})`,
        backgroundRepeat:"no-repeat",
        backgroundSize:"cover",
        alignItems:"center",
        justifyContent:"center",
        display:"flex",
        flexDirection:"column",
        paddingBottom:"146px",
        "@media(max-width:1168px)": {
            paddingBottom:"73px",
        },
        "@media(max-width:550px)": {
            padding: "10px",
            paddingBottom:"36px",
        }
    },
    "& .startProjectTitle":{
        fontFamily:"Silika-Medium",
        fontWeight:"500",
        fontSize:"72px",
        marginTop:"146px",
        "@media(max-width:1168px)":{
            fontSize:"48px",
            marginTop:"80px",
        },
        "@media(max-width:686px)": {
            fontSize:"36px",
            marginTop:"36px",
        },
    },
    "& .startBtnProject":{
        backgroundColor:"#9B9B9D"
    },
    "& .getStartedBtn":{
        width:"125px",
        height:"48px",
        borderRadius:"2px",
        color:"white",
        textTransform:"none",
        fontFamily:"Silka",
        marginTop:"50px",
    },
    "& .myBrandsContainer":{
        paddingRight:"155px",
        paddingLeft:"155px", 
        paddingBottom:"141px",
        backgroundColor:"white",
        "@media(max-width:686px)": {
            paddingLeft: "20px",
            paddingRight: "20px"
        },
        "@media(max-width:1168px)":{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingBottom:"72px",
        },

    },
    "& .cardDiv": {
        width: "25%",
        padding:"10",
        "@media(max-width:1184px)": {
            width: "40%"
        },
        "@media(max-width:950px)": {
            width: "50%"
        },
        "@media(max-width:560px)": {
            width: "90%"
        },
        "@media(max-width:390px)": {
            width: "100%"
        }
    }
});

const StyledAccordian = styled('div')({
    flexDirection:"column",
    paddingTop:"60px",
    display:"flex",
    "@media(max-width:1200px)":{
        paddingRight:"0px",
        paddingTop:"30px",
    },
    "@media(max-width:390px)": {
        paddingTop:"0px"  
    },
    "& .accordianMainDiv":{
        "@media(max-width:560px)": {
        marginTop:"0px !important"
        }
    },
    justifyContent:"center",
    width:"100%",
    "& .accodianTitle":{
        fontSize:"46px !important",
        fontWeight:"500 !important",
        color:"#0D0C22 !important",
        lineHeight:"52.8px",
        justifyContent:"center",
        fontFamily:"Silika-Medium !important",
        paddingRight:"37px",
        paddingLeft:"37px",
        "@media(max-width:1368px)": {
            fontSize:"36px !important",
        },
        "@media(max-width:1168px)":{
            paddingRight:"0px",
            paddingLeft:"0px",
            paddingTop:"10px"
        },
    },
    "& .MuiAccordion-root": {
        width: "100%"
    },
    "& .MuiTypography-body1":{
      fontFamily:"silka",
      fontWeight:"500",
      fontSize:"20px",
      color:"#000000",
    },
    "& .MuiIconButton-label":{
      fontSize:"20px",
      color:"#000000"
    },
    "& .MuiAccordionSummary-root.Mui-expanded":{
        width:"12%",
        margin:"auto"
    },
    "& .accordianDetails":{  
        backgroundColor:"white",
        paddingLeft:"64px",
        paddingRight:"64px",
        paddingTop:"60px",
        flexDirection:"column",
        "@media(max-width:960px)":{
            paddingLeft:"24px",
            paddingRight:"24px",
        },
    },
    "& .titleBrands":{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        margin:"auto",
        width:"50%",
        "@media(max-width:1496px)":{
          marginTop:"50px"
        },
        "@media(max-width:1168px)":{
            width:"100%",
            maxWidth:"100%",
            marginTop:"24px"
        },
    },
    "& .paraBrands":{
        fontFamily:"Silika-Light",
        fontWeight:"300",
        fontSize:"16px",
        color:"#0D0C22",
        opacity:"0.8",
        maxWidth:"552px",
        "@media(max-width:1168px)":{
            width:"100%",
            maxWidth:"100%",
        },
    },
    "& .brandQuoteDiv":{
        backgroundColor:"#66A2EB",
        color:"#FFFFFF",
        fontFamily:"Silika-Medium",
        fontWeight:"500",
        fontSize:"48px",
        paddingLeft:"64px",
        paddingRight:"64px",
        paddingTop:"204px",
        paddingBottom:"204px",
        marginTop:"134px",
        width: "100%",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
        "@media(max-width:1168px)":{
            marginTop:"72px",
            paddingTop:"120px",
            paddingBottom:"120px",
            fontSize:"48px",
        },
        "@media(max-width:960px)":{
            marginTop:"72px",
            paddingTop:"120px",
            paddingBottom:"120px",
            fontSize:"36px",
        },
        "@media(max-width:648px)":{
            marginTop:"50px",
            paddingTop:"72px",
            paddingBottom:"72px",
            fontSize:"24px",
            paddingLeft:"16px",
            paddingRight:"16px",
        }
    },
    "& .authorQuote":{
        color:"#FFFFFF",
        fontFamily:"Silika-Medium",
        fontWeight:"500",
        fontSize:"24px",
        marginTop:"51px",
        "@media(max-width:648px)":{
            fontSize:"20px", 
        }
    },
    "& .backTop":{
        textTransform:"none",
        backgroundColor:"white",
        width:"171px",
        height:"44px",
        marginLeft:"auto",
        marginRight:"auto",
        marginTop:"130px",
        fontFamily:"silka",
        fontWeight:"500",
        fontSize:"16px",
        color:"#010101",
        "@media(max-width:960px)":{
            marginTop:"65px", 
        },
        "@media(max-width:648px)":{
            marginTop:"24px", 
        }
    },
    "& .contentOfContainer":{
        justifyContent: "space-between", 
        paddingLeft: "64px", 
        paddingRight: "64px",
        marginTop:"0",
        display:"flex",
        flexWrap:"wrap",
        "@media(max-width:1650px)":{
            paddingLeft: "0px", 
            paddingRight: "0px",
        }
    },
    "& .titleDivAcc":{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        margin:"auto",
        width:"50%",
        "@media(max-width:1604px)":{
            marginTop:"auto !important",
        },
        "@media(max-width:1168px)":{
            width:"100%",
        },
    },
    "& .accordianTitle":{
        fontFamily:"Silika-Medium",
        fontWeight:"500",
        fontSize:"16px",
        lineHeight:"19.3px"
    }
  });


  const data = [
    {
      category: 'Profile management',
      features: [
        'Subscribe and manage subscription',
        'Portfolio page management',
        'Portfolio page',
        'Tagging/crediting other collaborators',
        'Request collaborator credit from others',
        'Approving collaborator credit from others',
        'Like images in other portfolios',
        'Save favourite profiles',
        'See other people social media links',
      ],
    },
    {
      category: 'Search',
      features: [
        'Filter by names', 
        'Filter by other fields', 
        'See full surnames'
      ],
    },
    {
      category: 'Messaging & Interaction',
      features: [
        'Links to social accounts', 
        'Calendar management to mark availability', 
        'Initiate messaging','Block messaging from selected users',
        'Boost your profile'
      ],
    },
      {
          category: 'Events',
          features: [
              'View and search events',
              'Create events',
          ],
      },
      {
          category: 'Content',
          features: [
              'Media library for online content'
          ],
      },
      {
        category: 'Opportunities',
        features: [
            'Opportunities feed',
            'Review portfolios',
            'Submit automated invoice',
            'Submit timesheet',
            'Be invited to an opportunity',
            'See if you’ve been accepted',
            'Apply to an opportunity',
            'Access to project management dashboard',
            'Define deliverables',
            'Define dates',
            'See designer availability',
            'Shortlist designers',
            'See who’s applied to your opportunity',
            'Request video interviews / schedule appoitment ',
            'Timesheet review and approve',
            'Payment management',
            'Included roles per month',
            'Auto-onboard document suite'
        ],
    },
    {
        category: 'Account Permissions',
        features: [
            'Different approval permissions under a single account',
            'Multiple seats on each account (max 5)'
        ],
    },
  ];
  
  const plans = ['Free', 'Freelance', 'Studio', 'Enterprise'];
  
  const planFeatures = [
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'],
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'],   
    [' ', ' ', '✔', '✔'],
    ['✔', '✔', '✔', '✔'], 
    [' ', '✔', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    ['✔', '✔', '✔', '✔'], 
    ['✔', '✔', '✔', '✔'], 
    [' ', '✔', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],   
    [' ', ' ', ' ', '✔'],    
    ['✔', '✔', '✔', '✔'],
    [' ', '✔', '✔', '✔'],
    [' ', '✔', '✔', '✔'],  
    ['✔', '✔', '✔', '✔'],
    ['✔', '✔', '✔', '✔'],
    ['✔', '✔', '✔', '✔'],
    ['✔', '✔', '✔', ' '],
    ['✔', '✔', '✔', ' '],
    ['✔', '✔', '✔', ' '],
    [' ', '✔', '✔', ' '],
    [' ', ' ', '✔', '✔'],
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'],  
    [' ', ' ', '✔', '✔'], 
    [' ', ' ', '1', '6'],   
    [' ', ' ', ' ', '✔'],
    [' ', ' ', ' ', '✔'],
    [' ', ' ', ' ', '✔'],  
  ];
  
  const StyledTableContainer = styled(TableContainer)({
    width: '100%',
    overflowX: 'scroll',
    fontFamily:"Silka",
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor:"white",
    "& .getItNow":{
       backgroundColor:"#0D0C22 !important",
       fontFamily:"Silika-Medium",
       textTransform:"none",
       width:"132px",
       "@media(max-width:1000px)":{
        width:"100px",
        fontSize:"12px"
       },
       "@media(max-width:960px)":{
        display:"none"
       },
    },
    "& .plantTitle":{
       margin:"20px",
       fontFamily:"Silika-Medium",
       fontWeight:500,
       fontSize:"25.27px",
       "@media(max-width:1230px)":{
        fontSize:"18.72px",
        margin:"10px",
       },
       "@media(max-width:960px)":{
           transform: "rotate(-90deg)",
           justifyContent: "center",
           alignContent: "center",
           display: "flex"
       },
    },
    "& .featureTitle":{
       fontFamily:"Silika-Light",
       fontWeight:300,
       fontSize:"18.27px",
       "@media(max-width:1230px)":{
        fontSize:"14px",
       },
    },
    "& .headerGrayDiv":{
      fontFamily:"Silika-Medium",
      "@media(max-width:1230px)":{
        fontSize:"16px",
       },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      '& table': {
        width: '100%',
        display: 'block',
        overflowX: 'auto',
        border: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    "& .tableCellStyled":{
        "@media(max-width:960px)":{
        height:"127px",
        width:"100px"
        },
        "@media(max-width:772px)":{
          maxWidth:"70px",
          height:"250px",
        },
        "@media(max-width:580px)":{
            maxWidth:"50px",
            height:"250px",
        },
        "@media(max-width:470px)":{
            maxWidth:"30px",
        },
        "@media(max-width:386px)":{
            maxWidth:"24px",
        }
    }
  });
  
  const StyledTableCell = styled(TableCell)({
    textAlign: 'center',
    border: `1px solid ${theme.palette.grey[300]}`,
    fontSize:"22.66px",
    backgroundColor:"white",
    '&.green': {
        color: '#00FFA7',
      },
      '&.black': {
        color: 'black',
      },
    "@media(max-width:1074px)":{
        padding:"10px",
    },
  });

  const SectionHeaderCell = styled(TableCell) ({
    backgroundColor: `${theme.palette.grey[300]}`,
    fontWeight: 'bold',
    textAlign: 'left',
    border: `1px solid ${theme.palette.grey[300]}`,
    fontFamily:"Silka",
    fontSize:"21.6px",
  });
  
  const GetButton = styled(Button)({
    backgroundColor:"black",
    color: "white",
    cursor: 'pointer',
    textAlign: 'center',
  });

// Customizable Area End
export default class LandingPage3Web extends LandingPage3Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    
 PricingTable = () => {
    return (
        <StyledTableContainer>
        <Table aria-label="pricing table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {plans.map((plan, index) => (
                <StyledTableCell key={index} className="tableCellStyled">
                  <Typography className="plantTitle" variant="h6">{plan}</Typography>
                  <GetButton className="getItNow" variant="contained">Get it Now</GetButton>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
                    {data.map((section, sectionIndex) => (
                        <React.Fragment key={sectionIndex}>
                            <TableRow>
                                <SectionHeaderCell colSpan={plans.length + 1} className="headerGrayDiv">
                                    {section.category}
                                </SectionHeaderCell>
                            </TableRow>
                            {section.features.map((feature, featureIndex) => {
                                const featureIndexOffset = sectionIndex === 0
                                    ? featureIndex
                                    : featureIndex + data.slice(0, sectionIndex).reduce((acc, curr) => acc + curr.features.length, 0);

                                return (
                                    <TableRow key={featureIndex}>
                                        <TableCell className="featureTitle">{feature}</TableCell>
                                        {plans.map((_, planIndex) => {
                                            const cellValue = planFeatures[featureIndexOffset][planIndex];
                                            const cellClass = cellValue === '✔' ? 'green' : 'black';

                                            return (
                                                <StyledTableCell className={cellClass} key={planIndex}>
                                                    {cellValue}
                                                </StyledTableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </React.Fragment>
                    ))}
        </TableBody>
        </Table>
      </StyledTableContainer>
    );
  };
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <LandingHeader navigation={this.props.navigation}/>
                <StyledContainer>
                    <Box className="paymentContainer">
                        <Grid container className="paymentContainer">
                            <Grid item xl={12} className="paymentTitle" style={{paddingLeft:"64px",paddingRight:"64px"}}>
                                <Typography className="topTitle">Work with world's top designers</Typography>
                                <Typography className="tagLine">Our hand-picked community of automotive and industrial designers are
                                    enabled to work better through our simple, seamless management tools. </Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="paymentContainer paidCard">
                            <Grid item className="cardDiv">
                                <Card className="cardsPayment">
                                    <Typography className="cardTitle">Free</Typography>
                                    <Typography className="subTitle">Perfect plan for Starters </Typography>
                                    <Typography className="amount">£0</Typography>
                                    <Typography className="perMonth">per month</Typography>
                                    <Box className="markDiv">
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">Portfolio page</Typography></Box>
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">Opportunities feed</Typography></Box>
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">View and search events</Typography></Box>
                                    </Box>
                                    <Button className="choosePlanBtn">Choose Plan</Button>
                                    <Typography className="cardFooterLine">Cancel anytime. No strings attached.</Typography>
                                </Card>
                            </Grid>
                            <Grid item className="cardDiv">
                                <Card className="cardsPayment">
                                    <Typography className="cardTitle">Freelance</Typography>
                                    <Typography className="subTitle">Work on projects <br />professionally.</Typography>
                                    <Typography className="amount">£12</Typography>
                                    <Typography className="perMonth">per month</Typography>
                                    <Box className="markDiv">
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">Can initiate messages</Typography></Box>
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">Apply to opportunities</Typography></Box>
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">Create events</Typography></Box>
                                    </Box>
                                    <Button className="choosePlanBtn">Choose Plan</Button>
                                    <Typography className="cardFooterLine">Cancel anytime. No strings attached.</Typography>
                                </Card>
                            </Grid>
                            <Grid item className="cardDiv">
                                <Card className="cardsPayment">
                                    <Typography className="cardTitle">Studio</Typography>
                                    <Typography className="subTitle">Build teams and work <br />on projects.</Typography>
                                    <Typography className="amount">£30</Typography>
                                    <Typography className="perMonth">per month</Typography>
                                    <Box className="markDiv">
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">Boost your profile</Typography></Box>
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">Shortlist designers</Typography></Box>
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">Payment management</Typography></Box>
                                    </Box>
                                    <Button className="choosePlanBtn">Choose Plan</Button>
                                    <Typography className="cardFooterLine">Cancel anytime. No strings attached.</Typography>
                                </Card>
                            </Grid>
                            <Grid item className="cardDiv">
                                <Card className="cardsPayment">
                                    <Typography className="cardTitle">Enterprise</Typography>
                                    <Typography className="subTitle">Find talent and manage<br /> professional teams.</Typography>
                                    <Typography className="amount">£80</Typography>
                                    <Typography className="perMonth">per month</Typography>
                                    <Box className="markDiv">
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">6 Roles per month</Typography></Box>
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">Project management</Typography></Box>
                                        <Box className="cardMarks"><img src={rightLogo} alt="right" /><Typography className="checkMarkTitle">Approval permissions</Typography></Box>
                                    </Box>
                                    <Button className="choosePlanBtn">Choose Plan</Button>
                                    <Typography className="cardFooterLine">Cancel anytime. No strings attached.</Typography>
                                </Card>
                            </Grid>
                        </Grid>
                        <StyledAccordian>
                            <Accordion style={{ backgroundColor: "#F5F3F3",marginTop:"30px" }} className="accordianMainDiv">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    style={{ backgroundColor: "white", width: "196px", margin: "auto",display:"flex",justifyContent:"center" }}
                                >
                                    <Typography className="accordianTitle">View all features</Typography>
                                </AccordionSummary>
                                <AccordionDetails className="accordianDetails" style={{backgroundColor:"transparent",margin:"0",paddingTop:"0",paddingBottom:"0"}}>
                                    {this.PricingTable()}
                                    <Button className="backTop" id="backToTop" endIcon={<ExpandLessIcon/>} onClick={this.handleScrollUp}>Back to the top</Button>
                                </AccordionDetails>
                            </Accordion>
                        </StyledAccordian>
                        <Box className="myBrandsContainer">
                        <StyledAccordian>                
                            <Box className="contentOfContainer">
                                <Box className="titleDivAcc">
                                    <Typography className="accodianTitle">Why brands choose Forge Design</Typography>
                                </Box>
                                <Box  className="titleBrands">
                                        <Typography className="paraBrands">
                                            Manage all your projects in one place,
                                            with clear deadlines allocated to each task.
                                            See your project in a timeline and know exactly what’s happening in one glance.
                                            <br />
                                            <div style={{marginTop:"10px"}}>
                                            Communicate quickly and easily with your freelancers and suppliers in
                                            one place and avoid cognitive overload by reducing your outsourced deliverabes to simple one line deliverables.
                                            </div>
                                        </Typography>
                                </Box>
                            </Box>
                            <Grid container className="brandQuoteDiv">
                                <Grid item xl={12}>
                                "It's not a coincidence that it was such a great team. Credit to you guys for building the team. This comes from the top down... "
                                    <Box className="authorQuote">Freelance Interior Designer</Box>
                                </Grid>
                            </Grid>
                        </StyledAccordian>
                        </Box>
                       <Box className="startProject">
                            <Typography className="startProjectTitle">Start a Project</Typography>
                            <Typography className="tagLine startProjectTagLine">As a decentralised design consultancy we can provide designers,
                                modellers and project managers, for services in-house or complex
                                projects on a turnkey basis.
                            </Typography>
                            <Button className="getStartedBtn startBtnProject">Get Started</Button>
                       </Box>
                    </Box>
                </StyledContainer>
                <LandingFooter navigation={this.props.navigation}/>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
// Customizable Area End