import React from "react";
import { styled, Typography, Button, Table, TableCell, TableRow, TableBody, TableHead, TableContainer, Theme } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

interface Feature{
    feature: string;
    free: boolean | string;
    freelance: boolean | string;
    studio: boolean | string;
    enterprise: boolean | string;
}

interface SubscriptionCardProps{
    windowWidth: number;
}

export default class SubscriptionCards extends React.Component<SubscriptionCardProps>{

    constructor(props: SubscriptionCardProps){
        super(props);
    }
    renderCard = (title: string, description:string, price: number, features: string[], actionBtn: string, isCurrentPlan: boolean) => {
        return (
            <StyledCard>
                <div className="top">
                    <Typography className="title">{title}</Typography>
                    <Typography className="description">{description}</Typography>
                </div>
                <div className="bottom">
                    {isCurrentPlan && <span className="currentPlan">Current plan</span>}
                    <span className="price">
                        £ {price}
                    </span>
                    <span className="perMonth">
                        per month
                    </span>
                    <div className="groupFeatures">
                        {features.map(feature => <div className="groupFeature">
                            <CheckIcon/>
                            <span className="groupFeatureName">{feature}</span>
                        </div>)}
                    </div>
                    <Button className="actionBtn">
                        {actionBtn}
                    </Button>
                    <Typography className="cancelBtn" component="button">Cancel anytime. No strings attached.</Typography>
                </div>
            </StyledCard>
        )
    };

    renderRows = (rows: Feature[]) => {
        return rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.feature}</TableCell>
              <TableCell>
                <div className="tickMark">
                    {row.free ? <CheckIcon/> : <></>}
                </div>
              </TableCell>
              <TableCell>
                <div className="tickMark">
                    {row.freelance ? <CheckIcon/> : <></>}
                </div>
              </TableCell>
              <TableCell>
                <div className="tickMark">
                    {row.studio ? <CheckIcon/> : <></>}
                </div>
              </TableCell>
              <TableCell>
                <div className="tickMark">
                    {row.enterprise ? <CheckIcon/> : <></>}
                </div>
              </TableCell>
            </TableRow>
          ))
    };

    render(){
    const isMobile = this.props.windowWidth <= 599;
    const profileManagement = [
        { feature: 'Subscribe and manage subscription', free: true, freelance: true, studio: true, enterprise: true },
        { feature: 'Portfolio page management', free: true, freelance: true, studio: true, enterprise: true },
        { feature: 'Portfolio page', free: true, freelance: true, studio: true, enterprise: true },
        { feature: 'Tagging/crediting other collaborators', free: true, freelance: true, studio: true, enterprise: true },
        { feature: 'Request collaborator credit from others', free: false, freelance: true, studio: true, enterprise: true },
        { feature: 'Approving collaborator credit from others', free: false, freelance: true, studio: true, enterprise: true },
        { feature: 'Like images in other portfolios', free: true, freelance: true, studio: true, enterprise: true },
        { feature: 'Save favourite profiles', free: true, freelance: true, studio: true, enterprise: true },
        { feature: 'See other people social media links', free: true, freelance: true, studio: true, enterprise: true },
    ];

    const opertunities = [
    { feature: 'Opportunities feed', free: true, freelance: true, studio: true, enterprise: true },
    { feature: 'Review portfolios', free: true, freelance: true, studio: true, enterprise: true },
    { feature: 'Submit automated invoice', free: true, freelance: true, studio: true, enterprise: true },
    { feature: 'Submit timesheet', free: true, freelance: true, studio: true, enterprise: true },
    { feature: 'Be invited to an opportunity', free: true, freelance: true, studio: true, enterprise: true },
    { feature: 'See if you’ve been accepted', free: true, freelance: true, studio: true, enterprise: true },
    { feature: 'Apply to an opportunity', free: false, freelance: true, studio: true, enterprise: true },
    { feature: 'Access to project management dashboard', free: false, freelance: false, studio: true, enterprise: true },
    { feature: 'Define deliverables', free: false, freelance: false, studio: true, enterprise: true },
    { feature: 'Define dates', free: false, freelance: false, studio: true, enterprise: true },
    { feature: 'See designer availability', free: false, freelance: false, studio: true, enterprise: true },
    { feature: 'Shortlist designers', free: false, freelance: false, studio: true, enterprise: true },
    { feature: 'See who’s applied to your opportunity', free: false, freelance: false, studio: true, enterprise: true },
    { feature: 'Request video interviews / schedule appointment', free: false, freelance: false, studio: true, enterprise: true },
    { feature: 'Timesheet review and approve', free: false, freelance: false, studio: true, enterprise: true },
    { feature: 'Payment management', free: false, freelance: false, studio: true, enterprise: true },
    { feature: 'Included roles per month', free: '', freelance: '1', studio: '1', enterprise: '6' },
    { feature: 'Auto-onboard document suite', free: false, freelance: false, studio: false, enterprise: true },
    ];

    const search = [
        { feature: 'Filter by names', free: true, freelance: true, studio: true, enterprise: true },
        { feature: 'Filter by other fields', free: true, freelance: true, studio: true, enterprise: true },
        { feature: 'See full surnames', free: false, freelance: true, studio: true, enterprise: true },
    ];

    const messagingAndInteractions = [
        { feature: 'Links to social accounts', free: true, freelance: true, studio: true, enterprise: true },
        { feature: 'Calendar management to mark availability', free: false, freelance: true, studio: true, enterprise: true },
        { feature: 'Initiate messaging', free: false, freelance: true, studio: true, enterprise: true },
        { feature: 'Block messaging from selected users', free: false, freelance: true, studio: true, enterprise: true },
        { feature: 'Boost your profile', free: false, freelance: true, studio: true, enterprise: true },
    ];

    const events = [
        { feature: 'View and search events', free: true, freelance: true, studio: true, enterprise: true },
        { feature: 'Create events', free: false, freelance: true, studio: true, enterprise: true },
    ];

    const content = [
        { feature: 'Media library for online content', free: true, freelance: true, studio: true, enterprise: true },
    ];

    const accountPermissions = [
        {feature: "Different approval permissions under a single account", free: "", freelance: "", studio: "", enterprise: true},
        {feature: "Multiple seats on each account (max 5)", free: "", freelance: "", studio: "", enterprise: true}
    ];

    return (
        <StyledSubscriptionContainer data-test-id="subscriptionCards">
            <Typography className="choosePlan">Choose the plan that works best for you.</Typography>
            <StyledCardList>
                {this.renderCard("Free", "Perfect plan for Starters", 0, ["Portfolio Page", "Opportunities feed", "View and search events"], "Your current plan", true)}
                {this.renderCard("Freelance", "Work on projects professionally.", 12, ["Can initiate messages", "Apply to opportunities", "Create events"], "Get Freelance", false)}
                {this.renderCard("Studio", "Build teams and work on projects.", 12, ["Boost your profile", "Shortlist designers", "Payment management"], "Get Studio", false)}
                {this.renderCard("Enterprise", "Find talent and manage professional teams.", 80, ["6 Roles per month", "Project management", "Approval permissions"], "Get Enterprise", false)}
            </StyledCardList>
            <StyledViewAllFeatures>
                View all features <ExpandMoreIcon/>
            </StyledViewAllFeatures>
            <StyledTableContainer>
                <Table>
                    <StyledTableHead>
                    <TableRow>
                        <TableCell className="tableCellEmpty"></TableCell>
                        <TableCell className="tablecell">
                            <div className="headTitle">
                                <span>Free</span>
                                {!isMobile ?<Typography>Get it now</Typography>: <Typography data-test-id="mobileCurrentPlan" className="currentPlan">Current plan</Typography>}
                            </div>
                        </TableCell>
                        <TableCell className="tablecell">
                            <div className="headTitle">
                                <span>Freelance</span>
                                {!isMobile ?<Typography>Get it now</Typography>: <></>}
                            </div>
                        </TableCell>
                        <TableCell className="tablecell">
                            <div className="headTitle">
                                <span>Studio</span>
                                {!isMobile ?<Typography>Get it now</Typography>: <></>}
                            </div>
                        </TableCell>
                        <TableCell className="tablecell">
                            <div className="headTitle">
                                <span>Enterprise</span>
                                {!isMobile ?<Typography>Get it now</Typography>: <></>}
                            </div>
                        </TableCell>
                    </TableRow>
                    </StyledTableHead>
                    <StyledTableBody>
                        <TableRow key={-1} className="titleBar">
                            <TableCell colSpan={5}>Profile management</TableCell>
                        </TableRow>
                        {this.renderRows(profileManagement)}
                        <TableRow key={-2} className="titleBar">
                            <TableCell colSpan={5}>Search</TableCell>
                        </TableRow>
                        {this.renderRows(search)}
                        <TableRow key={-3} className="titleBar">
                            <TableCell colSpan={5}>Messaging & Interaction</TableCell>
                        </TableRow>
                        {this.renderRows(messagingAndInteractions)}
                        <TableRow key={-4} className="titleBar">
                            <TableCell colSpan={5}>Events</TableCell>
                        </TableRow>
                        {this.renderRows(events)}
                        <TableRow key={-5} className="titleBar">
                            <TableCell colSpan={5}>Content</TableCell>
                        </TableRow>
                        {this.renderRows(content)}
                        <TableRow key={-6} className="titleBar">
                            <TableCell colSpan={5}>Opportunities</TableCell>
                        </TableRow>
                        {this.renderRows(opertunities)}
                        <TableRow key={-7} className="titleBar">
                            <TableCell colSpan={5}>Account Permissions</TableCell>
                        </TableRow>
                        {this.renderRows(accountPermissions)}
                    </StyledTableBody>
                </Table>
            </StyledTableContainer>
            <StyledViewAllFeatures>
                Back to the top <ExpandLessIcon/>
            </StyledViewAllFeatures>
        </StyledSubscriptionContainer>
    )
    }
}

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    boxShadow: 'none',
    border: `1px solid #DFDFDF`,
    borderBottom: "0px",
    [theme.breakpoints.down('sm')]:{
        margin: "0 auto",
        width: "calc(100vw - 50px)"
    }
}));
  
const StyledTableHead = styled(TableHead)(({ theme }: {theme: Theme}) => ({
    backgroundColor: '#f8f8f8',
    borderBottom: "0px",
    '& .MuiTableCell-root': {
        fontWeight: 'bold',
        fontSize: '14px',
        padding: '12px',
        borderBottom: '2px solid #ddd',
        color: '#333',
    },
    "& .tableCellEmpty":{
        width: "296px",
        [theme.breakpoints.down('sm')] :{
            width: "126px"
        }
    },
    "& .tablecell":{
        padding: "16px 28px 12px 28px",
        width: "98px",
        borderRight: "0.5px solid #DFDFDF",
        borderLeft: "0.5px solid #DFDFDF",
        height: "100%",
        "&:last-child":{
            borderRight: "0px",
        },
        [theme.breakpoints.down('sm')] :{
            padding: "10px 5px",
            height: "127px",
            width: "12%"
        }
    },
    "& .headTitle":{
        display: "flex",
        flexFlow: "column",
        height: "100%",
        alignItems: "center",
        [theme.breakpoints.down('sm')] :{
            flexDirection: "column",
            writingMode: "sideways-lr",
            textOrientation: "mixed",
            width: "max-content !important",
            alignItems: "baseline",
            "@supports (-webkit-appearance: none)": {
                writingMode: "vertical-lr",
                transform: "rotate(180deg)",
                alignItems: "flex-start"
            }
        },
        "& p":{
            background: "#0D0C22",
            color: "#fff",
            borderRadius: "3.12px",
            fontSize: "12px",
            lineHeight: "18.72px",
            fontFamily: "Silika-Medium",
            padding: "4.5px 17px",
            marginTop: "8px",
            width: "fit-content",
            [theme.breakpoints.down('sm')] :{
                margin: "0",
                padding: "0 0.5px",
                height: "94px",
                textAlign: "center",
                borderRadius: "59px"
            }
        },
        "& span":{
            marginTop: "0px",
            marginBottom: "auto",
            textAlign: "center",
            fontSize: "18.72px",
            lineHeight: "24.96px",
            [theme.breakpoints.down('sm')] :{
                margin: "0",
                lineHeight: "14px",
                marginRight: "4px"
            }
        }
    }
}));
  
const StyledTableBody = styled(TableBody)(({ theme }: {theme: Theme}) => ({
    '& .MuiTableCell-root': {
      padding: '14.5px 12px',
      borderBottom: `0.5px solid #DFDFDF`,
      borderRight: "0.5px solid #DFDFDF",
      fontSize: '14px',
      color: '#010101',
      fontFamily: "Silika-Light",
      lineHeight: "18.72px",
      [theme.breakpoints.down('sm')] :{
        padding: "8px 12px"
      }
    },
    '& .MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9',
    },
    "& .tickMark":{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "& svg":{
            color: "#00FFA7"
        }
    },
    "& .titleBar":{
        "& td":{
            background: "#DFDFDF",
            fontFamily: "Silika-Medium"
        }
    }
})); 

const StyledViewAllFeatures = styled(Button)(({theme}: {theme: Theme}) => ({
    textTransform: "none",
    height: "44px",
    width: "200px",
    margin: "36px auto 48px auto",
    border: "1px solid #0D0C22",
    "& svg":{
        marginLeft: "8px"
    }
}))

const StyledSubscriptionContainer = styled("div")(({theme}: {theme: Theme}) => ({
    paddingTop: "37px",
    display: "flex",
    width: "100%",
    flexFlow: "column",
    justifyContent: "center",
    "& *":{
        fontFamily: "Silika-Medium"
    },
    "& p":{
        fontSize: "16px",
        lineHeight: "32px"
    },
    "& .choosePlan":{
        marginBottom: "13px",
        [theme.breakpoints.down('sm')] :{ 
            textAlign: "center"
        }
    }
}));

const StyledCardList = styled("div")(({theme}: {theme: Theme}) => ({
    display: "inline-flex",
    flexWrap: "wrap",
    [theme.breakpoints.down('sm')] :{ 
        justifyContent: "center",
        width: "calc(100vw - 50px)",
        margin:"0 auto"
    }
}));

const StyledCard = styled("div")(({theme}: {theme: Theme}) => ({
    border: "0.78px solid #DFDFDF",
    padding: "33.8px 23px",
    height: "408px",
    width: "192px",
    background: "#fff",
    marginRight: "12px",
    marginBottom: "12px",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')] :{ 
        marginRight: "0px",
        width: "100%",
        marginBottom: "24px",
        boxShadow: "0px 2px 8px 0px #00000014",
        transition: "boxShadow 0.6s ease",
        "& .actionBtn":{
            background: "#0D0C22 !important",
            color: "#fff",
            border: "1px solid #0D0C22",
            transition: "background-color 0.6s ease, color 0.7s ease",
        }
    },
    "&:hover":{
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 8px 32px 0px, rgba(0, 0, 0, 0.06) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px, rgba(0, 0, 0, 0.09) 0px 25px 50px 0px",
        transition: "boxShadow 0.6s ease",
        "& .actionBtn":{
            background: "#0D0C22 !important",
            color: "#fff",
            border: "1px solid #0D0C22",
            transition: "background-color 0.6s ease, color 0.7s ease",
        }
    },
    "& .title":{
        fontSize: "18.72px",
        lineHeight: "24.96px",
        marginBottom: "8px"
    },
    "& .description":{
        fontFamily: "Silika-Light",
        fontSize: "12px",
        lineHeight: "18.72px"
    },
    "& .price":{
        marginTop: "15.13px",
        fontSize: "37.44px",
        lineHeight: "43.68px",
        display: "block"
    },
    "& .currentPlan":{
        background: "#0D0C22",
        color: "#fff",
        borderRadius: "59.27px",
        fontSize: "9.48px",
        lineHeight: "14.23px",
        fontFamily: "Silika-Medium",
        padding: "2px 7px"
    },
    "& .perMonth":{
        fontSize: "12px",
        lineHeight: "18.72px",
        color: "#9B9B9B",
        display: "block",
    },
    "& .groupFeatures":{
        margin: "38px 0"
    },
    "& .groupFeature":{
        display: "inline-flex",
        width: "100%",
        alignItems: "center",
        height: "19px",
        marginBottom: "8px",
        "& svg":{
            color: "#00FFA7",
            height: "16px"
        }
    },
    "& .groupFeatureName":{
        fontSize: "12px",
        lineHeight: "18.72px",
        marginLeft: "8px"
    },
    "& .actionBtn":{
        borderRadius: "3.12px",
        border: "0.39px solid #DFDFDF",
        textTransform: "none",
        height: "44px",
        width: "100%",
        fontSize: "12px",
        marginBottom: "18px"
    },
    "& .cancelBtn":{
        background: "none",
        border: "none",
        fontSize: "10px",
        color: "#9B9B9B",
        lineHeight: "18.72px",
        padding: "0px"
    }
}));